import AceEditor from "react-ace";
import "ace-builds/src-noconflict/worker-json.js";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/src-noconflict/theme-one_dark";
import "ace-builds/src-noconflict/ext-language_tools";
import {setCode} from "../../slices/nodes.slice";
import {useAppDispatch, useAppSelector} from "../../store";
import {useEffect} from "react";
import {NodeInterface} from "../../interfaces/node.interface";

export function Editor({node, readonly = false}: {node: NodeInterface; readonly?: boolean}) {
    const code = useAppSelector((state) => state.nodes.editor.code);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!code) {
            dispatch(setCode(node.code));
        }
    }, [node, code]);

    return (
        <>
            <AceEditor
                readOnly={readonly}
                showPrintMargin={false}
                mode="javascript"
                value={code}
                scrollMargin={[30, 30]}
                theme="xcode"
                width="100%"
                height="100%"
                fontSize={14}
                setOptions={{useWorker: false}}
                onChange={(value) => dispatch(setCode(value))}
            />
        </>
    )
}