import {Button, Dimmer, Form, Loader, Message, Modal} from "semantic-ui-react";
import {useCommitRuleMutation} from "../../apis/rules.api";
import {cloneElement, ReactElement, useEffect, useState} from "react";
import {RuleInterface} from "../../interfaces/rule.interface";

export function CommitChangesModal({rule, children, disabled}: {rule: RuleInterface; children: ReactElement, disabled?: boolean}) {
    const [commitRule, {isLoading, isSuccess, isError, error, reset}] = useCommitRuleMutation();
    const [isOpen, setIsOpen] = useState(false);
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (isOpen) {
            setDescription('');
            reset();
        }
    }, [isOpen])

    useEffect(() => {
        if (isSuccess) {
            setIsOpen(false);
        }
    }, [isSuccess]);

    return (
        <>
            {cloneElement(children, {
                onClick: () => !disabled && setIsOpen(true)
            })}
            <Modal closeIcon open={isOpen} onClose={() => setIsOpen(false)} size="mini">
                <Dimmer active={isLoading} inverted><Loader /></Dimmer>
                <Modal.Header>Commit changes</Modal.Header>
                <Modal.Content>
                    {isError && !(error as any)?.data?.errors && <Message error content={(error as any)?.data?.error?.description || 'Unknown error'} />}
                    <Form>
                        <Form.TextArea
                            label="Changes made"
                            value={description}
                            error={(error as any)?.data?.errors?.commitMessage?.[0]}
                            onChange={(_e, {value}) => setDescription(value?.toString() || '')}
                        />
                        <p>
                            A new version of the rule will be created. Existing rule instances will not be affected.
                            You can switch them to the new rule version manually.
                        </p>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary content="Create" icon="check" onClick={() => {
                        commitRule({
                            ruleUuid: rule.uuid,
                            commitMessage: description,
                        })
                    }} />
                </Modal.Actions>
            </Modal>
        </>
    );
}