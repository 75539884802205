import {Link, useParams} from "react-router-dom";
import {Button, Card, Divider, Grid} from "semantic-ui-react";
import {RuleInfo} from "../../../components/RuleInfo";
import {CreateInstanceModal} from "../../../components/instance/CreateInstanceModal";
import {RuleInterface} from "../../../interfaces/rule.interface";

export function OverviewTab({rule}: { rule: RuleInterface }) {
    const {organization} = useParams();

    return (
        <>
            <Divider hidden/>
            <Card fluid>
                <Card.Content>
                    <Grid columns={2}>
                        <Grid.Column width={10}>
                            <RuleInfo rule={rule} showHeader={false} padded={false}/>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <CreateInstanceModal ruleUuid={rule.uuid} ruleVersion={rule.version}>
                                <Button primary
                                        fluid
                                        icon="plus"
                                        style={{marginBottom: 5}}
                                        content="Create an instance"
                                />
                            </CreateInstanceModal>
                            <Button icon="play"
                                    basic
                                    color="black"
                                    content="Debug"
                                    fluid as={Link}
                                    to={`/org/${organization}/rules/view/${rule.uuid}/${rule.version}`}
                            />
                        </Grid.Column>
                    </Grid>
                </Card.Content>
            </Card>
        </>
    );
}
