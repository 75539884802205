import {Accordion, Divider, Header, Segment} from "semantic-ui-react";
import SyntaxHighlighter from "react-syntax-highlighter/dist/cjs/light";
import {docco} from "react-syntax-highlighter/dist/esm/styles/hljs";
import {useState} from "react";

export function Help() {

    const signature = `
function(
  input: any,
  nodeParams: {[key: string]: any},
  instance: InstanceInterface,
  flowNode: FlowNodeInterface,
  eventData: object,
): any | Promise<any>;
`;

    const instanceInterface = `
interface InstanceInterface {
    uuid: string;
    ruleUuid: string;
    ruleVersion: number;
    triggerParams: {
        [key: string]: string | number;
    };
    ruleParams: {
        [key: string]: any;
    };
    scratchpad: {
        [key: string]: any;
    };
    createdBy: string;
    updatedBy: string;
    createdAt: Date;
    updatedAt: Date;
}
`;

    const flowNodeInterface = `
interface FlowNodeInterface {
    uuid: string;
    type: RuleNodeTypeEnum;
    nodeUuid: string;
    nodeParams: {
        [key: string]: any
    };
    defaults?: {
        [key: string]: any
    };
}
`;

    const [active1, setActive1] = useState(-1);
    const [active2, setActive2] = useState(-1);

    return (
        <Segment basic>
            <Header style={{marginTop: '0.25em'}}>
                Help
            </Header>
            <Divider/>
            <p>Node code must be a function with the next signature:</p>
            <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: 0}}>
                {signature}
            </SyntaxHighlighter>
            <Accordion activeIndex={active1}>
                <Accordion.Title
                    active={active1 === 0}
                    index={0}
                    onClick={() => setActive1(active1 === 0 ? -1 : 0)}
                    content="InstanceInterface"
                />
                <Accordion.Content active={active1 === 0}>
                    <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: 0}}>
                        {instanceInterface}
                    </SyntaxHighlighter>
                </Accordion.Content>
                <Accordion.Title
                    active={active1 === 1}
                    index={1}
                    onClick={() => setActive1(active1 === 1 ? -1 : 1)}
                    content="FlowNodeInterface"
                />
                <Accordion.Content active={active1 === 1}>
                    <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: 0}}>
                        {flowNodeInterface}
                    </SyntaxHighlighter>
                </Accordion.Content>
            </Accordion>
            <h4>Helper functions</h4>
            <Accordion activeIndex={0}>
                <Accordion.Title
                    active={active2 === 0}
                    index={0}
                    onClick={() => setActive2(active2 === 0 ? -1 : 0)}
                    content="Send SMS"
                />
                <Accordion.Content active={active2 === 0}>
                    <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: 0}}>
                        {'sendSMS(\n  phoneNumber: string,\n  text: string\n): boolean;'}
                    </SyntaxHighlighter>
                </Accordion.Content>

                <Accordion.Title
                    active={active2 === 1}
                    index={1}
                    onClick={() => setActive2(active2 === 1 ? -1 : 1)}
                    content="Send email"
                />
                <Accordion.Content active={active2 === 1}>
                    <p>
                        To send email with custom HTML use <b>pure</b> template.
                    </p>
                    <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: 0}}>
                        {'sendEmail(\n  email: string,\n  subject: string,\n  text: string,\n  html?: string,\n  template?: \'notify\' | \'pure\'\n): boolean;'}
                    </SyntaxHighlighter>
                </Accordion.Content>

                <Accordion.Title
                    active={active2 === 2}
                    index={2}
                    onClick={() => setActive2(active2 === 2 ? -1 : 2)}
                    content="Parsing datetime"
                />
                <Accordion.Content active={active2 === 2}>
                    <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: '0 0 10px'}}>
                        {'chrono.parseDate(value: string): Date;'}
                    </SyntaxHighlighter>
                    <a href="https://github.com/wanasit/chrono" target="_blank">Chrono docs</a>
                </Accordion.Content>

                <Accordion.Title
                    active={active2 === 3}
                    index={3}
                    onClick={() => setActive2(active2 === 3 ? -1 : 3)}
                    content="Lodash"
                />
                <Accordion.Content active={active2 === 3}>
                    <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: '0 0 10px'}}>
                        {'lodash.chunk([\'a\', \'b\', \'c\', \'d\'], 2);\n' +
                            '// => [[\'a\', \'b\'], [\'c\', \'d\']]'}
                    </SyntaxHighlighter>
                    <a href="https://lodash.com/docs/4.17.15" target="_blank">Lodash docs</a>
                </Accordion.Content>

                <Accordion.Title
                    active={active2 === 4}
                    index={4}
                    onClick={() => setActive2(active2 === 4 ? -1 : 4)}
                    content="HTTP"
                />
                <Accordion.Content active={active2 === 4}>
                    <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: 0}}>
                        {'http(\n  method: string,\n  url: string,\n  headers?: object,\n  pathParams?: object,\n  queryParams?: object,\n  body?: object\n): Promise<object>;'}
                    </SyntaxHighlighter>
                </Accordion.Content>

                <Accordion.Title
                    active={active2 === 5}
                    index={5}
                    onClick={() => setActive2(active2 === 5 ? -1 : 5)}
                    content="Send metric"
                />
                <Accordion.Content active={active2 === 5}>
                    <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: 0}}>
                        {'sendMetric(metric: {\n  metricType: MetricTypeEnum;\n  value: number;\n  index: number;\n  devicePointer: ' +
                            'number;\n  deviceId: number;\n  userId: number;\n  measuredAtUtc: Date;\n  measuredAtLocaltime: Date;\n  ' +
                            'measuredAtTimezoneOffsetMin: number;\n}): Promise<boolean>;\n\n' +
                            'enum MetricTypeEnum {\n  SKIN_TEMPERATURE = 1,\n' +
                            '  AMBIENT_TEMPERATURE = 2,\n' +
                            '  CORE_TEMPERATURE = 3,\n' +
                            '  BATTERY_LEVEL = 4,\n' +
                            '  DEVICE_RESET_COUNT = 5,\n' +
                            '  DEVICE_CYCLES_COUNT = 6\n}'}
                    </SyntaxHighlighter>
                </Accordion.Content>

                <Accordion.Title
                    active={active2 === 6}
                    index={6}
                    onClick={() => setActive2(active2 === 6 ? -1 : 6)}
                    content="Create rule instance"
                />
                <Accordion.Content active={active2 === 6}>
                    <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: 0}}>
                        {'createRuleInstance(\n  ruleUuid: string,\n  ruleVersion: number,\n  triggerParams: string,\n  ruleParams: string\n): void'}
                    </SyntaxHighlighter>
                </Accordion.Content>

                <Accordion.Title
                    active={active2 === 7}
                    index={7}
                    onClick={() => setActive2(active2 === 7 ? -1 : 7)}
                    content="Delete rule instance"
                />
                <Accordion.Content active={active2 === 7}>
                    <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: 0}}>
                        {'deleteRuleInstance(\n  ruleUuid: string,\n  ruleVersion: number,\n  triggerParams: string,\n  ruleParams: string\n): void'}
                    </SyntaxHighlighter>
                </Accordion.Content>

                <Accordion.Title
                    active={active2 === 8}
                    index={8}
                    onClick={() => setActive2(active2 === 8 ? -1 : 8)}
                    content="Exit"
                />
                <Accordion.Content active={active2 === 8}>
                    <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: 0}}>
                        {'exit(\n  code: number,\n  reason: string\n): void'}
                    </SyntaxHighlighter>
                </Accordion.Content>
            </Accordion>
        </Segment>
    );
}