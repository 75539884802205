import {Mention, MentionsInput} from "react-mentions";
import defaultStyle from './default-style'
import defaultMentionStyle from './default-mention-style'

export function Mentions({data, value, onChange, singleLine, displayTransform}: {
    data: {id: number | string; display: string}[];
    value?: string;
    onChange?: (e: any, value: string, plainValue: string) => void;
    singleLine?: boolean;
    displayTransform?: (id: string, display: string) => string;
}) {

    return (
        <MentionsInput
            className="mentions"
            value={value}
            onChange={(e, value, plain) => {
                onChange && onChange(e, value, plain);
            }}
            style={defaultStyle}
            singleLine={singleLine || false}
        >
            <Mention
                trigger="$"
                markup="<__id__>"
                regex={/(?:\<|^)(\$[A-z0-9\.\[\]\-]+)(?:\>|$)/}
                data={data}
                style={defaultMentionStyle}
                displayTransform={displayTransform}
                renderSuggestion={(
                    suggestion,
                    search,
                    highlightedDisplay,
                    index,
                    focused
                ) => (
                    <div className={`user ${focused ? 'focused' : ''}`}>
                        {highlightedDisplay}
                    </div>
                )}
                // renderSuggestion={this.renderUserSuggestion}
            />
        </MentionsInput>
    )
}