export enum RuleNodeTypeEnum {
    INPUT = 'input',
    NODE = 'node',
    TRIGGER = 'trigger',
}

export interface RuleNodeInterface {
    uuid: string;
    type: RuleNodeTypeEnum;
    nodeUuid: string;
    nodeVersion: number;
    nodeParams: {
        [key: string]: any
    };
    defaults?: {
        [key: string]: any
    };
    position: {
        x: number;
        y: number;
    },
    description: string;
}