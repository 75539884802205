import {useEffect} from "react";
import {isNumber, sortBy, uniqBy} from "lodash";
import {useFindNodesQuery} from "../../apis/nodes.api";
import {useFindTriggersQuery} from "../../apis/triggers.api";
import {useFindEventsQuery} from "../../apis/events.api";
import {NodeStatusEnum} from "../../interfaces/node-status.enum";
import {useAppDispatch, useAppSelector} from "../../store";
import {TriggerStatusEnum} from "../../interfaces/trigger-status.enum";
import {reset, setEvents, setNodes, setTriggers} from "../../slices/editor.slice";
import {useGetRuleQuery} from "../../apis/rules.api";

export function DataLoader({ruleUuid, ruleVersion}: {
    ruleUuid?: string;
    ruleVersion?: number;
}) {
    const org = useAppSelector(state => state.auth.org);
    const needToFetchRule = ruleUuid && isNumber(ruleVersion);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(reset());
    }, []);

    const publicNodesQuery = useFindNodesQuery({
        status: NodeStatusEnum.PUBLIC,
        limit: -1
    }, {
        refetchOnMountOrArgChange: true,
    });
    const orgNodesQuery = useFindNodesQuery({
        orgId: org?.id as number,
        limit: -1
    }, {
        refetchOnMountOrArgChange: true,
        skip: !org?.id
    });
    const publicTriggersQuery = useFindTriggersQuery({
        status: TriggerStatusEnum.PUBLIC,
        limit: -1
    }, {
        refetchOnMountOrArgChange: true,
    });
    const orgTriggersQuery = useFindTriggersQuery({
        orgId: org?.id as number,
        limit: -1
    }, {
        refetchOnMountOrArgChange: true,
        skip: !org?.id
    });
    const eventsQuery = useFindEventsQuery({});
    const ruleQuery = useGetRuleQuery({
        ruleUuid,
        ruleVersion,
        getDependencies: true,
        getPermissions: true
    } as any, {
        refetchOnMountOrArgChange: true,
        skip: !needToFetchRule
    });

    useEffect(() => {
        if (
            publicNodesQuery.isSuccess && orgNodesQuery.isSuccess &&
            (ruleQuery.isSuccess || !needToFetchRule)
        ) {
            const nodes = uniqBy(
                [
                    ...publicNodesQuery.data.data,
                    ...orgNodesQuery.data.data,
                    ...ruleQuery.data?.dependencies?.nodes || []
                ],
                (node) => [node.uuid, node.version].join('-')
            );
            dispatch(setNodes(sortBy(nodes, node => {
                return [node.orgId === org?.id ? 0 : 1, node.name]
            })))
        }
        if (
            publicTriggersQuery.isSuccess && orgTriggersQuery.isSuccess &&
            (ruleQuery.isSuccess || !needToFetchRule)
        ) {
            const triggers = uniqBy(
                [
                    ...publicTriggersQuery.data.data,
                    ...orgTriggersQuery.data.data,
                    ...ruleQuery.data?.dependencies?.triggers || []
                ],
                (trigger) => [trigger.uuid, trigger.version].join('-')
            );
            dispatch(setTriggers(sortBy(triggers, trigger => {
                return [trigger.orgId === org?.id ? 0 : 1, trigger.name]
            })))
        }
        if (eventsQuery.isSuccess) {
            dispatch(setEvents(sortBy(eventsQuery.data, event => event.title)))
        }
    }, [
        publicNodesQuery.isSuccess, orgNodesQuery.isSuccess,
        publicTriggersQuery.isSuccess, orgTriggersQuery.isSuccess,
        eventsQuery.isSuccess, ruleQuery.isSuccess
    ]);

    return <></>;
}