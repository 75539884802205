import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {baseQuery} from '../helpers/base-query';
import {OrganizationInterface} from "../interfaces/organization.interface";

export const organizationsApi = createApi({
    reducerPath: 'organizationsApi',
    baseQuery,
    tagTypes: ['Organization'],
    endpoints: (build) => ({
        getOrganization: build.query<OrganizationInterface, {
            orgId: number;
        }>({
            query: ({orgId}) => `${process.env.REACT_APP_ACCOUNT_BASE_URL}/organizations/${orgId}`
        }),
        checkOrgByName: build.query<Partial<OrganizationInterface>, string>({
            query: (orgName) => `${process.env.REACT_APP_ACCOUNT_BASE_URL}/organizations/check/${orgName}`
        }),
    })
});

export const {
    useGetOrganizationQuery,
    useCheckOrgByNameQuery,
    useLazyCheckOrgByNameQuery
} = organizationsApi;
