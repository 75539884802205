import {Button, Dimmer, Form, Loader, Message, Modal} from "semantic-ui-react";
import {useCreateRuleMutation} from "../../apis/rules.api";
import {useEffect, useState} from "react";
import {RuleStatusEnum} from "../../interfaces/rule-status.enum";
import {useNavigate, useParams} from "react-router-dom";

export function CreateRuleModal({open, onClose}: {open: boolean; onClose?: () => void}) {
    const [createRule, {isLoading, isSuccess, isError, error, data}] = useCreateRuleMutation();
    const [name, setName] = useState('');
    const [category, setCategory] = useState<string>();
    const [tags, setTags] = useState<string>();
    const [description, setDescription] = useState<string>();
    const navigate = useNavigate();
    const {organization} = useParams()

    useEffect(() => {
        setName('');
        setCategory(undefined);
        setDescription(undefined);
        setTags(undefined);
    }, [open])

    useEffect(() => {
        if (isSuccess) {
            onClose?.call(null);
            data?.uuid && navigate(['/org', organization, 'rules', 'edit', data.uuid, data.version].join('/'));
        }
    }, [isSuccess]);

    return (
        <Modal closeIcon open={open} onClose={onClose} size="mini">
            <Dimmer active={isLoading} inverted><Loader /></Dimmer>
            <Modal.Header>Create new rule</Modal.Header>
            <Modal.Content>
                {isError && !(error as any)?.data?.errors && <Message error content={(error as any)?.data?.error?.description || 'Unknown error'} />}
                <Form>
                    <Form.Input
                        autoFocus
                        label="Name"
                        type="text"
                        value={name}
                        error={(error as any)?.data?.errors?.name?.[0]}
                        onChange={(_e, {value}) => setName(value)}
                    />
                    <Form.TextArea
                        label="Description (optional)"
                        value={description}
                        error={(error as any)?.data?.errors?.description?.[0]}
                        onChange={(_e, {value}) => setDescription(value?.toString() || undefined)}
                    />
                    <Form.Input
                        label="Category (optional)"
                        type="text"
                        value={category}
                        error={(error as any)?.data?.errors?.category?.[0]}
                        onChange={(_e, {value}) => setCategory(value || undefined)}
                    />
                    <Form.Input
                        label="Tags (optional, separated by comma or space)"
                        type="text"
                        value={tags}
                        error={(error as any)?.data?.errors?.tags?.[0]}
                        onChange={(_e, {value}) => setTags(value || undefined)}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button primary content="Create" icon="check" onClick={() => {
                    createRule({
                        name,
                        description,
                        category,
                        tags: tags?.split(/[\s,]/).map(i => i.trim()).filter(i => !!i),
                        status: RuleStatusEnum.ACTIVE,
                        params: [],
                        flow: [],
                    })
                }} />
            </Modal.Actions>
        </Modal>
    );
}