import {memo} from "react";
import {Button, Loader} from "semantic-ui-react";
import SyntaxHighlighter from "react-syntax-highlighter/dist/cjs/light";
import {docco} from "react-syntax-highlighter/dist/esm/styles/hljs";
import {useAppSelector} from "../store";
import {RuleNodeInterface} from "../interfaces/rule-node.interface";
import {useGetScratchpadQuery, useResetScratchpadMutation} from "../apis/instances.api";
import './FlowNode.scss';

export const FlowScratchpadNode = memo(({data, isConnectable, type}: {
    data: {
        data: RuleNodeInterface
    };
    isConnectable: boolean;
    type: string;
}) => {
    const currentInstance = useAppSelector(state => state.debug.currentInstance);
    const scratchpadQuery = useGetScratchpadQuery(currentInstance?.uuid as string, {
        skip: !currentInstance?.uuid
    })
    const [resetScratchpad, resetScratchpadQuery] = useResetScratchpadMutation();

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: 10}}>
                {(scratchpadQuery.isLoading || resetScratchpadQuery.isLoading) ? (
                    <Loader size="mini" active />
                ) : (
                    <>
                        {currentInstance && (
                            <>
                                <Button loading={scratchpadQuery.isLoading || scratchpadQuery.isFetching} icon="refresh" color="teal" circular style={{position: 'absolute', top: 5, right: 95}} size="mini" onClick={(e) => {
                                    scratchpadQuery.refetch();
                                    e.preventDefault();
                                    e.stopPropagation();
                                }} />
                                <Button content="Reset state" color="teal" circular style={{position: 'absolute', top: 5, right: 2}} size="mini" onClick={(e) => {
                                    resetScratchpad(currentInstance?.uuid as string);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }} />
                            </>
                        )}
                        <div style={{lineHeight: 1.2}}>
                            <div>Scratchpad state</div>
                        </div>
                        {currentInstance ? (
                            <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: 0, backgroundColor: 'transparent', width: '100%'}}>
                                {JSON.stringify(scratchpadQuery?.data, null, 2)}
                            </SyntaxHighlighter>
                        ) : (
                            <p style={{opacity: 0.5, marginTop: '1em'}}>No instance selected</p>
                        )}
                    </>
                )}
            </div>
        </>
    );
})