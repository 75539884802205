import {useEffect, useMemo, useState} from "react";
import {Button, Container, Dimmer, Divider, Grid, Input, Label, Loader, Message, Segment, Tab} from "semantic-ui-react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {compact, flatten, groupBy, intersection, map, maxBy, uniq, xor} from "lodash";
import {useFindNodesQuery} from "../../apis/nodes.api";
import {NodesList} from "./nodes/NodesList";
import {NodeStatusEnum} from "../../interfaces/node-status.enum";
import {NavLink, Route, Routes, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store";
import {setNodesViewMode} from "../../slices/settings.slice";
import {CreateNodeModal} from "../../components/node/CreateNodeModal";

export function Nodes() {
    const {organization, tag, section} = useParams<{
        organization: string;
        tag: string;
        section: 'drafts' | 'releases' | 'public';
    }>();
    const auth = useAppSelector((state) => state.auth);
    const org = useAppSelector(state => state.auth.org);
    const viewMode = useAppSelector(state => state.settings.nodesViewMode);
    const [search, setSearch] = useState('');
    const [tagFilter, setTagFilter] = useState<string[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
    const [createModalOpened, setCreateModalOpened] = useState(false);
    const dispatch = useAppDispatch();

    const nodeStatusSectionMap = {
        drafts: NodeStatusEnum.DRAFT,
        releases: NodeStatusEnum.ACTIVE,
        public: NodeStatusEnum.PUBLIC
    };

    const {data, isLoading, isError, isFetching, error, refetch: refetchNodes} = useFindNodesQuery({
        limit: -1,
        getUsageCount: true,
        createdBy: organization ? undefined : auth.accountId,
        orgId: org?.id,
        status: section ? nodeStatusSectionMap[section] : undefined,
        groupByVersion: section === 'public' ? true : undefined
    });

    const nodes = useMemo(() => {
        return data?.data
            .filter(node => {
                switch (tag) {
                    case 'recent':
                        return true;
                    case 'org':
                        return node.orgId === auth.orgId;
                    case 'shared':
                        return node.orgId !== auth.orgId;
                    default:
                        return true;
                }
            })
            .filter(node => !search.trim() || node.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1)
            .filter(node => {
                return (!categoryFilter.length || categoryFilter.includes(node.category || '')) &&
                    (!tagFilter.length || intersection(tagFilter, node.tags).length > 0);
            }) || []
    }, [categoryFilter, tagFilter, data, tag, search]);

    const categories = compact(uniq(data?.data.map(node => node.category)));
    const tags = compact(uniq(flatten(data?.data.map(node => node.tags))));

    return (
        <>
            {isFetching && <Dimmer active inverted style={{backgroundColor: 'transparent'}}>
                <Loader inverted content='Loading'/>
            </Dimmer>}
            <PerfectScrollbar>
                {createModalOpened && <CreateNodeModal open onClose={() => setCreateModalOpened(false)}/>}
                <Segment basic>
                    <Container>
                        <Grid>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <h1>{organization ? `Nodes` : 'My Nodes'}</h1>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={8} textAlign="right">
                                <Button
                                    color="teal" circular icon="add" content={'Create new node'}
                                    onClick={() => setCreateModalOpened(true)}
                                />
                                <Button circular basic color="teal" icon="refresh" onClick={refetchNodes} loading={isFetching} />
                                <Button
                                    basic
                                    circular
                                    color={'teal'}
                                    icon={viewMode === 'cards' ? 'table' : 'address card outline'}
                                    title={viewMode === 'cards' ? 'Table view' : 'Cards view'}
                                    onClick={() => {
                                        dispatch(setNodesViewMode(viewMode === 'cards' ? 'table' : 'cards'))
                                    }}
                                />
                            </Grid.Column>
                        </Grid>
                        <Divider hidden/>
                        {isError &&
                            <Message error content={(error as any).data?.error?.description || 'Unknown error'}/>}

                        <Tab
                            menu={{secondary: true, pointing: true}}
                            renderActiveOnly={false}
                            activeIndex={-1}
                            panes={[{
                                menuItem: {
                                    as: NavLink,
                                    content: 'Drafts',
                                    to: ['/org', organization, 'nodes', 'drafts', tag].join('/'),
                                    end: true,
                                    key: 'drafts',
                                }
                            }, {
                                menuItem: {
                                    as: NavLink,
                                    content: 'Releases',
                                    to: ['/org', organization, 'nodes', 'releases', tag].join('/'),
                                    key: 'releases'
                                }
                            }, {
                                menuItem: {
                                    as: NavLink,
                                    content: 'Published',
                                    to: ['/org', organization, 'nodes', 'public', tag].join('/'),
                                    key: 'public'
                                }
                            }]}
                        />
                        <Divider hidden/>
                        <Grid columns={2}>
                            <Grid.Column>
                                <Tab menu={{secondary: true}}
                                     renderActiveOnly={false}
                                     activeIndex={-1}
                                     panes={[
                                         {
                                             menuItem: {
                                                 as: NavLink,
                                                 content: 'Recent',
                                                 to: ['/org', organization, 'nodes', section, 'recent'].filter(i => !!i).join('/'),
                                                 key: 'recent',
                                             },
                                         }, {
                                             menuItem: {
                                                 as: NavLink,
                                                 content: 'Your organization',
                                                 to: ['/org', organization, 'nodes', section, 'org'].filter(i => !!i).join('/'),
                                                 key: 'org',
                                             },
                                         },
                                         // {
                                         //     menuItem: {
                                         //         as: NavLink,
                                         //         content: 'Shared',
                                         //         to: ['/org', organization, 'nodes', section, 'shared'].filter(i => !!i).join('/'),
                                         //         key: 'shared',
                                         //     }
                                         // }
                                     ]}
                                />
                            </Grid.Column>
                            <Grid.Column textAlign={'right'}>
                                <Input
                                    icon="search" placeholder="Search..." value={search}
                                    onChange={(e, {value}) => setSearch(value)}
                                />
                            </Grid.Column>
                        </Grid>
                        <Divider hidden/>
                        {(categories.length > 0 || tags.length > 0) && (
                            <>
                                <div style={{paddingBottom: '0.5em'}}>
                                    {categories.map(category => (
                                        <Label
                                            basic={!categoryFilter.includes(category)}
                                            style={{borderWidth: 1, cursor: 'pointer'}}
                                            content={category}
                                            onClick={() => setCategoryFilter(xor(categoryFilter, [category]))}
                                        />
                                    ))}
                                </div>
                                <div>
                                    {tags.map(tag => (
                                        <Label
                                            basic={!tagFilter.includes(tag)}
                                            style={{borderWidth: 1, cursor: 'pointer'}}
                                            content={`#${tag}`}
                                            onClick={() => setTagFilter(xor(tagFilter, [tag]))}
                                        />
                                    ))}
                                </div>
                                <Divider hidden/>
                            </>
                        )}
                        {!isLoading && !isFetching && <NodesList view={viewMode} nodes={nodes}/>}
                        {!nodes.length && !isLoading && !isFetching &&
                            <Message>No nodes matching the filter found</Message>
                        }
                    </Container>
                </Segment>
            </PerfectScrollbar>
        </>
    )
}