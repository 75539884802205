export enum RuleEdgeTypeEnum {
    EDGE = 'edge'
}

export interface RuleEdgeInterface {
    uuid: string;
    type: RuleEdgeTypeEnum;
    source: string;
    target: string;
    if?: boolean;
}