import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {clone} from "lodash";
import {OrganizationInterface} from "../interfaces/organization.interface";

export interface AuthStateInterface {
    loggedIn: boolean;
    username?: string;
    name?: string;
    accountId?: number;
    orgId?: number;
    roles?: string[];
    accessToken?: string;
    org?: OrganizationInterface;
}

export interface AuthPayloadInterface {
    username: string;
    name: string;
    accountId: number;
    orgId: number;
    roles: string[];
    accessToken: string;
}

const initialState: AuthStateInterface = {
    loggedIn: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logIn: (draft: AuthStateInterface, action: PayloadAction<AuthPayloadInterface>) => {
            draft.loggedIn = true;
            Object.assign(draft, action.payload);
        },
        logOut: (draft: AuthStateInterface) => {
            return clone(initialState);
        },
        setOrg: (draft: AuthStateInterface, action: PayloadAction<OrganizationInterface | undefined>) => {
            draft.org = action.payload;
        }
    },
});

export const {logIn, logOut, setOrg} = authSlice.actions;

export const authReducer = authSlice.reducer;