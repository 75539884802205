import {Button, Dimmer, Icon, Input, Item, Loader, Message, Pagination, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
// import {RuleSortEnum} from "../../../../interfaces/node-sort.enum";
import {useDebounce} from "../../../../hooks/useDebounce";
import {useAppDispatch} from "../../../../store";
import {setTitle} from "../../../../slices/marketplace.slice";
import {useFindNodesQuery} from "../../../../apis/nodes.api";
import {NodeStatusEnum} from "../../../../interfaces/node-status.enum";

export function NodesList() {
    const [page, setPage] = useState<number>(1);
    // const [sort, setSort] = useState<RuleSortEnum>(RuleSortEnum.NEWEST);
    const [search, setSearch] = useState<string | undefined>(undefined);
    // const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const debouncedSearch = useDebounce(search, 500);
    const dispatch = useAppDispatch();

    const {data: nodes, isFetching, isError, error, refetch: refetchRules} = useFindNodesQuery({
        limit: 10,
        status: NodeStatusEnum.PUBLIC,
        name: debouncedSearch,
        // sort,
        page,
        // order,
        groupByVersion: true,
        getUsageCount: true,
    });

    useEffect(() => {
        refetchRules();
    // }, [page, sort, debouncedSearch, order]);
    }, [page, debouncedSearch]);

    useEffect(() => {
        dispatch(setTitle('Marketplace'));
    }, []);

    return (
        <>
            <Input icon='search'
                   placeholder='Search...'
                   style={{marginRight: 30, marginTop: '0.7em', marginBottom: '0.9em'}}
                   value={search || ''}
                   onChange={(e, data) => {
                       setSearch(data.value || undefined);
                       setPage(1);
                   }}
            />
            {/*<Button.Group basic style={{marginBottom: 15, marginTop: 15}} size={'mini'}>*/}
            {/*    <Button content="Newest"*/}
            {/*            active={sort === RuleSortEnum.NEWEST}*/}
            {/*            icon={sort === RuleSortEnum.NEWEST ? (order === 'asc' ? "arrow up" : "arrow down") : false}*/}
            {/*            onClick={() => {*/}
            {/*                setPage(1);*/}
            {/*                if (sort === RuleSortEnum.NEWEST) {*/}
            {/*                    setOrder(order === 'asc' ? 'desc' : 'asc')*/}
            {/*                } else {*/}
            {/*                    setSort(RuleSortEnum.NEWEST)*/}
            {/*                }*/}
            {/*            }}*/}
            {/*    />*/}
            {/*    <Button content="Popular"*/}
            {/*            active={sort === RuleSortEnum.POPULAR}*/}
            {/*            icon={sort === RuleSortEnum.POPULAR ? (order === 'asc' ? "arrow up" : "arrow down") : false}*/}
            {/*            onClick={() => {*/}
            {/*                setPage(1);*/}
            {/*                if (sort === RuleSortEnum.POPULAR) {*/}
            {/*                    setOrder(order === 'asc' ? 'desc' : 'asc')*/}
            {/*                } else {*/}
            {/*                    setSort(RuleSortEnum.POPULAR)*/}
            {/*                }*/}
            {/*            }}*/}
            {/*    />*/}
            {/*</Button.Group>*/}

            {isError && <Message error content={(error as any).data?.error?.description || 'Unknown error'}/>}
            <Segment style={{marginBottom: 0}}>
                <Dimmer active={isFetching} inverted>
                    <Loader inverted/>
                </Dimmer>
                {!nodes?.data.length && <div style={{textAlign: 'center', marginTop: 20}}>Nothing found</div>}
                <Item.Group divided style={{marginTop: 10}}>
                    {nodes?.data.map((node, i) => (
                        <Item key={`node-${i}`} style={{padding: '20px 0'}}>
                            <Item.Content>
                                <Item.Header
                                    as={Link}
                                    to={`${node.uuid}/${node.version}`}
                                >{node.name}</Item.Header>
                                <Item.Description>{node.description}</Item.Description>
                                <Item.Extra>
                                    <Button primary floated='right' as={Link} to={`${node.uuid}/${node.version}`}
                                            content="View"/>
                                    <div style={{paddingTop: '1.5em'}}>
                                        <Icon name="clock outline"/>last
                                        update: {new Date(node.updatedAt).toLocaleDateString()},
                                        latest version: {node.version},
                                        usage in rules: {node.usageCount}
                                    </div>
                                </Item.Extra>
                            </Item.Content>
                        </Item>
                    ))}
                </Item.Group>
            </Segment>
            {(nodes?.pagination.total || 0) > (nodes?.pagination.limit || 0) && <Pagination
                activePage={page}
                onPageChange={(e, data) => setPage(parseInt(data.activePage as any))}
                totalPages={Math.ceil((nodes?.pagination.total || 1) / (nodes?.pagination.limit || 1))}
                style={{marginTop: 30}}
            />}
        </>
    )
}