import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../helpers/base-query";
import {PaginationInterface} from "../interfaces/pagination.interface";
import {TriggerInterface} from "../interfaces/trigger.interface";
import {CreateTriggerInterface} from "../interfaces/create-trigger.interface";
import {TriggerStatusEnum} from "../interfaces/trigger-status.enum";

export const triggersApi = createApi({
    reducerPath: 'triggersApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        findTriggers: builder.query<{
            data: TriggerInterface[];
            pagination: PaginationInterface;
        }, {
            status?: TriggerStatusEnum;
            createdBy?: number;
            orgId?: number;
            limit?: number;
        }>({
            query: (params) => ({
                url: 'triggers',
                params
            }),
        }),

        getTrigger: builder.query<TriggerInterface, string>({
            query: (uuid) => `triggers/${uuid}`,
        }),

        createTrigger: builder.mutation<TriggerInterface, CreateTriggerInterface>({
            query: (body) => ({
                url: `triggers`,
                method: 'POST',
                body
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                const { data: createdTrigger } = await queryFulfilled;
                dispatch(
                    triggersApi.util.updateQueryData('findTriggers', {}, (draft) => {
                        draft.data.unshift(createdTrigger);
                    })
                );
            }
        }),

        updateTrigger: builder.mutation<TriggerInterface, Partial<CreateTriggerInterface> & Pick<TriggerInterface, 'uuid'>>({
            query: ({uuid, ...body}) => ({
                url: `triggers/${uuid}`,
                method: 'POST',
                body
            }),
            async onQueryStarted({ uuid, ...patch }, { dispatch, queryFulfilled }) {
                const { data: updatedTrigger } = await queryFulfilled;
                dispatch(
                    triggersApi.util.updateQueryData('getTrigger', uuid, (draft) => {
                        Object.assign(draft, updatedTrigger)
                    })
                );
                dispatch(
                    triggersApi.util.updateQueryData('findTriggers', {}, (draft) => {
                        const triggerIndex = draft.data.findIndex((trigger) => trigger.uuid === uuid);
                        Object.assign(draft.data[triggerIndex], updatedTrigger);
                    })
                );
            },
        }),

        deleteTrigger: builder.mutation<void, string>({
            query: (uuid) => ({
                url: `triggers/${uuid}`,
                method: 'DELETE',
            }),
            async onQueryStarted(uuid, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    triggersApi.util.updateQueryData('findTriggers', {}, (draft) => {
                        draft.data = draft.data.filter(item => item.uuid !== uuid);
                    })
                );
                queryFulfilled.catch(() => patchResult.undo());

            }
        }),
    }),
})

export const {useFindTriggersQuery, useGetTriggerQuery, useCreateTriggerMutation, useUpdateTriggerMutation, useDeleteTriggerMutation} = triggersApi;