import {createAsyncThunk} from '@reduxjs/toolkit'
import {RuleService} from "../services/rule.service";
import {CreateRuleInterface} from "../interfaces/create-rule.interface";
import {UpdateRuleInterface} from "../interfaces/update-rule.interface";
import {TestRuleInterface} from "../interfaces/test-rule.interface";

export const findRules = createAsyncThunk(
    'rules/find',
    async (params: {limit: number}, thunkAPI) => {
        return await RuleService.find(params);
    }
)

export const createRule = createAsyncThunk(
    'rules/create',
    async (ruleData: CreateRuleInterface, thunkAPI) => {
        return await RuleService.create(ruleData);
    }
)

export const updateRule = createAsyncThunk(
    'rules/update',
    async (ruleData: UpdateRuleInterface, thunkAPI) => {
        return await RuleService.update(ruleData.ruleUuid, ruleData);
    }
)

export const getRule = createAsyncThunk(
    'rules/get',
    async (ruleUuid: string, thunkAPI) => {
        return await RuleService.get(ruleUuid);
    }
)

export const testRule = createAsyncThunk(
    'rules/test',
    async (testData: TestRuleInterface, thunkAPI) => {
        return await RuleService.test(
            testData.ruleUuid,
            testData.ruleVersion,
            testData.triggerParams,
            testData.ruleParams,
            testData.eventData,
            testData.runTimes
        );
    }
)