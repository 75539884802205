import {Button, Dimmer, Form, Loader, Message, Modal} from "semantic-ui-react";
import {cloneElement, ReactElement, useEffect, useState} from "react";
import {useFireInstanceMutation, useGetTriggerInstancesQuery} from "../../apis/instances.api";
import {InstanceInterface} from "../../interfaces/instance.interface";
import {JsonEditor} from "../JsonEditor";
import {setDebugData, setDebugNodeUuid, setDebugUuid, startDebug} from "../../slices/debug.slice";
import {setActiveNodeUuid} from "../../slices/flow.slice";
import {useAppDispatch, useAppSelector} from "../../store";

export function FireInstanceModal({children, instance, triggerUuid}: {children: ReactElement, instance: InstanceInterface, triggerUuid?: string}) {
    const [open, setOpen] = useState(false);
    const [fireInstance, fireInstanceResult] = useFireInstanceMutation();
    const triggerInstancesQuery = useGetTriggerInstancesQuery(instance.uuid, {
        skip: !open
    });
    const events = useAppSelector(state => state.editor.events);
    const [errors, setErrors] = useState<{
        triggerInstanceUuid?: string;
        eventData?: string;
    }>({
        triggerInstanceUuid: undefined,
        eventData: undefined
    });
    const [formData, setFormData] = useState({
        ruleInstanceUuid: instance.uuid,
        triggerInstanceUuid: '',
        eventData: {}
    });
    const dispatch = useAppDispatch();

    useEffect(() => {
        open && triggerInstancesQuery.refetch();
    }, [open]);

    useEffect(() => {
        if (fireInstanceResult.isSuccess) {
            dispatch(startDebug());
            dispatch(setActiveNodeUuid(undefined));
            dispatch(setDebugNodeUuid(undefined));
            setOpen(false);
        }
    }, [fireInstanceResult.isSuccess]);

    useEffect(() => {
        if (fireInstanceResult.data) {
            dispatch(setDebugUuid(fireInstanceResult.data.instanceRunUuid));
            dispatch(setDebugData([fireInstanceResult.data.data]));
        }
    }, [fireInstanceResult.data]);

    useEffect(() => {
        const triggerInstance = triggerInstancesQuery.data?.data?.find(item => item.triggerInstanceUuid === formData.triggerInstanceUuid);
        const event = events.find(event => event.event === triggerInstance?.triggerEvent);

        if (event) {
            setErrors({...errors, eventData: ''});
            setFormData({...formData, eventData: ''});
            setTimeout(() => {
                setFormData({...formData, eventData: event.dataExample});
            }, 0)
        } else {
            setFormData({...formData, eventData: {}});
        }
    }, [formData.triggerInstanceUuid, triggerInstancesQuery.data]);

    useEffect(() => {
        if (open) {
            const triggerInstance = triggerInstancesQuery.data?.data?.find(item => item.triggerUuid === triggerUuid);
            const event = events.find(event => event.event === triggerInstance?.triggerEvent);

            setErrors({...errors, eventData: ''});
            setFormData({
                ruleInstanceUuid: instance.uuid,
                triggerInstanceUuid: triggerInstance?.triggerInstanceUuid || '',
                eventData: ''
            });
            setTimeout(() => {
                setFormData({
                    ruleInstanceUuid: instance.uuid,
                    triggerInstanceUuid: triggerInstance?.triggerInstanceUuid || '',
                    eventData: event?.dataExample || {}});
            }, 0)
        }
    }, [open, triggerInstancesQuery.data, triggerUuid]);

    useEffect(() => {
        if (fireInstanceResult.isSuccess) {
            setOpen(false);
        }
    }, [fireInstanceResult])

    function fire() {
        fireInstance(formData);
    }

    return (
        <>
            {cloneElement(children, {
                onMouseDownCapture: (e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                },
                onClick: (e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(true);
                }})
            }
            <Modal size="tiny" closeIcon open={open} closeOnDimmerClick closeOnEscape onClose={() => setOpen(false)}>
                <Modal.Header>Fire instance</Modal.Header>
                <Modal.Content>
                    {fireInstanceResult.isError && <Message error content={(fireInstanceResult.error as any)?.data?.error?.description || 'Unknown error'}/>}
                    <Dimmer active={fireInstanceResult.isLoading || triggerInstancesQuery.isFetching} inverted><Loader/></Dimmer>
                    <Form>
                        <Form.Select
                            label="Select trigger"
                            placeholder="Select..."
                            value={formData.triggerInstanceUuid}
                            options={triggerInstancesQuery.data?.data?.map((triggerInstance) => ({
                                key: triggerInstance.triggerInstanceUuid,
                                value: triggerInstance.triggerInstanceUuid,
                                text: triggerInstance.triggerName || triggerInstance.triggerInstanceEvent,
                                content: (
                                    <div>
                                        <div>{triggerInstance.triggerName || triggerInstance.triggerInstanceEvent}</div>
                                        <div style={{
                                            opacity: 0.5,
                                            fontWeight: 'normal',
                                            marginTop: 5
                                        }}>{triggerInstance.triggerInstanceEvent}</div>
                                    </div>
                                ),
                            })) || []}
                            onChange={(e, {value}) => {
                                setFormData({...formData, triggerInstanceUuid: value as string})
                            }}
                        />
                        <Form.Field>
                            <label style={{color: errors['eventData'] ? 'red' : 'inherit'}}>Event data</label>
                            <div style={{height: 300}}>
                                {formData.eventData && <JsonEditor
                                    value={formData.eventData}
                                    onChange={(value) => {
                                        setFormData({
                                            ...formData,
                                            eventData: value
                                        })
                                        setErrors({...errors, eventData: ''});
                                    }}
                                    onError={(error) => {
                                        setErrors({...errors, eventData: error});
                                    }}
                                />}
                            </div>
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Fire" primary disabled={!formData.triggerInstanceUuid || !!errors.eventData} icon="lightning" onClick={() => fire()}/>
                </Modal.Actions>
            </Modal>
        </>
    );
}