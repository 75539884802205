import {Link} from "react-router-dom";
import {Button, Divider, Grid, Segment} from "semantic-ui-react";
import {RuleInfo} from "../../../../components/RuleInfo";
import {CreateInstanceModal} from "../../../../components/instance/CreateInstanceModal";
import {StartDebugModal} from "../../../../components/debug/StartDebugModal";
import {RuleInterface} from "../../../../interfaces/rule.interface";

export function OverviewTab({rule}: { rule: RuleInterface }) {
    return (
        <>
            <Divider hidden/>
            <Segment>
                <Grid columns={2}>
                    <Grid.Column width={10}>
                        <RuleInfo rule={rule} showHeader={false} padded={false}/>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <CreateInstanceModal ruleUuid={rule.uuid} ruleVersion={rule.version}>
                            <Button primary
                                    fluid
                                    icon="plus"
                                    style={{marginBottom: 5}}
                                    content="Install"
                            />
                        </CreateInstanceModal>
                        <Button icon="play"
                                basic
                                color="black"
                                content="Try"
                                fluid as={Link}
                                to={`/marketplace/rules/view/${rule.uuid}/${rule.version}`}
                        />
                    </Grid.Column>
                </Grid>
            </Segment>
        </>
    );
}
