import {NodeInterface} from "../interfaces/node.interface";
import {NodeStatusEnum} from "../interfaces/node-status.enum";

export function getNodeLink(node: NodeInterface, orgName: string): string {

    let section = 'edit';

    if (node.status === NodeStatusEnum.ACTIVE) {
        section = 'release';
    }

    if (node.status === NodeStatusEnum.PUBLIC) {
        section = 'public';
    }

    return `/org/${orgName}/nodes/${section}/${node.uuid}/${node.version}`

}