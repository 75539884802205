import {RuleInterface} from "../interfaces/rule.interface";
import {List, Segment} from "semantic-ui-react";
import {useAppSelector} from "../store";
import {Arn} from "./Arn";

export function RuleInfo({rule, showHeader = true, padded = true}: { rule: RuleInterface, showHeader?: boolean, padded?: boolean }) {
    const triggers = useAppSelector(state => state.editor.triggers);
    const ruleTriggers = triggers.filter((trigger) => rule.triggers.map(i => i.triggerUuid).indexOf(trigger.uuid) !== -1);

    return (
        <Segment basic style={padded ? {} : {padding: 0}}>
            {showHeader && (
                <h3>{rule.name}</h3>
            )}
            <List divided>
                <List.Item style={{padding: '10px 0'}}>
                    <List.Content>Created by: {rule.createdBy}</List.Content>
                </List.Item>
                <List.Item style={{padding: '10px 0'}}>
                    <List.Content>Version: {rule.version}</List.Content>
                </List.Item>
                <List.Item style={{padding: '10px 0'}}>
                    <List.Content>Status: {rule.status}</List.Content>
                </List.Item>
                <List.Item style={{padding: '10px 0'}}>
                    <List.Content>Description: {rule.description}</List.Content>
                </List.Item>
                <List.Item style={{padding: '10px 0'}}>
                    <List.Content>Created at: {new Date(rule.createdAt).toLocaleString()}</List.Content>
                </List.Item>
                <List.Item style={{padding: '10px 0'}}>
                    <List.Content>Updated at: {new Date(rule.updatedAt).toLocaleString()}</List.Content>
                </List.Item>
            </List>
            <h5>Rule params</h5>
            <List divided>
                {rule.params.map((param, i) => (
                    <List.Item key={i} style={{padding: '10px 0'}}>
                        <List.Content>
                            {param.title}: {param.description || param.type.toString()}
                        </List.Content>
                    </List.Item>
                ))}
                {rule.params?.length === 0 && 'Rule has no any params'}
            </List>
            <h5>Rule triggers</h5>
            <List divided>
                {ruleTriggers?.map((trigger, i) => (
                    <List.Item key={i} style={{padding: '10px 0'}}>
                        {trigger.name}<br/>
                        <Arn arn={trigger.event} secondary />
                    </List.Item>
                ))}
                {ruleTriggers?.length === 0 && 'Rule has no any triggers'}
            </List>
        </Segment>
    )
}