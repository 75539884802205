import {Divider, Header, Segment} from "semantic-ui-react";

export function Help() {

    return (
        <Segment basic>
            <Header style={{marginTop: '0.25em'}}>
                Help
            </Header>
            <Divider/>
        </Segment>
    )
}