import {Button, Segment} from "semantic-ui-react";
import {useAppDispatch, useAppSelector} from "../../store";
import SyntaxHighlighter from "react-syntax-highlighter/dist/cjs/light";
import {xcode} from "react-syntax-highlighter/dist/esm/styles/hljs";
import javascript from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import yaml from "react-syntax-highlighter/dist/esm/languages/hljs/yaml";
import {setDebugData} from "../../slices/nodes.slice";
import PerfectScrollbar from "react-perfect-scrollbar";

SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('yaml', yaml);

export function DebugWindow() {
    const dispatch = useAppDispatch();
    const debugData = useAppSelector((state) => state.nodes.editor.debugData);

    if (!debugData) {
        return <></>;
    }

    return <Segment
        style={{
            height: '50%',
            margin: 0,
            padding: 0,
            borderRadius: 0,
            border: 0,
            borderTop: '1px solid rgba(34,36,38,.15)'
        }}>
        <Button circular icon="close" size="mini"
                onClick={() => dispatch(setDebugData(undefined))}
                style={{position: 'absolute', right: 10, top: 10, zIndex: 1}}
        />
        <PerfectScrollbar>
            <Segment basic style={{padding: 0, margin: 0, height: '100%', background: 'rgb(248, 248, 255)'}}>
                <SyntaxHighlighter language="yaml" style={{...xcode}}
                                   customStyle={{margin: 0, padding: '2em', background: 'rgb(248, 248, 255)'}}>
                    {
                        `1: Input data: ${JSON.stringify(debugData.inputData, null, 2)}\n` +
                        `2: Node params: ${JSON.stringify(debugData.nodeParams, null, 2)}\n` +
                        (debugData?.logs?.map((item, i) => {
                            return `${i + 3}: Log: ${JSON.stringify(item, null, 2)}\n`;
                        }).join('') || '') +
                        `${debugData.logs.length + 3}: Result: ${JSON.stringify(debugData.result, null, 2)}\n`
                    }
                </SyntaxHighlighter>
            </Segment>
        </PerfectScrollbar>
    </Segment>
}