import {useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store";
import {AuthPayloadInterface, logIn, logOut, setOrg} from "../../slices/auth.slice";
import {useEffect, useRef, useState} from "react";
import {useGetOrganizationQuery} from "../../apis/organizations.api";

export function Sso() {
    const data = useLocation();
    const dispatch = useAppDispatch();
    const mounted = useRef(false);

    const orgId = useAppSelector((state) => state.auth.orgId);
    const [params, setParams] = useState<URLSearchParams>()
    const [authData, setAuthData] = useState<AuthPayloadInterface>();
    const {data: org, isSuccess} = useGetOrganizationQuery({
        orgId: orgId as number
    }, {
        skip: !orgId
    });

    useEffect(() => {
        setParams(new URLSearchParams(data.search));
    }, [data.search]);

    useEffect(() => {
        try {
            if (params?.get('data')) {
                const parsed = JSON.parse(atob(params?.get('data') || '') || '{}');
                mounted.current = true;
                setAuthData(parsed);
            }
        } catch (e) {
            setAuthData(undefined);
            console.error('Authentication error', e, params?.get('data'));
        }
    }, [params]);

    useEffect(() => {
        if (authData?.accessToken) {
            dispatch(logIn(authData));
        } else {
            dispatch(logOut());
        }
    }, [authData]);

    useEffect(() => {
        if (isSuccess && org) {
            dispatch(setOrg(org));
        } else {
            dispatch(setOrg(undefined));
        }
    }, [isSuccess])

    return <>
        <></>
    </>

}