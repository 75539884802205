import {Card, Icon, Label, Menu, Popup} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {useRef, useState} from "react";
import {createContextFromEvent} from "../../helpers/common";
import {TriggerInterface} from "../../interfaces/trigger.interface";
import {useDeleteTriggerMutation} from "../../apis/triggers.api";

export function TriggersList({triggers, create}: { triggers: TriggerInterface[]; create?: boolean }) {
    const contextRef = useRef<any>()
    const [contextOpened, setContextOpened] = useState(false)
    const [activeTrigger, setActiveTrigger] = useState<TriggerInterface>();
    const [deleteTrigger] = useDeleteTriggerMutation();

    return (
        <>
            <Card.Group itemsPerRow={3} stackable doubling>
                {triggers.map((trigger, i) => (
                    <Card key={`trigger-${i}`}
                          link
                          as={Link}
                          to={trigger.uuid}
                          className={!trigger.createdBy ? 'waiting' : ''}
                          onContextMenu={(e: any) => {
                              e.preventDefault();
                              contextRef.current = createContextFromEvent(e);
                              setContextOpened(true);
                              setActiveTrigger(trigger);
                          }}
                    >
                        <Card.Content>
                            <Card.Header>{trigger.name}</Card.Header>
                            <Card.Description>
                                {trigger.event}
                            </Card.Description>
                            <br/>
                            <Card.Description style={{opacity: 0.6}}>
                                Version: {trigger.version}<br/>
                                {/*Status: {trigger.status}*/}
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>
                                <Icon name='clock outline'/> {new Date(trigger.createdAt).toDateString()}
                            </div>
                            {!trigger.createdBy && <Label color="orange" content="System" style={{marginLeft: 'auto'}}/>}
                        </Card.Content>
                    </Card>
                ))}
                {create && (
                    <Card style={{minHeight: 175}}  as={Link} to={'new'}>
                        <Card.Content style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Card.Header>Create new trigger</Card.Header>
                        </Card.Content>
                    </Card>
                )}
            </Card.Group>

            <Popup
                basic
                position="bottom left"
                context={contextRef}
                onClose={() => setContextOpened(false)}
                open={contextOpened}
            >
                <Menu secondary vertical>
                    <Menu.Item as={Link} to={activeTrigger?.uuid} onClick={() => setContextOpened(false)}>
                        <Icon name="arrow alternate circle right outline"/>
                        Open
                    </Menu.Item>
                    <Menu.Item onClick={() => {
                        activeTrigger && deleteTrigger(activeTrigger.uuid);
                        setContextOpened(false);
                    }}>
                        <Icon name="trash"/>
                        Delete
                    </Menu.Item>
                </Menu>
            </Popup>
        </>
    )
}