import {Label, Segment} from "semantic-ui-react";
import {TriggerInterface} from "../interfaces/trigger.interface";
import {TriggerStatusEnum} from "../interfaces/trigger-status.enum";
import {RuleNodeTypeEnum} from "../interfaces/rule-node.interface";
import {useAppSelector} from "../store";

export function Trigger({trigger}: {trigger: TriggerInterface}) {
    const orgId = useAppSelector(state => state.auth.orgId);

    const onDragStart = (event: any) => {
        event.dataTransfer.setData('application/reactflow', JSON.stringify({
            type: RuleNodeTypeEnum.TRIGGER,
            uuid: trigger.uuid,
            version: trigger.version
        }));
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <Segment
            style={{
                backgroundColor: trigger.orgId === orgId ? '#dbdbdb' : undefined,
                cursor: 'grab',
            }}
            onDragStart={(event: any) => onDragStart(event)} draggable
        >
            {trigger.name}
            {/*{trigger.status !== TriggerStatusEnum.ACTIVE && <Label color='yellow' size='mini' style={{float: 'right'}}>{trigger.status}</Label>}*/}
        </Segment>
    );
}