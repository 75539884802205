import {useEffect, useRef, useState} from "react";
import {cloneDeep} from "lodash";
import {Button, Icon, List} from "semantic-ui-react";
import {NodeInterface} from "../../../interfaces/node.interface";
import {CreateTestCaseModal} from "./CreateTestCaseModal";
import {NodeTestCaseInterface, NodeTestCaseStatusEnum} from "../../../interfaces/node-test-case.interface";
import {useTestNodeMutation, useUpdateNodeMutation} from "../../../apis/nodes.api";

export function NodeTestCases({
    node,
    allowEditTestCases = true,
    showCreateButton = true,
    showTestAllButton = true,
    runTestCasesOnMounted = false,
    onStarted,
    onPassed,
    onFailed
}: {
    node: NodeInterface;
    allowEditTestCases?: boolean;
    showCreateButton?: boolean;
    showTestAllButton?: boolean;
    runTestCasesOnMounted?: boolean;
    onStarted?: (testCases: NodeTestCaseInterface[]) => void;
    onPassed?: (testCases: NodeTestCaseInterface[]) => void;
    onFailed?: (testCases: NodeTestCaseInterface[]) => void;
}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [edit, setEdit] = useState<NodeTestCaseInterface | undefined>();
    const [updateNode, updateResult] = useUpdateNodeMutation();
    const [testNode] = useTestNodeMutation();
    const [testCases, setTestCases] = useState<NodeTestCaseInterface[]>([]);
    const [testingAll, setTestingAll] = useState(false);
    const testedOnMount = useRef(false);

    useEffect(() => {
        if (testCases.length && runTestCasesOnMounted && !testedOnMount.current) {
            testedOnMount.current = true;
            testAll();
        }
    }, [testCases]);

    useEffect(() => {
        setTestCases(node.testCases || []);
    }, [node]);

    function deleteTestCase(uuid: string) {
        const filtered = testCases.filter(testCase => testCase.uuid !== uuid);
        setTestCases(filtered);
        updateNode({
            uuid: node.uuid,
            version: node.version,
            testCases: filtered
        })
    }

    async function testAll() {
        setTestingAll(true);
        let _testCases = cloneDeep(testCases.map(testCase => ({
            ...testCase,
            status: NodeTestCaseStatusEnum.PENDING
        })));
        onStarted?.call(null, _testCases);
        setTestCases(_testCases);
        let successCount = 0;
        for (let testCase of _testCases) {
            try {
                let result = await test(testCase.uuid);
                let success = (result as any)?.data?.assertResult?.success === true;
                testCase.status = success ? NodeTestCaseStatusEnum.SUCCESS : NodeTestCaseStatusEnum.FAILED;
                testCase.testFailureMessage = success ? '' : (result as any)?.data?.assertResult?.error;
                success && successCount++;
                setTestCases(cloneDeep(_testCases));
            } catch (e: any) {
                console.log(e);
                testCase.status = NodeTestCaseStatusEnum.FAILED;
                testCase.testFailureMessage = e.message || 'Unknown';
                setTestCases(cloneDeep(_testCases));
            }
        }
        setTestingAll(false);

        if (successCount === _testCases.length) {
            onPassed?.call(null, _testCases);
        } else {
            onFailed?.call(null, _testCases);
        }

        updateNode({
            uuid: node.uuid,
            version: node.version,
            testCases: _testCases
        })
    }

    async function test(testCaseId: string) {
        const testCase = testCases?.find(item => item.uuid === testCaseId);
        if (!testCase) {
            return;
        }
        return testNode({
            uuid: node.uuid,
            version: node.version,
            inputData: testCase.input,
            nodeParams: testCase.nodeParams,
            assertions: testCase.assertions,
        });
    }

    return (
        <>
            {showTestAllButton && testCases.length > 0 && (
                <Button disabled={testingAll} primary basic content="Run test cases" fluid onClick={testAll}/>
            )}
            <List divided>
                {testCases.map(testCase => (
                    <List.Item style={{padding: '1em 0', gap: 5, display: 'flex', justifyContent: 'space-between'}}>
                        {testCase.status === NodeTestCaseStatusEnum.PENDING && (
                            <Icon name="refresh" color="grey" loading title="Pending"/>
                        )}
                        {testCase.status === NodeTestCaseStatusEnum.SUCCESS && (
                            <Icon name="check circle" color="teal" title="Passed"/>
                        )}
                        {testCase.status === NodeTestCaseStatusEnum.FAILED && (
                            <Icon name="minus circle" color="red" title="Failed"/>
                        )}
                        {allowEditTestCases ? (
                            <>
                                <span style={{cursor: 'pointer'}} onClick={() => {
                                    setEdit(testCase);
                                    setModalOpen(true);
                                }}>
                                {testCase.name || 'Unnamed'}
                            </span>
                                <Icon disabled={testingAll}
                                      name="trash alternate outline" color="grey"
                                      style={{marginLeft: 'auto', cursor: 'pointer'}}
                                      onClick={() => deleteTestCase(testCase.uuid)}
                                />
                            </>
                        ) : (
                            <span style={{marginRight: 'auto'}}>{testCase.name || 'Unnamed'}</span>
                        )}
                    </List.Item>
                ))}
            </List>
            {showCreateButton && (
                <>
                    <Button disabled={testingAll} color="teal" content="Create test case" fluid onClick={() => {
                        setEdit(undefined);
                        setModalOpen(true)
                    }}/>
                    {modalOpen && <CreateTestCaseModal node={node} edit={edit} onClose={() => {
                        setEdit(undefined);
                        setModalOpen(false)
                    }}/>}
                </>
            )}
        </>
    )
}