import {RuleParamsModal} from "../../../components/rule/RuleParamsModal";
import {Button, Label} from "semantic-ui-react";
import {RuleSettingsModal} from "../../../components/rule/RuleSettingsModal";
import {ShareRuleButton} from "./ShareRuleButton";
import {CommitChangesModal} from "../../../components/rule/CommitChangesModal";
import {RuleInterface} from "../../../interfaces/rule.interface";
import {RuleSharePermissionsEnum} from "../../../interfaces/rule-share-permissions.enum";
import {RuleStatusEnum} from "../../../interfaces/rule-status.enum";
import {PublishRuleModal} from "../../../components/rule/PublishRuleModal";

const sidebarStyles: any = {
    position: 'absolute',
    zIndex: 2,
    bottom: 'auto',
    maxHeight: 'calc(100% - 40px)',
    borderRadius: 20,
    width: 60,
    gap: 10,
    left: 20,
    top: 'calc(50% - 120px)',
    height: 'auto',
    border: 0,
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start'
}

export function RuleControls({debugModeEnabled, rule}: {
    debugModeEnabled: boolean;
    rule: RuleInterface;
}) {

    return (
        <div style={{
            ...sidebarStyles,
            pointerEvents: debugModeEnabled ? 'none' : undefined,
            opacity: debugModeEnabled ? 0.5 : undefined
        }}>
            {rule.status === RuleStatusEnum.DRAFT && (
                <>
                    <RuleParamsModal rule={rule}>
                        <Button title="Rule params" icon="list ul" size="huge" color="teal" circular
                                basic className="bg-white"/>
                    </RuleParamsModal>
                    <RuleSettingsModal rule={rule}>
                        <Button title="Rule settings" icon="cog" size="huge" color="teal" circular basic
                                className="bg-white"/>
                    </RuleSettingsModal>
                </>
            )}
            <ShareRuleButton rule={rule}/>
            {(!rule.permissions || rule.permissions?.includes(RuleSharePermissionsEnum.CAN_EDIT)) && (
                <>
                    {rule.status === RuleStatusEnum.DRAFT && (
                        <CommitChangesModal rule={rule} disabled={!rule.uncommittedChanges}>
                            <Button color="teal"
                                    size="huge"
                                    circular
                                    basic
                                    style={{position: 'relative'}}
                                    disabled={!rule.uncommittedChanges}
                                    title={`Commit ${rule.uncommittedChanges} changes`}
                                    icon="check"
                                    className="icon bg-white"
                                    content={<Label color='teal' circular floating style={{
                                        top: 'auto',
                                        bottom: '-0.75em'
                                    }}>{rule.uncommittedChanges}</Label>}
                            />
                        </CommitChangesModal>
                    )}
                    {rule.status === RuleStatusEnum.ACTIVE && (
                        <PublishRuleModal rule={rule}>
                            <Button color="teal"
                                    size="huge"
                                    circular
                                    basic
                                    title={`Publish v${rule.version} to the marketplace`}
                                    icon="shop"
                                    className="icon bg-white"
                            />
                        </PublishRuleModal>
                    )}
                </>
            )}
        </div>
    )
}