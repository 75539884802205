import {Button, Divider, Form, Header, Segment} from "semantic-ui-react";
import {removeNode, setActiveNodeUuid, updateNode} from "../../slices/flow.slice";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import {RuleNodeInterface} from "../../interfaces/rule-node.interface";
import {RuleInterface} from "../../interfaces/rule.interface";
import {RemoveNodeModal} from "../RemoveNodeModal";
import {useUpdateRuleMutation} from "../../apis/rules.api";
import {cloneDeep} from "lodash";
import {useDebounce} from "../../hooks/useDebounce";
import {useFindTriggersQuery} from "../../apis/triggers.api";
import {TriggerInterface} from "../../interfaces/trigger.interface";
import {BASE_URL} from "../../helpers/base-query";

export function TriggerParams({rule, readOnly}: { rule: RuleInterface, readOnly?: boolean }) {
    const dispatch = useAppDispatch();
    const activeNodeUuid = useAppSelector(state => state.flow.activeNodeUuid);
    const triggers = useAppSelector(state => state.editor.triggers);
    const nodes = useAppSelector(state => state.editor.nodes);
    const flow = useAppSelector(state => state.flow.flow);

    const [activeNode, setActiveNode] = useState<RuleNodeInterface>();
    const [trigger, setTrigger] = useState<TriggerInterface>();
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [description, setDescription] = useState<string | undefined>(activeNode?.description);
    const [nodeVersion, setNodeVersion] = useState<number | undefined>(activeNode?.nodeVersion);
    const debouncedDescription = useDebounce(description, 1000);
    const currentInstance = useAppSelector(state => state.debug.currentInstance);
    const [updateRule] = useUpdateRuleMutation();

    useEffect(() => {
        let activeNode = flow.find(node => node.uuid === activeNodeUuid);
        setActiveNode(activeNode);
        setDescription(activeNode?.description || '');
    }, [activeNodeUuid, flow]);

    useEffect(() => {
        setTrigger(triggers.find(trigger => trigger.uuid === activeNode?.nodeUuid && trigger.version === activeNode?.nodeVersion));
    }, [activeNode]);

    useEffect(() => {
        if (!activeNode || activeNode?.description === debouncedDescription) {
            return;
        }
        dispatch(updateNode({
            uuid: activeNode.uuid,
            data: {description: debouncedDescription}
        }));
        const newFlow = cloneDeep(flow);
        const node = newFlow.find((node) => node.uuid === activeNode.uuid);
        if (node) {
            node.description = debouncedDescription;
            updateRule({
                uuid: rule.uuid,
                version: rule.version,
                flow: newFlow
            });
        }
    }, [debouncedDescription]);


    // useEffect(() => {
    //     if (!activeNode) {
    //         return;
    //     }
    //     dispatch(updateNode({
    //         uuid: activeNode.uuid,
    //         data: {nodeVersion}
    //     }));
    //     const newFlow = cloneDeep(flow);
    //     const node = newFlow.find((node) => node.uuid === activeNode.uuid);
    //     if (node) {
    //         node.nodeVersion = nodeVersion;
    //         updateRule({
    //             uuid: rule.uuid,
    //             version: rule.version,
    //             flow: newFlow
    //         });
    //     }
    // }, [nodeVersion]);

    if (!activeNode) {
        return <></>;
    }

    function remove() {
        if (!activeNode) {
            return;
        }
        const newFlow = cloneDeep(flow).filter((node) => node.uuid !== activeNode.uuid);
        dispatch(removeNode(activeNode.uuid));
        setRemoveModalOpen(false);
        updateRule({
            uuid: rule.uuid,
            version: rule.version,
            flow: newFlow
        });
    }

    return (
        <Segment basic>
            {trigger && (
                <>
                    <Header style={{marginTop: '0.25em'}}>
                        {trigger.name}
                        <Button className="close-btn" icon="close" onClick={() => dispatch(setActiveNodeUuid(undefined))}/>
                    </Header>
                    <Divider/>
                    {/*<p>uuid: {activeNode.uuid}</p>*/}
                    <p>{trigger.event
                        .replace('{instanceUuid}', currentInstance?.uuid || '{instanceUuid}')
                        .replace(/\<(.*?)\>/g, (match: string, contents: string): any => {
                            return currentInstance?.triggerParams?.[contents] || match;
                        })
                    }</p>
                    {trigger.event === 'webhook:instance:{instanceUuid}' && (
                        <p>
                            <b>POST or GET:</b> {`${BASE_URL}/webhook/instance/${currentInstance?.uuid || '{instanceUuid}'}`}
                        </p>
                    )}
                    <Form>
                        {/*<Form.Select*/}
                        {/*    label="Trigger version"*/}
                        {/*    value={activeNode.nodeVersion}*/}
                        {/*    options={triggers?.data?.filter(node => node.uuid === activeNode?.nodeUuid).map((node, i) => ({*/}
                        {/*        key: `node-${i}`,*/}
                        {/*        value: node.version,*/}
                        {/*        text: `Version ${node.version}`*/}
                        {/*    })) || []}*/}
                        {/*    onChange={(e, {value}) => setNodeVersion(value as number)}*/}
                        {/*/>*/}
                        <Form.TextArea
                            label="Description (notes)"
                            value={description}
                            onChange={(e, {value}) => setDescription(value as string)}
                        />

                        {!readOnly && <div style={{marginTop: '2em'}}>
                            <Button color="red" basic content="Remove trigger" fluid
                                    onClick={() => setRemoveModalOpen(true)}/>
                            <RemoveNodeModal
                                open={removeModalOpen}
                                onYes={() => remove()}
                                onNo={() => setRemoveModalOpen(false)}/>
                        </div>}
                    </Form>
                </>
            )}
        </Segment>
    );
}