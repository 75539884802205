import {Button, Container, Dimmer, Divider, Grid, Input, Label, Loader, Message, Segment, Tab} from "semantic-ui-react";
import {compact, flatten, intersection, uniq, xor} from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import {RulesList} from "./rules/RulesList";
import {useFindRulesQuery} from "../../apis/rules.api";
import {useMemo, useState} from "react";
import {RuleStatusEnum} from "../../interfaces/rule-status.enum";
import {RuleSortEnum} from "../../interfaces/rule-sort.enum";
import {useAppDispatch, useAppSelector} from "../../store";
import {NavLink, useParams} from "react-router-dom";
import {setRulesViewMode} from "../../slices/settings.slice";
import {CreateRuleModal} from "../../components/rule/CreateRuleModal";

export function Rules() {
    const {organization, tag, section} = useParams<{
        organization: string;
        tag: string;
        section: 'drafts' | 'releases' | 'public';
    }>();
    const auth = useAppSelector((state) => state.auth);
    const org = useAppSelector(state => state.auth.org);
    const [createModalOpened, setCreateModalOpened] = useState(false);
    const [search, setSearch] = useState('');
    const [tagFilter, setTagFilter] = useState<string[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
    const viewMode = useAppSelector(state => state.settings.rulesViewMode);
    const dispatch = useAppDispatch();

    const ruleStatusSectionMap = {
        drafts: RuleStatusEnum.DRAFT,
        releases: RuleStatusEnum.ACTIVE,
        public: RuleStatusEnum.PUBLIC
    };

    const {data, isLoading, isFetching, isError, error, refetch: refetchRules} = useFindRulesQuery({
        limit: -1,
        createdBy: organization ? undefined : auth.accountId,
        orgId: org?.id,
        sort: RuleSortEnum.NEWEST,
        order: 'desc',
        status: section ? ruleStatusSectionMap[section] : undefined,
        groupByVersion: section === 'public' ? true : undefined
    });

    const rules = useMemo(() => {
        return data?.data
            .filter(rule => {
                switch (tag) {
                    case 'recent':
                        return true;
                    case 'org':
                        return rule.orgId === auth.orgId;
                    case 'shared':
                        return rule.orgId !== auth.orgId;
                    default:
                        return true;
                }
            })
            .filter(rule => !search.trim() || rule.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1)
            .filter(rule => {
                return (!categoryFilter.length || categoryFilter.includes(rule.category || '')) &&
                    (!tagFilter.length || intersection(tagFilter, rule.tags).length > 0);
            }) || []
    }, [categoryFilter, tagFilter, data, tag, search]);

    const categories = compact(uniq(data?.data.map(rule => rule.category)));
    const tags = compact(uniq(flatten(data?.data.map(rule => rule.tags))));

    return (
        <>
            {isFetching && <Dimmer active inverted style={{backgroundColor: 'transparent'}}>
                <Loader inverted content='Loading'/>
            </Dimmer>}
            <PerfectScrollbar>
                <Segment basic>
                    <Container style={{position: 'relative'}}>
                        <Grid>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                <h1>{organization ? `Rules` : 'My Rules'}</h1>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={8} textAlign="right">
                                <Button color="teal" circular icon="add" content={'Create new rule'}
                                        onClick={() => setCreateModalOpened(true)}/>
                                <Button circular basic color="teal" icon="refresh" onClick={refetchRules}
                                        loading={isFetching}/>
                                <Button
                                    basic
                                    circular
                                    color={'teal'}
                                    icon={viewMode === 'cards' ? 'table' : 'address card outline'}
                                    title={viewMode === 'cards' ? 'Table view' : 'Cards view'}
                                    onClick={() => {
                                        dispatch(setRulesViewMode(viewMode === 'cards' ? 'table' : 'cards'))
                                    }}
                                />
                            </Grid.Column>
                        </Grid>

                        <Divider hidden/>
                        {isError &&
                            <Message error content={(error as any).data?.error?.description || 'Unknown error'}/>}

                        <Tab
                            menu={{secondary: true, pointing: true}}
                            renderActiveOnly={false}
                            activeIndex={-1}
                            panes={[{
                                menuItem: {
                                    as: NavLink,
                                    content: 'Drafts',
                                    to: ['/org', organization, 'rules', 'drafts', tag].join('/'),
                                    end: true,
                                    key: 'drafts',
                                }
                            }, {
                                menuItem: {
                                    as: NavLink,
                                    content: 'Releases',
                                    to: ['/org', organization, 'rules', 'releases', tag].join('/'),
                                    key: 'releases'
                                }
                            }, {
                                menuItem: {
                                    as: NavLink,
                                    content: 'Published',
                                    to: ['/org', organization, 'rules', 'public', tag].join('/'),
                                    key: 'public'
                                }
                            }]}
                        />
                        <Divider hidden/>
                        <Grid columns={2}>
                            <Grid.Column>
                                <Tab menu={{secondary: true}}
                                     renderActiveOnly={false}
                                     activeIndex={-1}
                                     panes={[
                                         {
                                             menuItem: {
                                                 as: NavLink,
                                                 content: 'Recent',
                                                 to: ['/org', organization, 'rules', section, 'recent'].filter(i => !!i).join('/'),
                                                 key: 'recent',
                                             },
                                         }, {
                                             menuItem: {
                                                 as: NavLink,
                                                 content: 'Your organization',
                                                 to: ['/org', organization, 'rules', section, 'org'].filter(i => !!i).join('/'),
                                                 key: 'org',
                                             },
                                         }, {
                                             menuItem: {
                                                 as: NavLink,
                                                 content: 'Shared',
                                                 to: ['/org', organization, 'rules', section, 'shared'].filter(i => !!i).join('/'),
                                                 key: 'shared',
                                             }
                                         }
                                     ]}
                                />
                            </Grid.Column>
                            <Grid.Column textAlign={'right'}>
                                <Input
                                    icon="search" placeholder="Search..." value={search}
                                    onChange={(e, {value}) => setSearch(value)}
                                />
                            </Grid.Column>
                        </Grid>
                        <Divider hidden/>
                        {(categories.length > 0 || tags.length > 0) && (
                            <>
                                <div style={{paddingBottom: '0.5em'}}>
                                    {categories.map(category => (
                                        <Label
                                            basic={!categoryFilter.includes(category)}
                                            style={{borderWidth: 1, cursor: 'pointer'}}
                                            content={category}
                                            onClick={() => setCategoryFilter(xor(categoryFilter, [category]))}
                                        />
                                    ))}
                                </div>
                                <div>
                                    {tags.map(tag => (
                                        <Label
                                            basic={!tagFilter.includes(tag)}
                                            style={{borderWidth: 1, cursor: 'pointer'}}
                                            content={`#${tag}`}
                                            onClick={() => setTagFilter(xor(tagFilter, [tag]))}
                                        />
                                    ))}
                                </div>
                                <Divider hidden/>
                            </>
                        )}
                        {!isLoading && !isFetching && <RulesList view={viewMode} rules={rules}/>}
                        {!rules.length && !isLoading && !isFetching &&
                            <Message>No rules matching the filter found</Message>
                        }
                    </Container>
                    {createModalOpened && <CreateRuleModal open onClose={() => setCreateModalOpened(false)}/>}
                </Segment>
            </PerfectScrollbar>
        </>
    )
}