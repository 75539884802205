import {NavLink, Route, Routes, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {isArray, maxBy} from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Container, Dimmer, Divider, Loader, Segment, Tab} from "semantic-ui-react";
import {useFindRulesQuery, useGetRuleQuery} from "../../../apis/rules.api";
import {RuleStatusEnum} from "../../../interfaces/rule-status.enum";
import {RuleSortEnum} from "../../../interfaces/rule-sort.enum";
import {RuleInterface} from "../../../interfaces/rule.interface";
import {useAppDispatch, useAppSelector} from "../../../store";
import {setTitle} from "../../../slices/marketplace.slice";
import {OverviewTab} from "./OverviewTab";
import {VersionsTab} from "./VersionsTab";
import {InstancesTab} from "./InstancesTab";
import {Flow} from "../../../components/Flow";
import {DataLoader} from "../../rule-editor/DataLoader";
import {setPosition, setZoom} from "../../../slices/flow.slice";

export function RulePage() {
    const {ruleUuid} = useParams();
    const [latestVersion, setLatestVersion] = useState<RuleInterface>();
    const ready = useAppSelector(state => state.editor.ready);
    const dispatch = useAppDispatch();
    const {data: rule, isFetching: isRuleLoading} = useGetRuleQuery({
        ruleUuid: ruleUuid as string,
        ruleVersion: Number(latestVersion?.version),
        getDependencies: true,
        getPermissions: true
    }, {skip: !latestVersion || !ruleUuid});
    const {data: rules, isFetching: isRulesLoading} = useFindRulesQuery({
        uuid: ruleUuid,
        limit: -1,
        status: RuleStatusEnum.PUBLIC,
        sort: RuleSortEnum.NEWEST,
        order: 'desc',
        groupByVersion: false
    }, {skip: !ruleUuid});

    useEffect(() => {
        rule && dispatch(setTitle(rule.name));
        dispatch(setPosition());
        dispatch(setZoom());
    }, [rule]);

    useEffect(() => {
        if (rules?.data && isArray(rules?.data)) {
            setLatestVersion(maxBy(rules.data, 'version'));
        }
    }, [rules]);

    return (
        <PerfectScrollbar>
            <Segment basic style={{margin: 0}}>
                {(rule && rules?.data) && (
                    <Container>
                        <h1>{rule.name}</h1>
                        <Divider hidden/>
                        <Tab
                            renderActiveOnly={false}
                            activeIndex={-1}
                            menu={{secondary: true, pointing: true}}
                            panes={[
                                {
                                    menuItem: {
                                        as: NavLink,
                                        content: 'Overview',
                                        to: '',
                                        end: true,
                                        key: 'overview'
                                    },
                                    pane: (
                                        <Routes>
                                            <Route index element={<OverviewTab rule={rule}/>}/>
                                        </Routes>
                                    )
                                },
                                {
                                    menuItem: {
                                        as: NavLink,
                                        content: 'Algorithm',
                                        to: 'algorithm',
                                        key: 'algorithm'
                                    },
                                    pane: (
                                        <Routes>
                                            <Route path="algorithm" element={(
                                                <>
                                                    <DataLoader ruleUuid={ruleUuid} ruleVersion={Number(latestVersion?.version)}/>
                                                    <Segment style={{height: 'calc(100vh - 225px)'}}>
                                                        {!isRuleLoading && !isRulesLoading && rule && ready && (
                                                            <Flow rule={rule} readOnly/>
                                                        )}
                                                    </Segment>
                                                </>
                                            )}/>
                                        </Routes>
                                    )
                                },
                                {
                                    menuItem: {
                                        as: NavLink,
                                        content: 'Versions',
                                        to: 'versions',
                                        key: 'versions'
                                    },
                                    pane: (
                                        <Routes>
                                            <Route path="versions" element={<VersionsTab rules={rules.data}/>}/>
                                        </Routes>
                                    )
                                },
                                {
                                    menuItem: {
                                        as: NavLink,
                                        content: 'Instances',
                                        to: 'instances',
                                        key: 'instances'
                                    },
                                    pane: (
                                        <Routes>
                                            <Route path="instances" element={<InstancesTab rules={rules.data}/>}/>
                                            <Route path="instances/:version" element={<InstancesTab rules={rules.data}/>}/>
                                        </Routes>
                                    )
                                },
                            ]}
                        />
                    </Container>
                )}
            </Segment>
            <Dimmer active={isRuleLoading || isRulesLoading} inverted style={{backgroundColor: 'transparent'}}>
                <Loader inverted content='Loading'/>
            </Dimmer>
        </PerfectScrollbar>
    )
}