import {ParamInterface} from "../interfaces/param.interface";
import {Button, Form} from "semantic-ui-react";
import {stringifyValue} from "../helpers/common";
import {SetValueModal} from "./params/SetValueModal";
import {useState} from "react";
import {RuleInterface} from "../interfaces/rule.interface";
import {getVarsList} from "../helpers/vars";
import {useAppSelector} from "../store";
import {flatten, isArray, isBoolean, isObject, isString, isUndefined} from "lodash";
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import SyntaxHighlighter from "react-syntax-highlighter/dist/cjs/light";

SyntaxHighlighter.registerLanguage('json', json);

export function ParamsForm({
   rule,
   params,
   values,
   onChange,
   readOnly
}: {
    rule: RuleInterface;
    params: ParamInterface[];
    values: { [key: string]: any };
    onChange: (param: ParamInterface, value: any) => void;
    readOnly?: boolean;
}) {
    const nodes = useAppSelector(state => state.editor.nodes);
    const triggers = useAppSelector(state => state.editor.triggers);
    const flow = useAppSelector(state => state.flow.flow);
    const [activeParam, setActiveParam] = useState<ParamInterface | undefined>();

    function getValueTitle(param: ParamInterface) {
        const vars = flatten(Object.values(getVarsList(rule, param, flow, nodes, triggers)));
        let initialValue = values[param.name];
        let value = values[param.name];
        vars.forEach((item) => {
            if (isString(value)) {
                let regex = new RegExp(`(?:^|\<)${item.id.toString().replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')}(?:$|\>)`);
                value = value.replace(regex, `<span style="background-color: rgb(206, 228, 229); border-radius: 3px; outline: 2px solid rgb(206, 228, 229);">${item.fullTitle || item.title}</span>`);
            } else {
                value = stringifyValue(value);
            }
        });
        if (isString(initialValue)) {
            return <div style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}} dangerouslySetInnerHTML={{__html: value}}></div>;
        } else if (isObject(initialValue) || isArray(initialValue)) {
            return <SyntaxHighlighter language="json" style={{...docco}}>
                {value}
            </SyntaxHighlighter>
        } else if (isUndefined(initialValue)) {
            return <div>Not set</div>;
        } else if (isBoolean(initialValue)) {
            return <div>{initialValue ? 'true' : 'false'}</div>;
        } else {
            return <div>{stringifyValue(initialValue)}</div>;
        }
    }

    return (
        <Form style={{marginBottom: '1em'}}>
            {params.map((param, i) => (
                <Form.Field key={i}>
                    <h5 style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 5}}>
                        {param.title} ({param.type.toString()})
                        {!readOnly && (<div>
                            <Button basic icon="pencil" size="mini" onClick={() => setActiveParam(param)}/>
                            <Button basic icon="close" size="mini" onClick={() => onChange && onChange(param, undefined)}/>
                        </div>)}
                    </h5>
                    {param.description && (
                        <div style={{opacity: 0.5, marginBottom: 15}}>{param.description}</div>
                    )}
                    {getValueTitle(param) || 'Not set'}
                </Form.Field>
            ))}
            {activeParam && !readOnly &&
                <SetValueModal
                    rule={rule}
                    open={true}
                    param={activeParam}
                    value={values[activeParam.name]}
                    onChange={(param, value) => onChange && onChange(param, value)}
                    onClose={() => setActiveParam(undefined)}
                />}
        </Form>
    )
}