import {RuleNodeInterface} from "../interfaces/rule-node.interface";
import './FlowNode.scss';

export const FlowStickyNoteNode = ({data, isConnectable, type}: {
    data: {
        data: RuleNodeInterface
    };
    isConnectable: boolean;
    type: string;
}) => {
    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: '100%',
                width: '100%',
                padding: 10
            }}>
                <p style={{
                    width: '100%',
                    opacity: 0.5,
                    whiteSpace: 'pre-wrap'
                }}>
                    {data.data.description?.trim() || 'Sticky note...'}
                </p>
            </div>
        </>
    );
}