import React, {cloneElement, ReactElement, useState} from 'react';
import {Icon} from 'semantic-ui-react';

export function Arn({arn, secondary, trigger, text = true, style = {}}: {
    arn: string,
    secondary?: boolean,
    trigger?: ReactElement,
    text?: boolean,
    style?: any,
}) {
    const [icon, setIcon] = useState<any>('copy outline');

    function copy() {
        setIcon('check');
        navigator.clipboard.writeText(arn);
        setTimeout(() => setIcon('copy outline'), 2000);
    }

    return (
        trigger ? (
            cloneElement(trigger, {
                onClick: copy,
                title: arn
            })
        ) : (
            <span title={arn} style={{whiteSpace: 'nowrap', display: 'flex', opacity: secondary ? 0.5 : 1, ...style}}>
                {text && <span style={{textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100%'}}>{arn}</span>}
                <Icon style={{marginLeft: 5, cursor: 'pointer'}} name={icon} onClick={copy}/>
            </span>
        )
    );
}
