import {Button, Dimmer, Icon, Input, Item, Loader, Message, Pagination, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {useFindRulesQuery} from "../../../../apis/rules.api";
import {RuleStatusEnum} from "../../../../interfaces/rule-status.enum";
import {RuleSortEnum} from "../../../../interfaces/rule-sort.enum";
import {useDebounce} from "../../../../hooks/useDebounce";
import {useAppDispatch} from "../../../../store";
import {setTitle} from "../../../../slices/marketplace.slice";

export function RulesList() {
    const [page, setPage] = useState<number>(1);
    const [sort, setSort] = useState<RuleSortEnum>(RuleSortEnum.NEWEST);
    const [search, setSearch] = useState<string | undefined>(undefined);
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const debouncedSearch = useDebounce(search, 500);
    const dispatch = useAppDispatch();

    const {data: rules, isError, error, isFetching, refetch: refetchRules} = useFindRulesQuery({
        limit: 10,
        status: RuleStatusEnum.PUBLIC,
        name: debouncedSearch,
        sort,
        page,
        order,
        groupByVersion: true
    });

    useEffect(() => {
        refetchRules();
    }, [page, sort, debouncedSearch, order]);

    useEffect(() => {
        dispatch(setTitle('Marketplace'));
    }, []);

    return (
        <>
            <Input icon='search'
                   placeholder='Search...'
                   style={{marginRight: 30}}
                   value={search || ''}
                   onChange={(e, data) => {
                       setSearch(data.value || undefined);
                       setPage(1);
                   }}
            />
            <Button.Group basic style={{marginBottom: 15, marginTop: 15}} size={'mini'}>
                <Button content="Newest"
                        active={sort === RuleSortEnum.NEWEST}
                        icon={sort === RuleSortEnum.NEWEST ? (order === 'asc' ? "arrow up" : "arrow down") : false}
                        onClick={() => {
                            setPage(1);
                            if (sort === RuleSortEnum.NEWEST) {
                                setOrder(order === 'asc' ? 'desc' : 'asc')
                            } else {
                                setSort(RuleSortEnum.NEWEST)
                            }
                        }}
                />
                <Button content="Popular"
                        active={sort === RuleSortEnum.POPULAR}
                        icon={sort === RuleSortEnum.POPULAR ? (order === 'asc' ? "arrow up" : "arrow down") : false}
                        onClick={() => {
                            setPage(1);
                            if (sort === RuleSortEnum.POPULAR) {
                                setOrder(order === 'asc' ? 'desc' : 'asc')
                            } else {
                                setSort(RuleSortEnum.POPULAR)
                            }
                        }}
                />
            </Button.Group>

            {isError && <Message error content={(error as any).data?.error?.description || 'Unknown error'}/>}

            <Segment style={{marginBottom: 0}}>
                <Dimmer active={isFetching} inverted>
                    <Loader inverted/>
                </Dimmer>
                {!rules?.data.length && <div style={{textAlign: 'center', marginTop: 20}}>Nothing found</div>}
                <Item.Group divided style={{marginTop: 10}}>
                    {rules?.data.map((rule, i) => (
                        <Item key={`rule-${i}`} style={{padding: '20px 0'}}>
                            <Item.Content>
                                <Item.Header
                                    as={Link}
                                    to={`${rule.uuid}/${rule.version}`}
                                >{rule.name}</Item.Header>
                                <Item.Meta>{rule.createdBy ? `Account #${rule.createdBy}` : 'AffirmXH'}</Item.Meta>
                                <Item.Description>{rule.description}</Item.Description>
                                <Item.Extra>
                                    <Button primary floated='right' as={Link} to={`${rule.uuid}/${rule.version}`}
                                            content="View"/>
                                    <div style={{paddingTop: '1.5em'}}>
                                        <Icon name="clock outline"/>last
                                        update: {new Date(rule.updatedAt).toLocaleDateString()},
                                        version: {rule.version},
                                        installations: {rule.instanceCount}
                                    </div>
                                </Item.Extra>
                            </Item.Content>
                        </Item>
                    ))}
                </Item.Group>
            </Segment>
            {(rules?.pagination.total || 0) > (rules?.pagination.limit || 0) && <Pagination
                activePage={page}
                onPageChange={(e, data) => setPage(parseInt(data.activePage as any))}
                totalPages={Math.ceil((rules?.pagination.total || 1) / (rules?.pagination.limit || 1))}
                style={{marginTop: 30}}
            />}
        </>
    )
}