import {cloneElement, ReactElement, useEffect, useRef, useState} from "react";
import {cloneDeep, isArray, isObject, omit} from "lodash";
import {Button, Dimmer, Divider, Form, Grid, Icon, Loader, Message, Modal} from "semantic-ui-react";
import AceEditor from "react-ace";
import {v4} from "uuid";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/theme-github";
import {docco} from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter/dist/cjs/light";
import {useAppSelector} from "../../../store";
import {floatify, stringifyValue} from "../../../helpers/common";
import {NodeInterface} from "../../../interfaces/node.interface";
import {NodeTestCaseInterface, NodeTestCaseStatusEnum} from "../../../interfaces/node-test-case.interface";
import {TestNodeResultInterface} from "../../../interfaces/test-node-result.interface";
import {useTestNodeMutation, useUpdateNodeMutation} from "../../../apis/nodes.api";
import PerfectScrollbar from "react-perfect-scrollbar";

export function CreateTestCaseModal({children, node, edit, onClose}: {
    children?: ReactElement;
    node: NodeInterface;
    edit?: NodeTestCaseInterface;
    onClose?: () => void;
}) {
    const [testNode, {data, isLoading, isSuccess, isError, error}] = useTestNodeMutation();
    const [updateNode, updateResult] = useUpdateNodeMutation();
    const [isOpen, setIsOpen] = useState(!children);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [debug, setDebug] = useState<TestNodeResultInterface>();
    const assertEditor = useRef<AceEditor | null>(null);
    const paramsEditor = useRef<AceEditor | null>(null);
    const inputDataEditor = useRef<AceEditor | null>(null);
    const code = useAppSelector((state) => state.nodes.editor.code);

    const [formData, setFormData] = useState<NodeTestCaseInterface>({
        uuid: v4(),
        name: '',
        input: {},
        instance: {},
        flowNode: {},
        eventData: {},
        nodeParams: {},
        assertions: defaultAssertions,
        status: NodeTestCaseStatusEnum.NEW,
        testFailureMessage: ''
    });

    useEffect(() => {
        if (edit) {
            setFormData(edit);
            setTimeout(() => paramsEditor.current?.editor.setValue(JSON.stringify(edit.nodeParams, null, 2), -1), 10);
            setTimeout(() => inputDataEditor.current?.editor.setValue(isObject(edit.input) || isArray(edit.input) ? stringifyValue(edit.input) : edit.input.toString(), -1), 10);
        } else {
            let nodeParams = node.params.reduce((acc: any, node) => {
                acc[node.name] = "";
                return acc;
            }, {});
            setFormData({
                ...formData,
                nodeParams
            });
            setTimeout(() => paramsEditor.current?.editor.setValue(JSON.stringify(nodeParams, null, 2), -1), 10);
            setTimeout(() => inputDataEditor.current?.editor.setValue(JSON.stringify(formData.input, null, 2), -1), 10);
        }
        setTimeout(() => assertEditor.current?.editor.setValue(edit?.assertions || defaultAssertions, -1), 10);
    }, [edit, assertEditor]);

    useEffect(() => {
        if (isSuccess && data) {
            setDebug(data);
            if (data.assertResult) {
                setFormData({
                    ...formData,
                    status: data.assertResult.success ? NodeTestCaseStatusEnum.SUCCESS : NodeTestCaseStatusEnum.FAILED
                });
            }
        }
    }, [isSuccess]);

    useEffect(() => {
        if (updateResult.isSuccess) {
            setIsOpen(false);
            onClose?.call(null);
        }
    }, [updateResult.isSuccess]);

    function test() {
        testNode({
            uuid: node.uuid,
            version: node.version,
            inputData: formData.input,
            nodeParams: formData.nodeParams,
            assertions: formData.assertions,
            code
        });
    }

    function save() {
        let testCases = cloneDeep(node.testCases || []);
        if (edit) {
            let testCase = testCases.find(item => item.uuid === edit.uuid);
            Object.assign(testCase, formData);
        } else {
            testCases.push(formData);
        }

        updateNode({
            uuid: node.uuid,
            version: node.version,
            testCases
        });
    }

    return (
        <>
            {children && cloneElement(children, {
                onClick: () => setIsOpen(true)
            })}
            <Modal size="fullscreen" closeIcon onClose={() => {
                setIsOpen(false);
                onClose?.call(null);
            }} open={isOpen} style={{marginBottom: 50}}>
                <Dimmer inverted active={isLoading || updateResult.isLoading}><Loader inverted/></Dimmer>
                <Modal.Header>Create test case</Modal.Header>
                <Modal.Content style={{height: 500}}>
                    <Grid columns={3} divided>
                        <Grid.Column>
                            <PerfectScrollbar style={{height: 460}}>
                                <Form>
                                    <Form.Input
                                        label={'Test name'}
                                        value={formData.name} fluid
                                        onChange={(e, {value}) => setFormData({...formData, name: value})}
                                    />
                                    <Divider hidden/>
                                    <Form.Field>
                                        <label>Node params</label>
                                        <div style={{height: 200}}>
                                            <AceEditor
                                                ref={paramsEditor}
                                                mode="json"
                                                defaultValue={JSON.stringify(formData.nodeParams, null, 2)}
                                                theme="github"
                                                width="100%"
                                                height="100%"
                                                fontSize={14}
                                                setOptions={{useWorker: false}}
                                                onChange={(value) => {
                                                    try {
                                                        setFormData({
                                                            ...formData,
                                                            nodeParams: JSON.parse(value)
                                                        });
                                                    } catch (e: any) {}
                                                }}
                                            />
                                        </div>
                                    </Form.Field>
                                    <Divider hidden/>
                                    <Form.Field>
                                        <label>Input data</label>
                                        <div style={{height: 200}}>
                                            <AceEditor
                                                ref={inputDataEditor}
                                                mode="json"
                                                defaultValue={isObject(formData.input) || isArray(formData.input) ? stringifyValue(formData.input) : formData.input.toString()}
                                                theme="github"
                                                width="100%"
                                                height="100%"
                                                fontSize={14}
                                                setOptions={{useWorker: false}}
                                                onChange={(value) => {
                                                    try {
                                                        setFormData({
                                                            ...formData,
                                                            input: JSON.parse(value)
                                                        });
                                                    } catch (e: any) {
                                                        setFormData({
                                                            ...formData,
                                                            input: floatify(value)
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Form.Field>
                                </Form>
                            </PerfectScrollbar>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Field>
                                    <label>Assertions</label>
                                    <AceEditor
                                        ref={assertEditor}
                                        mode={'typescript'}
                                        theme="github"
                                        width="100%"
                                        height="100%"
                                        setOptions={{useWorker: false}}
                                        onChange={(assertions) => {
                                            setFormData({...formData, assertions});
                                        }}
                                        fontSize={14}
                                        style={{
                                            height: 440
                                        }}
                                    />
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <PerfectScrollbar style={{height: 460}}>
                                <h5>Status</h5>
                                {formData.status === NodeTestCaseStatusEnum.SUCCESS && (
                                    <span><Icon name="check circle" color="teal" size="large"/>Passed</span>
                                )}
                                {formData.status === NodeTestCaseStatusEnum.FAILED && (
                                    <span><Icon name="minus circle" color="red" size="large"/>
                                        Failed ({formData.testFailureMessage})
                                    </span>
                                )}
                                {(!formData.status || formData.status === NodeTestCaseStatusEnum.NEW) && (
                                    <span><Icon name="circle" color="grey" size="large"/>New</span>
                                )}
                                <h5>Node result</h5>
                                <PerfectScrollbar style={{height: 195}}>
                                    <SyntaxHighlighter language="json" style={{...docco}}>
                                        {debug ? (JSON.stringify(omit(debug, 'assertResult'), null, 2) || 'None') : 'None'}
                                    </SyntaxHighlighter>
                                </PerfectScrollbar>
                                <h5>Test result</h5>
                                <SyntaxHighlighter language="json" style={{...docco}}>
                                    {JSON.stringify(debug?.assertResult, null, 2) || 'None'}
                                </SyntaxHighlighter>
                            </PerfectScrollbar>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    {isError &&
                        <Message error style={{textAlign: 'left'}} content={(error as any)?.data?.error?.description || 'Unknown error'}/>}
                    {updateResult.isError &&
                        <Message error style={{textAlign: 'left'}} content={(updateResult.error as any)?.data?.error?.description || 'Unknown error'}/>}
                    <Button content="Run test" circular onClick={() => test()}/>
                    <Button icon={'check'} color={'teal'} circular content="Save" onClick={() => save()}/>
                </Modal.Actions>
            </Modal>
        </>
    );
}

const defaultAssertions = `
// Add assertions in code. 
// Some examples are below:
// 
// assert.equal(nodeParams.x, 1);
// assert.equal(input.y, 2);
// assert.equal(result, 3);
// 
// Docs: https://www.chaijs.com/api/assert/
// 
// [ADD OTHER EXAMPLES]
`.trim();