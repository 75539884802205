import {Navigate, useLocation} from "react-router-dom";
import {useAppSelector} from "../store";
import {ReactElement} from "react";

export function RequireAuth({ children }: { children: ReactElement }) {
    const auth = useAppSelector((state) => state.auth);
    let location = useLocation();

    if (auth.loggedIn && !auth.roles?.includes('cycles-developer')) {
        return <Navigate to="/forbidden" replace />;
    }

    if (!auth.loggedIn || !auth.org ) {
        return <Navigate to="/auth" state={{ from: location }} replace />;
    }

    return children;
}