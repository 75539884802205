import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {NodeInterface} from "../interfaces/node.interface";

export interface NodesStateInterface {
    list: NodeInterface[];
    editor: {
        code: string;
        debugData?: {
            inputData: any;
            nodeParams: {[key: string]: any};
            result: any;
            logs: any[];
        }
    }
}

const initialState: NodesStateInterface = {
    list: [],
    editor: {
        code: '',
        debugData: undefined
    }
}

export const nodesSlice = createSlice({
    name: 'nodes',
    initialState,
    reducers: {
        setNodes: (draft, action: PayloadAction<NodeInterface[]>) => {
            draft.list = action.payload;
        },
        setCode: (draft, action: PayloadAction<string>) => {
            draft.editor.code = action.payload;
        },
        setDebugData: (draft, action: PayloadAction<{
            inputData: any;
            nodeParams: {[key: string]: any};
            result: any;
            logs: any[];
        } | undefined>) => {
            draft.editor.debugData = action.payload;
        },
        resetEditor: (draft) => {
            draft.editor.code = '';
            draft.editor.debugData = undefined;
        }
    },
});

export const {setNodes, setCode, setDebugData, resetEditor} = nodesSlice.actions;

export const nodesReducer = nodesSlice.reducer;