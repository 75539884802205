import {Header} from "./Header";
import {Segment} from "semantic-ui-react";
import {ReactElement} from "react";

export function Layout({headerContent, leftSidebarContent, rightSidebarContent, mainContent, rightSidebarClassName, muted, noBorder, noPadding}: {
    headerContent?: ReactElement;
    leftSidebarContent?: ReactElement;
    rightSidebarContent?: ReactElement;
    mainContent?: ReactElement;
    rightSidebarClassName?: string;
    muted?: boolean;
    noBorder?: boolean;
    noPadding?: boolean;
}) {

    return (
        <>
            {headerContent ? headerContent : <Header muted={muted} />}
            <Segment.Group horizontal className="layout" style={{borderRadius: 0}}>
                {leftSidebarContent && (
                    <Segment style={{padding: 0, border: noBorder ? 0 : undefined}} className={`sidebar-left ${muted ? 'muted' : ''}`} basic>
                        {leftSidebarContent}
                    </Segment>
                )}
                <Segment style={{border: noBorder ? 0 : undefined, padding: noPadding ? 0 : undefined}}>
                    {mainContent || <></>}
                </Segment>
                {rightSidebarContent && (
                    <Segment style={{padding: 0, border: noBorder ? 0 : undefined}} className={`sidebar-right ${muted ? 'muted' : ''} ${rightSidebarClassName || ''}`}>
                        {rightSidebarContent}
                    </Segment>
                )}
            </Segment.Group>
        </>
    );
}