import {Form} from "semantic-ui-react";
import {floatify} from "../../helpers/common";
import {RuleInterface} from "../../interfaces/rule.interface";
import {ParamInterface} from "../../interfaces/param.interface";
import {useEffect, useState} from "react";
import {extractTriggerParams} from "../../helpers/params";
import {useAppSelector} from "../../store";

export function FillTriggerParamsForm({rule, value, onChange}: {rule: RuleInterface, value?: object, onChange: (formData: object) => void}) {
    const triggers = useAppSelector(state => state.editor.triggers);
    const [formData, setFormData] = useState<any>(value || {});
    const triggerParams: ParamInterface[] = triggers ? extractTriggerParams(rule, triggers) : [];

    useEffect(() => {
        onChange && onChange(formData);
    }, [formData]);

    return (
        <Form>
            {triggerParams.map((param) => (
                <Form.Input
                    label={`${param.title} (${param.type})`}
                    value={formData[param.name]?.toString()}
                    onChange={(_e, {value}) => {
                        setFormData({
                            ...formData,
                            [param.name]: floatify(value) || ''
                        })
                    }}
                />
            ))}
        </Form>
    );
}