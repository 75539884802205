import {Button, Modal} from "semantic-ui-react";

export function RemoveNodeModal({open, isEdge, onYes, onNo}: {
    open: boolean;
    isEdge?: boolean;
    onYes: () => void;
    onNo: () => void;
}) {

    return (
        <Modal size="mini" closeIcon open={open} closeOnDimmerClick closeOnEscape onClose={() => onNo()}>
            <Modal.Header>Remove {isEdge ? 'edge' : 'node'}</Modal.Header>
            <Modal.Content>Do you really want to remove this {isEdge ? 'edge' : 'node'}?</Modal.Content>
            <Modal.Actions>
                <Button content="Yes" color="red" icon="check" onClick={() => onYes()} />
                <Button content="No" icon="close" onClick={() => onNo()} />
            </Modal.Actions>
        </Modal>
    );
}