import {Button} from "semantic-ui-react";
import {Link, useParams} from "react-router-dom";
import {UserMenu} from "../../components/UserMenu";
import {StartDebugNodeModal} from "../../components/debug/StartDebugNodeModal";
import {useGetNodeQuery} from "../../apis/nodes.api";
import {CommitChangesModal} from "../../components/node/CommitChangesModal";
import {NodeStatusEnum} from "../../interfaces/node-status.enum";
import {PublishNodeModal} from "../../components/node/PublishNodeModal";
import {Logo} from "../../components/Logo";

export function Header({muted}: {muted?: boolean}) {
    const {nodeUuid, nodeVersion, triggerUuid} = useParams();
    const {data: activeNode} = useGetNodeQuery({nodeUuid, nodeVersion: parseInt(nodeVersion || '')} as any, {skip: !nodeUuid || !nodeVersion});

    return (
        <div className={`app-header ${muted ? 'muted' : ''}`}>
            <Logo to={'..'}/>
            {activeNode && (
                <>
                    <h3 style={{flex: '1 1 auto', margin: 0}}>{activeNode.name}</h3>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <StartDebugNodeModal node={activeNode}>
                            <Button icon="play" content="Debug"/>
                        </StartDebugNodeModal>
                        {activeNode.status === NodeStatusEnum.DRAFT && (
                            <CommitChangesModal node={activeNode} disabled={!activeNode.uncommittedChanges}>
                                <Button icon="check"
                                        disabled={!activeNode.uncommittedChanges}
                                        content={`Release (${activeNode.uncommittedChanges})`}
                                        title={`You have ${activeNode.uncommittedChanges} uncommitted changes`}
                                />
                            </CommitChangesModal>
                        )}
                        {activeNode.status === NodeStatusEnum.ACTIVE && (
                            <PublishNodeModal node={activeNode}>
                                <Button icon="check" content={`Publish v${activeNode.version}`} />
                            </PublishNodeModal>
                        )}
                    </div>
                </>
            )}
            <div style={{display: 'flex', gap: '1em', alignItems: 'center', justifyContent: 'end', width: 300}}>
                <UserMenu />
            </div>
        </div>
    );
}