import {Button, Dimmer, Loader, Modal} from "semantic-ui-react";

export function ConfirmDeleteModal({title, text, open, isLoading, onYes, onNo}: {
    title?: string;
    text?: string;
    open: boolean;
    isLoading?: boolean;
    onYes: () => void;
    onNo: () => void;
}) {

    return (
        <Modal size="mini" closeIcon open={open} closeOnDimmerClick closeOnEscape onClose={() => onNo()}>
            <Dimmer active={isLoading} inverted><Loader /></Dimmer>
            <Modal.Header>{title || 'Delete'}</Modal.Header>
            <Modal.Content>{text || 'Do you want to delete this item?'}</Modal.Content>
            <Modal.Actions>
                <Button content="Yes" color="red" icon="check" onClick={() => onYes()} />
                <Button content="No" icon="close" onClick={() => onNo()} />
            </Modal.Actions>
        </Modal>
    );
}