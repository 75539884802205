import {Button, Dimmer, Form, Loader, Message, Modal} from "semantic-ui-react";
import {ReactElement, useEffect, useState} from "react";
import {NodeInterface} from "../../interfaces/node.interface";
import {useCommitNodeMutation} from "../../apis/nodes.api";
import {NodeTestCases} from "./test-cases/NodeTestCases";

export function CommitChangesModal({node, children, disabled}: {
    node: NodeInterface;
    children: ReactElement;
    disabled?: boolean;
}) {
    const [commitNode, {isLoading, isSuccess, isError, error, reset}] = useCommitNodeMutation();
    const [isOpen, setIsOpen] = useState(false);
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState<'waiting' | 'success' | 'failed'>(node.testCases?.length ? 'waiting' : 'success');

    useEffect(() => {
        setDescription('');
        reset();
    }, [isOpen])

    useEffect(() => {
        if (isSuccess) {
            setIsOpen(false);
        }
    }, [isSuccess]);

    return (
        <>
            <span onClick={() => !disabled && setIsOpen(true)}>{children}</span>
            <Modal closeIcon open={isOpen} onClose={() => setIsOpen(false)} size="mini">
                <Dimmer active={isLoading} inverted><Loader /></Dimmer>
                <Modal.Header>Commit changes</Modal.Header>
                <Modal.Content>
                    {node.testCases && node.testCases.length > 0 && (
                        <NodeTestCases
                            node={node}
                            allowEditTestCases={false}
                            showCreateButton={false}
                            showTestAllButton={false}
                            runTestCasesOnMounted={true}
                            onPassed={() => setStatus('success')}
                            onFailed={() => setStatus('failed')}
                        />
                    )}
                    <Form style={{
                        pointerEvents: status !== 'success' ? 'none' : 'initial',
                        opacity: status !== 'success' ? 0.5 : 1
                    }}>
                        <Form.TextArea
                            label="Changes made"
                            value={description}
                            error={(error as any)?.data?.errors?.commitMessage?.[0]}
                            onChange={(_e, {value}) => setDescription(value?.toString() || '')}
                        />
                        <p>
                            A new version of the node will be created
                        </p>
                    </Form>
                    {isError && !(error as any)?.data?.errors && <Message error content={(error as any)?.data?.error?.description || 'Unknown error'} />}
                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={status !== 'success'} primary content="Create" icon="check" onClick={() => {
                        commitNode({
                            nodeUuid: node.uuid,
                            commitMessage: description,
                        })
                    }} />
                </Modal.Actions>
            </Modal>
        </>
    );
}