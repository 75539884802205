import {isArray, isObject} from "lodash";
import {stringifyValue} from "../helpers/common";
import AceEditor from 'react-ace';
import "ace-builds/src-noconflict/worker-json.js";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";

export function JsonEditor({value, onChange, onError, mode, placeholder, style= {}}: {
    value: any;
    onChange?: (value: object) => void;
    onError?: (error: string) => void;
    mode?: string;
    placeholder?: string;
    style?: any;
}) {

    return (
        <AceEditor
            mode={mode || 'json'}
            defaultValue={isObject(value) || isArray(value) ? stringifyValue(value) : ''}
            theme="github"
            width="100%"
            height="100%"
            placeholder={placeholder}
            setOptions={{ useWorker: false }}
            onChange={(value) => {
                try {
                    onChange?.call(null, JSON.parse(value));
                } catch (e: any) {
                    onError?.call(null, 'Invalid JSON');
                }
            }}
            style={style}
        />
    );
}