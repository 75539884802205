import {Button, List, Modal} from "semantic-ui-react";
import {AddParam} from "../../components/rule/AddParam";
import {NodeInterface} from "../../interfaces/node.interface";
import {useState} from "react";
import {useUpdateNodeMutation} from "../../apis/nodes.api";

export function NodeParams({node}: {node: NodeInterface}) {
    const [deleteParam, setDeleteParam] = useState<number>();
    const [updateNode, {isLoading, isSuccess, isError, error}] = useUpdateNodeMutation();

    return (
        <>
            <p style={{opacity: 0.5}}>
                Node params will be filled on adding node to the rule flow
            </p>
            <List divided>
                {node.params.map((param, i) => (
                    <List.Item key={i} style={{
                        padding: '10px 0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <List.Content title={param.description}>
                            {param.title}
                        </List.Content>
                        <List.Content style={{marginLeft: 'auto', whiteSpace: 'pre'}}>
                            <AddParam
                                params={node.params}
                                editIndex={i}
                                isLoading={isLoading}
                                isError={isError}
                                isSuccess={isSuccess}
                                error={error}
                                requiredField={true}
                                onSubmit={(param, params) => {
                                    updateNode({
                                        uuid: node.uuid,
                                        version: node.version,
                                        params
                                    });
                                }}
                            >
                                <Button basic icon="pencil" size="mini"/>
                            </AddParam>
                            <Button basic icon="trash" size="mini" onClick={() => setDeleteParam(i)}/>
                        </List.Content>
                    </List.Item>
                ))}
            </List>

            <AddParam
                params={node.params}
                editIndex={node.params.length}
                isLoading={isLoading}
                isError={isError}
                isSuccess={isSuccess}
                error={error}
                requiredField={true}
                onSubmit={(param, params) => {
                    updateNode({
                        uuid: node.uuid,
                        version: node.version,
                        params
                    });
                }}
            >
                <Button color="teal" content="Add param" fluid/>
            </AddParam>

            <Modal open={deleteParam !== undefined} closeIcon size="mini" onClose={() => setDeleteParam(undefined)}>
                <Modal.Header>Delete rule param?</Modal.Header>
                <Modal.Content>
                    Delete node param &laquo;<b>{node.params[deleteParam || 0]?.title}</b>&raquo;?
                </Modal.Content>
                <Modal.Actions>
                    <Button color="red" content="Delete" onClick={() => {
                        updateNode({
                            uuid: node.uuid,
                            version: node.version,
                            params: node.params.filter((param, k) => deleteParam !== k)
                        });
                        setDeleteParam(undefined);
                    }} />
                </Modal.Actions>
            </Modal>
        </>
    );
}