import {Button, Popup} from "semantic-ui-react";
import {ReactElement} from "react";

export function Hint({children, trigger, content, floated, position}: {
    children?: ReactElement;
    trigger?: ReactElement;
    content?: string;
    floated?: 'left' | 'right';
    position?:
        | 'top left'
        | 'top right'
        | 'bottom right'
        | 'bottom left'
        | 'right center'
        | 'left center'
        | 'top center'
        | 'bottom center';
}) {

    return (
        <Popup
            content={content}
            on="click"
            hideOnScroll
            position={position || 'bottom center'}
            trigger={trigger ?? <Button basic secondary icon="help" circular size="mini" floated={floated} style={{fontSize: 10, padding: 6, margin: 0}}/>}
        >{children}</Popup>
    )
}