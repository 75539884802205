import {Dimmer, Grid, Loader, Modal} from "semantic-ui-react";
import {FlowDebug} from "../FlowDebug";
import {useEffect} from "react";
import {setActiveNodeUuid, setFlow, setPosition, setZoom} from "../../slices/flow.slice";
import {useAppDispatch, useAppSelector} from "../../store";
import {useGetRuleQuery} from "../../apis/rules.api";
import {useGetDebugDataQuery} from "../../apis/instances.api";
import {setDebugData, startDebug, stopDebug} from "../../slices/debug.slice";
import {Debug} from "./Debug";
import {DataLoader} from "../../screens/rule-editor/DataLoader";

export function ViewDebugModal({ruleUuid, ruleVersion, debugUuid, onClose}: {
    ruleUuid: string;
    ruleVersion: number;
    debugUuid: string;
    onClose: () => void;
}) {
    const dispatch = useAppDispatch();
    const dataIsReady = useAppSelector(state => state.editor.ready);
    const {data: rule, isFetching: isRuleLoading} = useGetRuleQuery({
        ruleUuid,
        ruleVersion
    });
    const debugDataQuery = useGetDebugDataQuery(debugUuid);

    useEffect(() => {
        dispatch(stopDebug());
        return () => {
            dispatch(stopDebug());
        }
    }, []);

    useEffect(() => {
        if (debugDataQuery.isSuccess && debugDataQuery.data) {
            dispatch(startDebug());
            dispatch(setDebugData([debugDataQuery.data]));
        }
    }, [debugDataQuery.isSuccess, debugDataQuery.data]);


    useEffect(() => {
        dispatch(setZoom(undefined));
        dispatch(setPosition(undefined));
        dispatch(setActiveNodeUuid(undefined));
        dispatch(setFlow(rule?.flow))
    }, []);

    return (
        <>
            <Modal size="fullscreen" open={true} closeIcon closeOnDimmerClick closeOnEscape onClose={() => onClose()}>
                <DataLoader ruleUuid={ruleUuid} ruleVersion={Number(ruleVersion)}/>
                <Dimmer active={!dataIsReady || isRuleLoading || debugDataQuery.isLoading} inverted><Loader/></Dimmer>
                <Modal.Header>Debug</Modal.Header>
                <Grid columns={2} style={{height: '80vh'}}>
                    <Grid.Row style={{height: '100%'}}>
                        <Grid.Column width={12} style={{borderRight: '1px solid rgba(34,36,38,.15)'}}>
                            {dataIsReady && rule && <FlowDebug rule={rule}/>}
                        </Grid.Column>
                        <Grid.Column width={4} style={{paddingLeft: 0, height: '100%'}}>
                            <Debug showHeader={false}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal>
        </>
    );
}