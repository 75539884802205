import {RuleInterface} from "../interfaces/rule.interface";
import {ParamInterface} from "../interfaces/param.interface";
import {uniqBy} from "lodash";
import {ParamTypeEnum} from "../interfaces/param-type.enum";
import {RuleNodeInterface} from "../interfaces/rule-node.interface";
import {RuleEdgeInterface} from "../interfaces/rule-edge.interface";
import {NodeInterface} from "../interfaces/node.interface";
import {extractTriggerParams} from "./params";
import {TriggerInterface} from "../interfaces/trigger.interface";

interface VarInterface {
    id: string | number;
    title: string;
    fullTitle?: string;
    outputType?: ParamTypeEnum;
    outputOf?: ParamTypeEnum;
}

export function getVarsList(rule: RuleInterface, param: ParamInterface, flow: (RuleNodeInterface | RuleEdgeInterface)[], nodes: NodeInterface[], triggers: TriggerInterface[]): {
    global: VarInterface[];
    enum: VarInterface[];
    ruleParams: VarInterface[];
    triggerParams: VarInterface[];
    variables: VarInterface[];
    outputs: VarInterface[];
} {
    const setVariableNode = nodes
        .filter(node => node.name === 'Set variable' || node.name === 'Init variable')
        .map(node => node.uuid);

    return {
        global: [
            {id: '$event', title: 'Event data', fullTitle: ''},
            {id: '$input', title: 'Input data', fullTitle: ''},
            {id: '$outputs', title: 'Outputs data', fullTitle: ''},
            {id: '$currentDateTime', title: 'Current datetime', fullTitle: ''}
        ],
        enum: param.enum?.map((item) => ({
            id: item.value,
            title: item.title
        })) || [],
        ruleParams: rule.params.map(param => ({
            id: `$rule.params.${param.name}`,
            title: `${param.title}`,
            fullTitle: `Rule param: ${param.title}`
        })),
        triggerParams: extractTriggerParams(rule, triggers).map(param => ({
            id: `$trigger.params.${param.name}`,
            title: param.title,
        })),
        variables: uniqBy(flow
            .filter((ruleNode: any) => setVariableNode.indexOf(ruleNode.nodeUuid) !== -1 && ruleNode.nodeParams?.variableName)
            .map((ruleNode: any) => ({
                id: `$vars.${ruleNode.nodeParams?.variableName}`,
                title: `${ruleNode.nodeParams?.variableName}`,
                fullTitle: `Variable: ${ruleNode.nodeParams?.variableName}`
            })), item => item.id),
        outputs: flow
            .filter(item => item.type === 'node' || item.type === 'input')
            .map((item: any) => {
                const node = nodes.find(node => node.uuid === item.nodeUuid);
                if (!node) {
                    return null;
                }
                const paramTypes = param.type.toString().split(',');
                // const typeMatch = paramTypes.indexOf(node.outputType.toString()) !== -1 ||
                //     paramTypes.indexOf('any') !== -1 ||
                //     node?.outputType === 'any'
                const typeMatch = true; // need all outputs for Custom text
                return typeMatch ? {
                    id: `$outputs[${item.uuid}]`,
                    title: `${node?.name || 'Unknown node'}`,
                    fullTitle: `Output of ${node?.name || 'Unknown node'}`,
                    outputType: node?.outputType,
                    outputOf: node?.outputOf,
                } : null;
            })
            .filter(i => i !== null) as any[]
    };
}