import {Button, Dimmer, Loader, Message, Modal} from "semantic-ui-react";
import {usePublishNodeMutation} from "../../apis/nodes.api";
import {ReactElement, useEffect, useState} from "react";
import {NodeInterface} from "../../interfaces/node.interface";

export function PublishNodeModal({node, children, disabled}: {node: NodeInterface; children: ReactElement, disabled?: boolean}) {

    const [publishNode, {isLoading, isSuccess, isError, error}] = usePublishNodeMutation();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            setIsOpen(false);
        }
    }, [isSuccess]);

    return (
        <>
            <span onClick={() => !disabled && setIsOpen(true)}>{children}</span>
            <Modal closeIcon open={isOpen} onClose={() => setIsOpen(false)} size="mini">
                <Dimmer active={isLoading} inverted><Loader /></Dimmer>
                <Modal.Header>Publish release</Modal.Header>
                <Modal.Content>
                    {isError && !(error as any)?.data?.errors && <Message error content={(error as any)?.data?.error?.description || 'Unknown error'} />}
                    <p>Confirm publishing release <b>v{node.version}</b></p>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Cancel" onClick={() => setIsOpen(false)} />
                    <Button primary content="Publish" icon="check" onClick={() => {
                        publishNode({
                            nodeUuid: node.uuid,
                            nodeVersion: node.version,
                        })
                    }} />
                </Modal.Actions>
            </Modal>
        </>
    );
}