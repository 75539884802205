import ReactFlow, {
    MiniMap,
    Controls,
    addEdge,
    ReactFlowProvider,
    Node,
    Edge,
    ReactFlowInstance, useNodesState, useEdgesState, MarkerType, Background
} from 'reactflow';
import {FlowNode} from "./FlowNode";
import {useCallback, useEffect, useRef, useState, MouseEvent} from "react";
import {setActiveNodeUuid, setFlow, setPosition, setZoom} from "../slices/flow.slice";
import {v4} from 'uuid';
import {useAppDispatch, useAppSelector} from "../store";
import {setDebugNodeUuid} from "../slices/debug.slice";
import {flatten, isBoolean, isObject, keyBy, max, orderBy, size} from "lodash";
import {RuleInterface} from "../interfaces/rule.interface";
import {RuleNodeInterface, RuleNodeTypeEnum} from "../interfaces/rule-node.interface";
import {RuleEdgeInterface} from "../interfaces/rule-edge.interface";
import {useUpdateRuleMutation} from "../apis/rules.api";
import {FlowTriggerNode} from "./FlowTriggerNode";
import {FlowScratchpadNode} from "./FlowScratchpadNode";
import {FlowInstanceInfoNode} from "./FlowInstanceInfoNode";
import {FlowStickyNoteNode} from "./FlowStickyNoteNode";
import './Flow.scss';
import {NodeTypeEnum} from "../interfaces/node-type.enum";

const nodeTypes = {
    trigger: FlowTriggerNode,
    input: FlowNode,
    default: FlowNode,
    scratchpad: FlowScratchpadNode,
    instanceInfo: FlowInstanceInfoNode,
    stickyNote: FlowStickyNoteNode
};

export function FlowDebug({rule}: {rule: RuleInterface}) {
    const flow = useAppSelector((state) => state.flow.flow);
    const flowZoom = useAppSelector((state) => state.flow.zoom);
    const flowPosition = useAppSelector((state) => state.flow.position);
    const activeNodeUuid = useAppSelector((state) => state.flow.activeNodeUuid);
    const originalNodes = useAppSelector(state => keyBy(state.editor.nodes, item => `${item.uuid}-${item.version}`));
    const logs = useAppSelector((state) => state.debug.logs);
    const debugData = useAppSelector((state) => state.debug.debugData);
    const debugModeEnabled = useAppSelector((state) => state.debug.debugModeEnabled);
    const debugNodeUuid = useAppSelector((state) => state.debug.debugNodeUuid);
    const selectedIteration = useAppSelector((state) => state.debug.selectedIteration);
    const iteration = debugModeEnabled ? (debugData[selectedIteration] || {}) : {};
    const dispatch = useAppDispatch();
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    useEffect(() => {
        if (!flow) {
            return;
        }
        let lastUuid: string;
        let maxOrder: number = -1;

        for (let i in iteration) {
            if (iteration[i][0].order > maxOrder) {
                lastUuid = i;
                maxOrder = iteration[i][0].order;
            }
        }

        let flowNodes = flow
            .filter(item => item.type === 'node' || item.type === 'input' || item.type === 'trigger')
            .map((item, i) => {
                const isInput = item.type === RuleNodeTypeEnum.INPUT || item.type === RuleNodeTypeEnum.TRIGGER;
                const originalNode = originalNodes[`${item.nodeUuid}-${item.nodeVersion}`];

                const isScratchpadNode = originalNode?.type === NodeTypeEnum.SCRATCHPAD_STATE;
                const isInstanceInfoNode = originalNode?.type === NodeTypeEnum.INSTANCE_INFO;
                const isStickyNote = originalNode?.type === NodeTypeEnum.STICKY;

                let type = isInput ? 'trigger' : 'default';

                if (isScratchpadNode) {
                    type = 'scratchpad';
                }

                if (isInstanceInfoNode) {
                    type = 'instanceInfo'
                }

                if (isStickyNote) {
                    type = 'stickyNote'
                }
                item = item as RuleNodeInterface;
                return {
                    id: item.uuid,
                    type,
                    data: {
                        // label: <FlowNode node={item} index={i} />,
                        data: {
                            ...item,
                            type: isInput ? 'input' : 'default',
                        }
                    },
                    selected: item.uuid === debugNodeUuid,
                    selectable: !!iteration[item.uuid]?.[0],
                    draggable: false,
                    connectable: false,
                    className:  (!!iteration[item.uuid]?.[0] ? 'debuggable' : 'disabled') + ' ' +
                        (!!iteration[item.uuid]?.[0].error ? 'error' : '') + ' ' +
                        (item.type === RuleNodeTypeEnum.INPUT ? 'input' : '') + ' ' +
                        (item.uuid === lastUuid ? 'output' : ''),
                    active: false,
                    position: item.position ? {
                        x: item.position.x - item.position.x % 16,
                        y: item.position.y - item.position.y % 16,
                    } : {x: 0, y: 0},
                };
            });

        let flowEdges = flow
            .filter(item => item.type === 'edge')
            .map((item, i) => {
                item = item as RuleEdgeInterface;

                let result: any = iteration[item.source]?.[0].output;

                if (!iteration[item.target]?.[0]) {
                    result = undefined;
                }

                const hasValue = (
                    iteration[item.source]?.[0] && (item.if !== undefined ? item.if === Boolean(result) : true)
                );
                const colored = hasValue && iteration[item.target]?.[0];

                let label;

                if (hasValue) {
                    if (item.if !== undefined) {
                        label = Boolean(result) ? 'true' : 'false';
                    } else {
                        label = result ? isObject(result) ? 'json' : result.toString() : undefined;
                    }
                }

                return {
                    id: `${item.uuid}-ghost`,
                    source: item.source,
                    target: item.target,
                    type: 'smoothstep',
                    // type: 'floating',
                    label,
                    selected: false,
                    data: {
                        data: item
                    },
                    style: {
                        stroke: colored ? 'rgb(26, 79, 26)' : 'rgba(34,36,38,.15)',
                        strokeWidth: colored ? 3 : 1,
                    },
                    labelStyle: {
                        fill: 'rgba(34,36,38,.5)'
                    },
                    markerEnd: colored ? undefined : {
                        type: MarkerType.Arrow,
                    },
                };
            });
        setNodes(flowNodes);
        setEdges(flowEdges);
    }, [flow, activeNodeUuid, debugNodeUuid, debugModeEnabled, logs, debugData, selectedIteration]);

    function onNodeClick(e: MouseEvent, node: Node) {
        dispatch(setActiveNodeUuid(undefined));
        if (iteration[node.id]?.[0]) {
            dispatch(setDebugNodeUuid(node.id));
        }
    }
    function onPageClick(e: MouseEvent) {
        dispatch(setDebugNodeUuid(undefined));
    }

    return (
        <ReactFlowProvider>
            <div ref={reactFlowWrapper} style={{height: '100%', background: 'white'}}>
                <ReactFlow nodes={nodes}
                           edges={edges}
                           nodesConnectable={false}
                           onNodesChange={onNodesChange}
                           onEdgesChange={onEdgesChange}
                           onInit={setReactFlowInstance}
                           onNodeDragStart={(e, node) => onNodeClick(e, node)}
                           onNodeClick={(e, node) => onNodeClick(e, node)}
                           onPaneClick={(e) => onPageClick(e)}
                           snapToGrid={true}
                           snapGrid={[16, 16]}
                           className={debugModeEnabled ? 'debug' : ''}
                           selectNodesOnDrag={false}
                           fitView={!flowZoom && !flowPosition}
                           defaultViewport={{
                               x: flowPosition ? flowPosition[0] : 0,
                               y: flowPosition ? flowPosition[1] : 0,
                               zoom: flowZoom || 10
                           }}
                           onMoveEnd={(e, viewport) => {
                               dispatch(setZoom(viewport.zoom));
                               dispatch(setPosition([viewport.x, viewport.y]));
                           }}
                           nodeTypes={nodeTypes}
                           // @ts-ignore
                           // edgeTypes={edgeTypes}
                           // @ts-ignore
                           // connectionLineComponent={FloatingConnectionLine}
                >
                    <Controls/>
                    <Background color="#aaa" gap={16} />
                </ReactFlow>
            </div>
        </ReactFlowProvider>
    );
}