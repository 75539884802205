import {ReactElement, useEffect, useState} from "react";
import {Button, Dimmer, Form, Loader, Message, Modal} from "semantic-ui-react";
import {useAppDispatch, useAppSelector} from "../../store";
import {intersection, isArray, isObject} from "lodash";
import {floatify, stringifyValue} from "../../helpers/common";
import {JsonEditor} from "../JsonEditor";
import {useTestNodeMutation} from "../../apis/nodes.api";
import {NodeInterface} from "../../interfaces/node.interface";
import {setDebugData} from "../../slices/nodes.slice";
import AceEditor from 'react-ace';
import "ace-builds/src-noconflict/worker-json.js";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";

export function StartDebugNodeModal({children, node}: { children: ReactElement, node: NodeInterface }) {
    const dispatch = useAppDispatch();
    const [testNode, {data, isLoading, isSuccess, isError, error}] = useTestNodeMutation();
    const [isOpen, setIsOpen] = useState(false);
    const [errors, setErrors] = useState<{[paramName: string]: string}>({});
    const code = useAppSelector((state) => state.nodes.editor.code);

    const [formData, setFormData] = useState<any>({
        inputData: {
        },
        nodeParams: {
        },
    });

    useEffect(() => {
        if (isSuccess && data) {
            dispatch(setDebugData(data));
            setIsOpen(false);
        }
    }, [isSuccess]);

    function test() {
        testNode({
            uuid: node.uuid,
            version: node.version,
            ...formData,
            code
        });
    }

    return (
        <>
            <span onClick={() => setIsOpen(true)}>{children}</span>
            <Modal size="small" closeIcon onClose={() => setIsOpen(false)} open={isOpen} style={{marginBottom: 50}}>
                <Dimmer inverted active={isLoading}><Loader inverted/></Dimmer>
                <Modal.Header>Debug node</Modal.Header>
                <Modal.Content scrolling>
                    <h4>Node params</h4>
                    <Form>
                        {node.params.map((param) => (
                            <>
                                {param.type.toString().split(',').indexOf('enum') !== -1 && (
                                    <Form.Select
                                        label={`${param.name} (${param.type})`}
                                        value={formData.nodeParams[param.name]}
                                        clearable
                                        options={param.enum?.map((item) => ({
                                            key: item.value,
                                            value: item.value,
                                            text: item.title,
                                        })) || []}
                                        onChange={(_e, {value}) => {
                                            setFormData({
                                                ...formData,
                                                nodeParams: {
                                                    ...formData.nodeParams,
                                                    [param.name]: value
                                                }
                                            })
                                        }}
                                    />
                                )}
                                {intersection(param.type.toString().split(','), ['array', 'object']).length > 0 && (
                                    <Form.Field>
                                        <label style={{color: errors[param.name] ? 'red' : 'inherit'}}>{`${param.name} (${param.type})`}</label>
                                        <div style={{height: 300}}>
                                            <JsonEditor
                                                value={formData.nodeParams[param.name]}
                                                onChange={(value) => {
                                                    setFormData({
                                                        ...formData,
                                                        nodeParams: {
                                                            ...formData.nodeParams,
                                                            [param.name]: value
                                                        }
                                                    })
                                                    setErrors({...errors, [param.name]: ''});
                                                }}
                                                onError={(error) => {
                                                    setErrors({...errors, [param.name]: error});
                                                }}
                                            />
                                        </div>
                                    </Form.Field>
                                )}
                                {intersection(param.type.toString().split(','), ['enum', 'array', 'object']).length === 0 && (
                                    <Form.Input
                                        label={`${param.name} (${param.type})`}
                                        value={formData.nodeParams[param.name]?.toString()}
                                        onChange={(_e, {value}) => {
                                            setFormData({
                                                ...formData,
                                                nodeParams: {
                                                    ...formData.nodeParams,
                                                    [param.name]: floatify(value) || ''
                                                }
                                            })
                                        }}
                                    />
                                )}
                            </>
                        ))}
                    </Form>
                    <h4 style={{color: errors['inputData'] ? 'red' : 'inherit'}}>Input data (int, double, string or JSON)</h4>
                    <Form>
                        <Form.Field>
                            <div style={{height: 300}}>
                                <AceEditor
                                    mode="json"
                                    defaultValue={isObject(formData.inputData) || isArray(formData.inputData) ? stringifyValue(formData.inputData) : formData.inputData.toString()}
                                    theme="github"
                                    width="100%"
                                    height="100%"
                                    setOptions={{ useWorker: false }}
                                    onChange={(value) => {
                                        try {
                                            setFormData({
                                                ...formData,
                                                inputData: JSON.parse(value)
                                            });
                                        } catch (e: any) {
                                            setFormData({
                                                ...formData,
                                                inputData: floatify(value)
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    {isError && <Message error style={{textAlign: 'left'}} content={(error as any)?.data?.error?.description}/>}
                    <Button primary content="Debug" onClick={() => test()}/>
                </Modal.Actions>
            </Modal>
        </>
    );
}