import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../helpers/base-query";
import {PaginationInterface} from "../interfaces/pagination.interface";
import {NodeInterface} from "../interfaces/node.interface";
import {CreateNodeInterface} from "../interfaces/create-node.interface";
import {NodeStatusEnum} from "../interfaces/node-status.enum";
import {CommitNodeInterface} from "../interfaces/commit-node.interface";
import {PublishNodeInterface} from "../interfaces/publish-node.interface";
import {TestNodeResultInterface} from "../interfaces/test-node-result.interface";

export const nodesApi = createApi({
    reducerPath: 'nodesApi',
    baseQuery: baseQuery,
    tagTypes: ['Nodes', 'Node'],
    endpoints: (builder) => ({
        findNodes: builder.query<{
            data: NodeInterface[];
            pagination: PaginationInterface;
        }, {
            name?: string;
            status?: NodeStatusEnum;
            createdBy?: number;
            orgId?: number;
            page?: number;
            limit?: number;
            groupByVersion?: boolean;
            uuid?: string;
            getUsageCount?: boolean;
            getUsages?: boolean;
        }>({
            query: (params) => ({
                url: 'nodes',
                params
            }),
            providesTags: (result) => {
                return [
                    ...result?.data.map((item) => ({type: 'Nodes' as const, id: `${item.uuid}-${item.version}`})) || [],
                    {
                        type: 'Nodes',
                        id: 'LIST',
                    }
                ];
            },
        }),

        getNode: builder.query<NodeInterface, { nodeUuid: string; nodeVersion: number }>({
            query: ({nodeUuid, nodeVersion}) => `nodes/${nodeUuid}/${nodeVersion}`,
            providesTags: (_result, error, {nodeUuid, nodeVersion}) => {
                return error ? [] : [{type: 'Node', id: `${nodeUuid}-${nodeVersion}`}];
            },
        }),

        createNode: builder.mutation<NodeInterface, CreateNodeInterface>({
            query: (body) => ({
                url: `nodes`,
                method: 'POST',
                body
            }),
            invalidatesTags: [{type: 'Nodes', id: 'LIST'}],
        }),

        updateNode: builder.mutation<NodeInterface, Partial<CreateNodeInterface> & Pick<NodeInterface, 'uuid' | 'version'>>({
            query: ({uuid, version, ...body}) => ({
                url: `nodes/${uuid}/${version}`,
                method: 'POST',
                body
            }),
            // invalidatesTags: (_result, _error, {uuid, version}) => [
            //     {type: 'Nodes', id: `${uuid}-${version}`},
            //     {type: 'Node', id: `${uuid}-${version}`},
            // ],
            async onQueryStarted({uuid, version, ...patch}, {dispatch, queryFulfilled}) {
                const {data: updatedNode} = await queryFulfilled;
                dispatch(
                    nodesApi.util.updateQueryData('getNode', {nodeUuid: uuid, nodeVersion: version}, (draft) => {
                        Object.assign(draft, updatedNode)
                    })
                );
            },
        }),

        commitNode: builder.mutation<NodeInterface, CommitNodeInterface>({
            query: (body) => ({
                url: `nodes/commit`,
                method: 'POST',
                body
            }),
            invalidatesTags: [{type: 'Nodes', id: 'LIST'}],
            async onQueryStarted(patch, {dispatch, queryFulfilled}) {
                const {data: updatedNode} = await queryFulfilled;
                dispatch(
                    nodesApi.util.updateQueryData('getNode', {nodeUuid: updatedNode.uuid, nodeVersion: updatedNode.version}, (draft) => {
                        Object.assign(draft, updatedNode)
                    })
                );
            },
        }),

        publishNode: builder.mutation<NodeInterface, PublishNodeInterface>({
            query: (body) => ({
                url: `nodes/publish`,
                method: 'POST',
                body
            }),
            invalidatesTags: [{type: 'Nodes', id: 'LIST'}],
            async onQueryStarted(patch, {dispatch, queryFulfilled}) {
                const {data: updatedNode} = await queryFulfilled;
                dispatch(
                    nodesApi.util.updateQueryData('getNode', {nodeUuid: updatedNode.uuid, nodeVersion: updatedNode.version}, (draft) => {
                        Object.assign(draft, updatedNode)
                    })
                );
            },
        }),

        deleteNode: builder.mutation<void, { nodeUuid: string; nodeVersion: number }>({
            query: ({nodeUuid, nodeVersion}) => ({
                url: `nodes/${nodeUuid}/${nodeVersion}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, {nodeUuid, nodeVersion}) => [
                {type: 'Nodes', id: 'LIST'},
                {type: 'Node', id: `${nodeUuid}-${nodeVersion}`}
            ],
        }),

        testNode: builder.mutation<TestNodeResultInterface, {
            uuid: string;
            version: number;
            inputData: any;
            nodeParams: { [key: string]: any };
            code?: string;
            assertions?: string;
        }>({
            query: ({uuid, version, ...body}) => ({
                url: `nodes/${uuid}/${version}/test`,
                method: 'PATCH',
                body
            }),
        }),
    }),
})

export const {
    useFindNodesQuery,
    useGetNodeQuery,
    useCreateNodeMutation,
    useUpdateNodeMutation,
    useCommitNodeMutation,
    usePublishNodeMutation,
    useTestNodeMutation,
    useDeleteNodeMutation
} = nodesApi;