import {To, useNavigate} from "react-router-dom";

export function Logo({to}: {to?: To}) {
    const navigate = useNavigate();

    return (
        <div className="app-header__logo" style={{width: 300, cursor: 'pointer'}} onClick={() => navigate(to || '/')}>
            <h3 className="app-header__title">cycles</h3>
        </div>
    )
}