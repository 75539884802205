import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {NodeInterface} from "../interfaces/node.interface";
import {TriggerInterface} from "../interfaces/trigger.interface";
import {cloneDeep} from "lodash";
import {GlobalEventInterface} from "../interfaces/global-event.interface";

export interface EditorStateInterface {
    ready: boolean;
    nodes: NodeInterface[];
    nodesLoaded: boolean;
    triggers: TriggerInterface[];
    triggersLoaded: boolean;
    events: GlobalEventInterface[];
    eventsLoaded: boolean;
}

const initialState: EditorStateInterface = {
    ready: false,
    nodes: [],
    nodesLoaded: false,
    triggers: [],
    triggersLoaded: false,
    events: [],
    eventsLoaded: false,
}

export const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        reset: (draft: EditorStateInterface, action: PayloadAction<void>) => {
            return cloneDeep(initialState);
        },
        setNodes: (draft: EditorStateInterface, action: PayloadAction<NodeInterface[]>) => {
            draft.nodes = action.payload;
            draft.nodesLoaded = true;
            draft.ready = draft.nodesLoaded && draft.triggersLoaded && draft.eventsLoaded;
        },
        setTriggers: (draft: EditorStateInterface, action: PayloadAction<TriggerInterface[]>) => {
            draft.triggers = action.payload;
            draft.triggersLoaded = true;
            draft.ready = draft.nodesLoaded && draft.triggersLoaded && draft.eventsLoaded;
        },
        setEvents: (draft: EditorStateInterface, action: PayloadAction<GlobalEventInterface[]>) => {
            draft.events = action.payload;
            draft.eventsLoaded = true;
            draft.ready = draft.nodesLoaded && draft.triggersLoaded && draft.eventsLoaded;
        }
    },
});

export const {reset, setNodes, setTriggers, setEvents} = editorSlice.actions;

export const editorReducer = editorSlice.reducer;