import {Button, Divider, Form, Header, Segment} from "semantic-ui-react";
import {removeNode, setActiveNodeUuid, updateNode} from "../../slices/flow.slice";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import {NodeInterface} from "../../interfaces/node.interface";
import {ParamInterface} from "../../interfaces/param.interface";
import {RuleNodeInterface} from "../../interfaces/rule-node.interface";
import {RuleInterface} from "../../interfaces/rule.interface";
import {ParamsForm} from "../ParamsForm";
import {RemoveNodeModal} from "../RemoveNodeModal";
import {useUpdateRuleMutation} from "../../apis/rules.api";
import {cloneDeep} from "lodash";
import {useDebounce} from "../../hooks/useDebounce";
import PerfectScrollbar from "react-perfect-scrollbar";

export function NodeParams({rule, readOnly}: { rule: RuleInterface, readOnly?: boolean }) {
    const dispatch = useAppDispatch();
    const activeNodeUuid = useAppSelector(state => state.flow.activeNodeUuid);
    const nodes = useAppSelector(state => state.editor.nodes);
    const flow = useAppSelector(state => state.flow.flow);

    const [activeNode, setActiveNode] = useState<RuleNodeInterface>();
    const [node, setNode] = useState<NodeInterface>();
    const [params, setParams] = useState<ParamInterface[]>([]);
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [description, setDescription] = useState<string | undefined>(activeNode?.description);
    const [nodeVersion, setNodeVersion] = useState<number | undefined>(activeNode?.nodeVersion);
    const debouncedDescription = useDebounce(description, 1000);
    const [updateRule] = useUpdateRuleMutation();

    useEffect(() => {
        let activeNode = flow.find(node => node.uuid === activeNodeUuid);
        setActiveNode(activeNode);
        setDescription(activeNode?.description || '');
    }, [activeNodeUuid, flow]);

    useEffect(() => {
        setNode(nodes.find(node => node.uuid === activeNode?.nodeUuid && node.version === activeNode?.nodeVersion));
    }, [activeNode]);

    useEffect(() => {
        node && setParams(node.params);
    }, [node]);

    useEffect(() => {
        if (!activeNode || activeNode?.description === debouncedDescription) {
            return;
        }
        dispatch(updateNode({
            uuid: activeNode.uuid,
            data: {description: debouncedDescription}
        }));
        const newFlow = cloneDeep(flow);
        const node = newFlow.find((node) => node.uuid === activeNode.uuid);
        if (node) {
            node.description = debouncedDescription;
            updateRule({
                uuid: rule.uuid,
                version: rule.version,
                flow: newFlow
            });
        }
    }, [debouncedDescription]);


    useEffect(() => {
        if (!activeNode) {
            return;
        }
        dispatch(updateNode({
            uuid: activeNode.uuid,
            data: {nodeVersion}
        }));
        const newFlow = cloneDeep(flow);
        const node = newFlow.find((node) => node.uuid === activeNode.uuid);
        if (node) {
            node.nodeVersion = nodeVersion;
            updateRule({
                uuid: rule.uuid,
                version: rule.version,
                flow: newFlow
            });
        }
    }, [nodeVersion]);

    if (!activeNode) {
        return <></>;
    }

    function handleParamChange(param: ParamInterface, value: any) {
        if (!activeNode) {
            return;
        }
        let nodeParams = {...activeNode.nodeParams};

        if (value?.toString().match(/^[\d\.]+$/)) {
            value = parseFloat(value);
        }
        let matches = value?.toString().match(/^\<(\$.*?)\>$/);
        if (matches && matches[1]) {
            value = matches[1];
        }

        nodeParams[param.name] = value;
        dispatch(updateNode({
            uuid: activeNode.uuid,
            data: {nodeParams}
        }));

        const newFlow = cloneDeep(flow);
        const node = newFlow.find((node) => node.uuid === activeNode.uuid);
        if (node) {
            node.nodeParams = nodeParams;
            updateRule({
                uuid: rule.uuid,
                version: rule.version,
                flow: newFlow
            });
        }
    }

    function remove() {
        if (!activeNode) {
            return;
        }
        const newFlow = cloneDeep(flow).filter((node) => node.uuid !== activeNode.uuid);
        dispatch(removeNode(activeNode.uuid));
        setRemoveModalOpen(false);
        updateRule({
            uuid: rule.uuid,
            version: rule.version,
            flow: newFlow
        });
    }

    return (
        <PerfectScrollbar>
        <Segment basic>
            {node && (
                <>
                    <Header style={{marginTop: '0.25em'}}>
                        {node.name}
                        <Button className="close-btn" icon="close" onClick={() => dispatch(setActiveNodeUuid(undefined))}/>
                    </Header>
                    <Divider/>
                    <p>uuid: {activeNode.uuid}</p>
                    <p>{node.description}</p>
                    <p>Output: {node.outputType} {node.outputOf ? `of ${node.outputOf}` : ''} {node.outputDescription && `(${node.outputDescription})`}</p>
                    {readOnly ? (
                        <Form>
                            <Form.Field>
                                <h5 style={{marginBottom: 5}}>Node version</h5>
                                <p>{activeNode.nodeVersion}</p>
                            </Form.Field>
                            <Form.Field>
                                <h5 style={{marginBottom: 5}}>Description</h5>
                                <p>{activeNode.description || <>&mdash;</>}</p>
                            </Form.Field>
                        </Form>
                    ) : (
                        <Form>
                            <Form.Select
                                label="Node version"
                                value={activeNode.nodeVersion}
                                options={nodes.filter(node => node.uuid === activeNode?.nodeUuid).map((node, i) => ({
                                    key: `node-${i}`,
                                    value: node.version,
                                    text: `Version ${node.version}`
                                }))}
                                onChange={(e, {value}) => setNodeVersion(value as number)}
                            />
                            <Form.TextArea
                                label="Description (notes)"
                                value={description}
                                onChange={(e, {value}) => setDescription(value as string)}
                            />
                        </Form>
                    )}
                    <Divider hidden/>
                    <ParamsForm
                        readOnly={readOnly}
                        rule={rule}
                        params={params}
                        values={activeNode.nodeParams || {}}
                        onChange={handleParamChange}
                    />
                </>
            )}
            {!readOnly && <>
                <Button color="red" basic content="Remove node" fluid
                        onClick={() => setRemoveModalOpen(true)}/>
                <RemoveNodeModal
                    open={removeModalOpen}
                    onYes={() => remove()}
                    onNo={() => setRemoveModalOpen(false)}/>
            </>}
        </Segment>
        </PerfectScrollbar>
    );
}