import ReactFlow, {
    addEdge,
    Background,
    Controls,
    Edge,
    MarkerType,
    Node,
    ReactFlowInstance,
    ReactFlowProvider,
    useEdgesState,
    useNodesState
} from 'reactflow';
import {FlowNode} from "./FlowNode";
import {MouseEvent, useCallback, useEffect, useRef, useState} from "react";
import {setActiveNodeUuid, setFlow, setPosition, setSelectedNodeOutput, setZoom} from "../slices/flow.slice";
import {v4} from 'uuid';
import {keyBy} from 'lodash';
import {useAppDispatch, useAppSelector} from "../store";
import {setDebugNodeUuid} from "../slices/debug.slice";
import {flatten, isObject, isUndefined} from "lodash";
import {RuleInterface} from "../interfaces/rule.interface";
import {RuleNodeInterface, RuleNodeTypeEnum} from "../interfaces/rule-node.interface";
import {RuleEdgeInterface} from "../interfaces/rule-edge.interface";
import {useUpdateRuleMutation} from "../apis/rules.api";
import 'reactflow/dist/style.css';
import './Flow.scss';
import {FlowScratchpadNode} from "./FlowScratchpadNode";
import {FlowInstanceInfoNode} from "./FlowInstanceInfoNode";
import {FlowStickyNoteNode} from "./FlowStickyNoteNode";
import {FlowTriggerNode} from "./FlowTriggerNode";
import {NodeTypeEnum} from "../interfaces/node-type.enum";

const nodeTypes = {
    trigger: FlowTriggerNode,
    input: FlowNode,
    default: FlowNode,
    scratchpad: FlowScratchpadNode,
    instanceInfo: FlowInstanceInfoNode,
    stickyNote: FlowStickyNoteNode
};

export function Flow({rule, readOnly, zoomOnScroll}: {rule: RuleInterface, readOnly?: boolean, zoomOnScroll?: boolean}) {
    const flow = useAppSelector((state) => state.flow.flow);
    const flowZoom = useAppSelector((state) => state.flow.zoom);
    const flowPosition = useAppSelector((state) => state.flow.position);
    const activeNodeUuid = useAppSelector((state) => state.flow.activeNodeUuid);
    const selectNodeOutputMode = useAppSelector(state => state.flow.selectNodeOutputMode);

    const debugData = useAppSelector((state) => state.debug.debugData);
    const debugModeEnabled = useAppSelector((state) => state.debug.debugModeEnabled);
    const debugNodeUuid = useAppSelector((state) => state.debug.debugNodeUuid);
    const selectedIteration = useAppSelector((state) => state.debug.selectedIteration);
    const originalNodes = useAppSelector(state => keyBy(state.editor.nodes, item => `${item.uuid}-${item.version}`));
    const iteration = debugModeEnabled ? (debugData[selectedIteration] || {}) : {};

    const dispatch = useAppDispatch();
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const [updateRule] = useUpdateRuleMutation();
    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const mounted = useRef(false);

    const onConnect = (params: any) => {
        setEdges((eds) => {
            const updated = addEdge(params, eds);
            setNodes([...nodes]);
            updateFlow(nodes, updated);
            return updated;
        });
    };

    useEffect(() => {
        if (!mounted.current && rule?.flow) {
            // dispatch(setZoom(undefined));
            // dispatch(setPosition(undefined));
            // dispatch(setActiveNodeUuid(undefined));
            dispatch(setFlow(rule?.flow))
            mounted.current = true;
        }
    }, [rule]);

    useEffect(() => {
        if (!flow) {
            return;
        }

        let flowNodes = flow
            .filter(item => item.type === 'node' || item.type === 'input' || item.type === 'trigger')
            .map((item, i) => {
                const isInput = item.type === RuleNodeTypeEnum.INPUT || item.type === RuleNodeTypeEnum.TRIGGER;
                const originalNode = originalNodes[`${item.nodeUuid}-${item.nodeVersion}`];

                const isScratchpadNode = originalNode?.type === NodeTypeEnum.SCRATCHPAD_STATE;
                const isInstanceInfoNode = originalNode?.type === NodeTypeEnum.INSTANCE_INFO;
                const isStickyNote = originalNode?.type === NodeTypeEnum.STICKY;

                let type = isInput ? 'trigger' : 'default';

                if (isScratchpadNode) {
                    type = 'scratchpad';
                }

                if (isInstanceInfoNode) {
                    type = 'instanceInfo'
                }

                if (isStickyNote) {
                    type = 'stickyNote'
                }

                item = item as RuleNodeInterface;
                return {
                    id: item.uuid,
                    type,
                    data: {
                        //label: <FlowNode node={item} index={i} />,
                        data: {
                            ...item,
                            nodeVersion: isUndefined(item.nodeVersion) ? 1 : item.nodeVersion,
                        }
                    },
                    zIndex: 2,
                    selected: item.uuid === activeNodeUuid,
                    selectable: !selectNodeOutputMode && !isScratchpadNode,
                    draggable: !readOnly && !debugModeEnabled,
                    connectable: !readOnly && !debugModeEnabled && !isScratchpadNode,
                    active: false,
                    position: item.position ? {
                        x: item.position.x - item.position.x % 16,
                        y: item.position.y - item.position.y % 16,
                    } : {x: 0, y: 0},

                    className: debugModeEnabled ? ( (!!iteration[item.uuid]?.[0] ? 'debuggable' : 'disabled') + ' ' +
                        (!!iteration[item.uuid]?.[0].error ? 'error' : '') + ' ' +
                        (item.type === RuleNodeTypeEnum.INPUT ? 'input' : '') ) : ''
                };
            });

        let flowEdges;

        if (debugModeEnabled) {
            flowEdges = flow
                .filter(item => item.type === 'edge')
                .map((item, i) => {
                    item = item as RuleEdgeInterface;

                    let result: any = iteration[item.source]?.[0].output;

                    if (!iteration[item.target]?.[0]) {
                        result = undefined;
                    }

                    const hasValue = (
                        iteration[item.source]?.[0] && (item.if !== undefined ? item.if === Boolean(result) : true)
                    );
                    const colored = hasValue && iteration[item.target]?.[0];

                    let label;

                    if (hasValue) {
                        if (item.if !== undefined) {
                            label = Boolean(result) ? 'true' : 'false';
                        } else {
                            label = result ? isObject(result) ? 'json' : result.toString() : undefined;
                        }
                    }

                    return {
                        id: `${item.uuid}-ghost`,
                        source: item.source,
                        target: item.target,
                        // type: 'smoothstep',
                        // type: 'floating',
                        label,
                        selected: false,
                        data: {
                            data: item
                        },
                        style: {
                            stroke: colored ? '#00b5ad' : 'rgba(34,36,38,.15)',
                            strokeWidth: colored ? 3 : 1,
                        },
                        labelStyle: {
                            fill: 'rgba(34,36,38,.5)'
                        },
                        markerEnd: colored ? undefined : {
                            type: MarkerType.Arrow,
                        },
                    };
                });
        } else {

            flowEdges = flow
                .filter(item => item.type === 'edge')
                .map((item, i) => {
                    item = item as RuleEdgeInterface;
                    const label = item.if !== undefined ? (item.if ? 'true' : 'false') : undefined;
                    const selected = item.uuid === activeNodeUuid;

                    return [{
                        id: `${item.uuid}-ghost`,
                        source: item.source,
                        target: item.target,
                        // type: 'smoothstep',
                        label,
                        selected,
                        zIndex: selected ? 1 : 0,
                        data: {
                            data: item
                        },
                        style: {
                            stroke: selected ? 'rgb(26, 79, 26)' : 'rgba(26, 79, 26,.15)',
                            strokeWidth: selected ? 3 : 1,
                        },
                        labelStyle: {
                            fill: 'rgba(34,36,38,.5)'
                        },
                        markerEnd: selected ? undefined : {
                            type: MarkerType.Arrow,
                        },
                    }, {
                        id: item.uuid,
                        source: item.source,
                        target: item.target,
                        // type: 'smoothstep',
                        selected,
                        zIndex: selected ? 3 : 2,
                        data: {
                            data: item
                        },
                        style: {
                            strokeDasharray: 0,
                            stroke: 'transparent',
                            strokeWidth: 25,
                            // stroke: selected ? 'rgb(26, 79, 26)' : 'rgba(34,36,38,.15)'
                        }
                    }];
                });
        }
        setNodes(flowNodes);
        setEdges(flatten(flowEdges as any));
    }, [flow, activeNodeUuid, selectNodeOutputMode, debugModeEnabled, debugData]);

    function updateFlow(nodes: Node[], edges: Edge[]) {
        let newFlow = [
            ...nodes.map(item => ({
                ...item.data.data,
                position: item.position
            })),
            ...edges
                .filter(edge => !edge.id.endsWith('-ghost'))
                .map(item => ({
                uuid: item.id,
                type: "edge",
                source: item.source,
                target: item.target,
                if: item.data?.data?.if
            }))
        ];
        if (JSON.stringify(flow) !== JSON.stringify(newFlow)) {
            dispatch(setFlow(newFlow));
            autosave(newFlow);
        }
    }

    function autosave(newFlow: any) {
        updateRule({
            uuid: rule.uuid,
            version: rule.version,
            flow: newFlow
        });
    }

    const onDragOver = useCallback((event: any) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event: any) => {
            event.preventDefault();

            if (!reactFlowWrapper.current || !reactFlowInstance) {
                return;
            }

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const {type, uuid, version} = JSON.parse(event.dataTransfer.getData('application/reactflow'));

            // check if the dropped element is valid
            if (!type || !uuid) {
                return;
            }

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });
            let ruleNodeUuid = v4();
            let item: RuleNodeInterface = {
                uuid: ruleNodeUuid,
                type,
                nodeUuid: uuid,
                nodeVersion: version,
                nodeParams: {},
                position: {
                    x: position.x,
                    y: position.y,
                },
                description: '',
            };
            const newNode = {
                id: ruleNodeUuid,
                type: 'default',
                position,
                selected: true,
                data: {
                    // label: <FlowNode node={item}/>,
                    data: item
                },
            };

            let updatedNodes = nodes.map(node => Object.assign(node, {selected: false})).concat(newNode);
            updateFlow(updatedNodes, edges);
            dispatch(setActiveNodeUuid(newNode.id));
        },
        [reactFlowInstance, flow, nodes, edges]
    );

    function onNodeClick(e: MouseEvent, node: Node) {
        if (debugModeEnabled) {
            dispatch(setActiveNodeUuid(undefined));
            if (iteration[node.id]?.[0]) {
                dispatch(setDebugNodeUuid(node.id));
            }
        } else {
            if (selectNodeOutputMode) {
                dispatch(setSelectedNodeOutput(node.id));
                e.preventDefault();
                e.stopPropagation();
            } else {
                dispatch(setActiveNodeUuid(node.id));
            }
        }
    }

    function onEdgeClick(e: MouseEvent, edge: Edge) {
        if (!selectNodeOutputMode) {
            dispatch(setActiveNodeUuid(edge.id));
        }
    }

    function onPageClick(e: MouseEvent) {
        dispatch(setDebugNodeUuid(undefined));
        dispatch(setActiveNodeUuid(undefined));
    }

    return (
        <ReactFlowProvider>
            <div ref={reactFlowWrapper} style={{height: '100%', background: 'white'}}>
                <ReactFlow nodes={nodes}
                           edges={edges}
                           onNodesChange={onNodesChange}
                           onEdgesChange={onEdgesChange}
                           onConnect={onConnect}
                           onInit={setReactFlowInstance}
                           onDrop={onDrop}
                           onDragOver={onDragOver}
                           onNodeDragStart={(e, node) => !readOnly && onNodeClick(e, node)}
                           onNodeClick={(e, node) => (readOnly || debugModeEnabled) && onNodeClick(e, node)}
                           onNodeDragStop={() => !readOnly && updateFlow(nodes, edges)}
                           onEdgeClick={(e, edge) => onEdgeClick(e, edge)}
                           onPaneClick={(e) => onPageClick(e)}
                           snapToGrid={true}
                           snapGrid={[16, 16]}
                           selectNodesOnDrag={false}
                           fitView={!flowZoom && !flowPosition}
                           defaultViewport={{
                               x: flowPosition ? flowPosition[0] : 0,
                               y: flowPosition ? flowPosition[1] : 0,
                               zoom: flowZoom || 10
                           }}
                           onMoveEnd={(e, viewport) => {
                               dispatch(setZoom(viewport.zoom));
                               dispatch(setPosition([viewport.x, viewport.y]));
                           }}
                           nodeTypes={nodeTypes}
                           nodesDraggable={!readOnly}
                           nodesConnectable={!readOnly}
                           zoomOnScroll={isUndefined(zoomOnScroll) ? true : zoomOnScroll}
                >
                    {/*<MiniMap/>*/}
                    <Controls/>
                    <Background color="#aaa" gap={16} />
                </ReactFlow>
            </div>
        </ReactFlowProvider>
    );
}