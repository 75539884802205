import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface FlowSliceInterface {
    activeNodeUuid?: string;
    nodeInfoUuid?: string;
    activeNode?: any;
    flow: any[];
    zoom: number | undefined;
    position: [number, number] | undefined;
    selectNodeOutputMode: boolean;
    selectedNodeOutput: string | undefined;
}

const initialState: FlowSliceInterface = {
    activeNodeUuid: undefined,
    nodeInfoUuid: undefined,
    activeNode: undefined,
    flow: [],
    zoom: undefined,
    position: undefined,
    selectNodeOutputMode: false,
    selectedNodeOutput: undefined,
}

export const flowSlice = createSlice({
    name: 'flow',
    initialState,
    reducers: {
        setActiveNodeUuid: (state, action) => {
            state.activeNodeUuid = action.payload;
            state.nodeInfoUuid = undefined;
        },
        setNodeInfoUuid: (state, action) => {
            state.nodeInfoUuid = action.payload;
            state.activeNodeUuid = undefined;
        },
        setFlow: (state, action) => {
            state.flow = action.payload;
        },
        updateNode: (state, action) => {
            let nodeIndex = state.flow.findIndex(node => node.uuid === action.payload.uuid);
            state.flow[nodeIndex] = {...state.flow[nodeIndex], ...action.payload.data};
        },
        removeNode: (state, action) => {
            state.flow = state.flow.filter(node => {
                return !(
                    (node.uuid === action.payload) ||
                    (node.type === 'edge' && (node.source === action.payload || node.target === action.payload))
                );
            })
        },
        setZoom: (state, action: PayloadAction<number | undefined>) => {
            state.zoom = action.payload;
        },
        setPosition: (state, action: PayloadAction<[number, number] | undefined>) => {
            state.position = action.payload;
        },
        setSelectNodeOutputMode: (state, action: PayloadAction<boolean>) => {
            state.selectNodeOutputMode = action.payload;
        },
        setSelectedNodeOutput: (state, action: PayloadAction<string | undefined>) => {
            state.selectedNodeOutput = action.payload;
        },
    },
});

export const {setActiveNodeUuid, setNodeInfoUuid, setSelectNodeOutputMode, setSelectedNodeOutput, setFlow, setZoom, setPosition, updateNode, removeNode} = flowSlice.actions;

export const flowReducer = flowSlice.reducer;