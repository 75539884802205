import {Button, Icon, Item, List, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {NodeInterface} from "../../interfaces/node.interface";
import {groupBy} from "lodash";

export function VersionsList({nodes, asDeveloper}: { nodes?: NodeInterface[]; asDeveloper?: boolean }) {

    return (
        <Segment basic style={{padding: 0}}>
            <Item.Group divided>
                {nodes?.map((node, i) => (
                    <Item key={`node-${i}`}>
                        <Item.Content>
                            <Item.Header
                                as={Link}
                                to={`${node.uuid}/${node.version}`}
                            >v{node.version}</Item.Header>
                            <Item.Description>
                                Release description:<br/>{node.commitMessage || 'Release description was not provided'}
                            </Item.Description>
                            <Item.Description>
                                {node.rules?.length ? (
                                    // <List>
                                    //     {Object.values(groupBy(node.rules, (rule) => [rule.uuid, rule.version])).map((rules) => (
                                    //         <List.Item>
                                    //             <Link target="_blank" to={`/rules/${rules[0].uuid}/${rules[0].version}`}>{rules[0].name}</Link>
                                    //             {' '}({rules.length} times)
                                    //         </List.Item>
                                    //     ))}
                                    // </List>
                                    <p>Node used {node.rules.length} times</p>
                                ) : (
                                    <p>The node isn't used in any rule</p>
                                )}
                            </Item.Description>
                            <Item.Extra>
                                <div style={{paddingTop: '1.5em'}}>
                                    <Icon name="clock outline"/>last
                                    update: {new Date(node.updatedAt).toLocaleDateString()}
                                </div>
                            </Item.Extra>
                        </Item.Content>
                        <Item.Content style={{flex: '0 0 200px'}}>
                            <Button fluid basic color="black" icon="play" content={asDeveloper ? 'Debug' : 'Try'}
                                    as={Link}
                                    to={`/nodes/releases/${node.uuid}/${node.version}`} style={{margin: '5px 0 0 0'}}/>
                        </Item.Content>
                    </Item>
                ))}
            </Item.Group>
        </Segment>
    );
}
