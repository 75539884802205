import {RuleInterface} from "../../interfaces/rule.interface";
import {cloneElement, ReactElement, useEffect, useState} from "react";
import {Button, Dimmer, Form, List, Loader, Message, Modal} from "semantic-ui-react";
import {useUpdateRuleMutation} from "../../apis/rules.api";
import {AddParam} from "./AddParam";

export function RuleParamsModal({children, rule}: { children: ReactElement; rule: RuleInterface }) {
    const [isOpen, setIsOpen] = useState(false);
    const [deleteParam, setDeleteParam] = useState<number>();
    const [updateRule, {isLoading, isSuccess, isError, error}] = useUpdateRuleMutation();
    const [formData, setFormData] = useState({
        name: rule.name,
        description: rule.description,
    });

    useEffect(() => {
        if (rule) {
            setFormData({
                name: rule.name,
                description: rule.description,
            });
        }
    }, [rule]);

    useEffect(() => {
        isOpen && setDeleteParam(undefined);
    }, [isOpen]);

    return (
        <>
            {cloneElement(children, {
                onClick: () => setIsOpen(true)
            })}
            <Modal open={isOpen} onClose={() => setIsOpen(false)} size="mini" closeIcon>
                <Dimmer active={isLoading} inverted><Loader /></Dimmer>
                <Modal.Header>Rule params</Modal.Header>
                <Modal.Content>
                    {isError && !(error as any)?.data?.errors && <Message error content={(error as any)?.data?.error?.description || 'Unknown error'} />}
                    <p style={{opacity: 0.5}}>
                        Rule params will be filled on rule instance creations. You can use its values in your nodes params.
                    </p>
                    <List divided>
                        {rule.params.map((param, i) => (
                            <List.Item key={i} style={{
                                padding: '10px 0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <List.Content title={param.description}>
                                    {param.title}
                                </List.Content>
                                <List.Content style={{marginLeft: 'auto'}}>
                                    <AddParam
                                        params={rule.params}
                                        editIndex={i}
                                        isLoading={isLoading}
                                        isError={isError}
                                        isSuccess={isSuccess}
                                        error={error}
                                        onSubmit={(param, params) => {
                                            updateRule({
                                                uuid: rule.uuid,
                                                version: rule.version,
                                                params
                                            });
                                        }}
                                    >
                                        <Button basic circular icon="pencil" size="mini"/>
                                    </AddParam>
                                    <Button basic circular icon="trash" size="mini" onClick={() => setDeleteParam(i)}/>
                                </List.Content>
                            </List.Item>
                        ))}
                    </List>

                    <AddParam
                        params={rule.params}
                        editIndex={rule.params.length}
                        isLoading={isLoading}
                        isError={isError}
                        isSuccess={isSuccess}
                        error={error}
                        onSubmit={(param, params) => {
                            updateRule({
                                uuid: rule.uuid,
                                version: rule.version,
                                params
                            });
                        }}
                    >
                        <Button content="Add param" fluid color="teal" circular/>
                    </AddParam>

                    <Modal open={deleteParam !== undefined} closeIcon size="mini" onClose={() => setDeleteParam(undefined)}>
                        <Modal.Header>Delete rule param?</Modal.Header>
                        <Modal.Content>
                            Delete rule param &laquo;<b>{rule.params[deleteParam || 0]?.title}</b>&raquo;?
                        </Modal.Content>
                        <Modal.Actions>
                            <Button circular content="Cancel" onClick={() => setDeleteParam(undefined)} />
                            <Button color="red" icon="check" circular content="Delete" onClick={() => {
                                updateRule({
                                    uuid: rule.uuid,
                                    version: rule.version,
                                    params: rule.params.filter((param, k) => deleteParam !== k)
                                });
                                setDeleteParam(undefined);
                            }} />
                        </Modal.Actions>
                    </Modal>
                </Modal.Content>
                <Modal.Actions>
                    <Button icon="check" content="Close" circular onClick={() => setIsOpen(false)} />
                </Modal.Actions>
            </Modal>
        </>
    )
}