import {Form} from "semantic-ui-react";
import {intersection} from "lodash";
import {JsonEditor} from "../JsonEditor";
import {floatify} from "../../helpers/common";
import {RuleInterface} from "../../interfaces/rule.interface";
import {useEffect, useState} from "react";

export function FillParamsForm({rule, value, onChange}: {rule: RuleInterface, value?: object, onChange: (formData: object) => void}) {
    const [formData, setFormData] = useState<any>(value || {});
    const [errors, setErrors] = useState<{[paramName: string]: string}>({});

    useEffect(() => {
        onChange && onChange(formData);
    }, [formData]);

    return (
        <Form>
            {rule.params.map((param, i) => (
                <div key={`param-${i}`} style={{marginBottom: '1em'}}>
                    {param.type.toString().split(',').indexOf('enum') !== -1 && (
                        <Form.Select
                            label={`${param.title} (${param.type})`}
                            value={formData[param.name]}
                            clearable
                            options={param.enum?.map((item) => ({
                                key: item.value,
                                value: item.value,
                                text: item.title,
                            })) || []}
                            onChange={(_e, {value}) => {
                                setFormData({...formData, [param.name]: value})
                            }}
                        />
                    )}
                    {intersection(param.type.toString().split(','), ['array', 'object']).length > 0 && (
                        <Form.Field>
                            <label style={{color: errors[param.name] ? 'red' : 'inherit'}}>{`${param.title} (${param.type})`}</label>
                            <div style={{height: 300}}>
                                <JsonEditor
                                    value={formData[param.name]}
                                    onChange={(value) => {
                                        setFormData({...formData, [param.name]: value})
                                        setErrors({...errors, [param.name]: ''});
                                    }}
                                    onError={(error) => {
                                        setErrors({...errors, [param.name]: error});
                                    }}
                                />
                            </div>
                        </Form.Field>
                    )}
                    {param.type.toString().split(',').indexOf('bool') !== -1 && (
                        <Form.Checkbox
                            label={`${param.title} (${param.type})`}
                            checked={formData[param.name]}
                            onChange={(_e, {checked}) => {
                                setFormData({...formData, [param.name]: checked})
                            }}
                        />
                    )}
                    {intersection(param.type.toString().split(','), ['enum', 'array', 'object', 'bool']).length === 0 && (
                        <Form.Input
                            label={`${param.title} (${param.type})`}
                            value={formData[param.name]?.toString()}
                            onChange={(_e, {value}) => {
                                setFormData({...formData, [param.name]: floatify(value)})
                            }}
                        />
                    )}
                </div>
            ))}
        </Form>
    )
}