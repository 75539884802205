import {UserMenu} from "./UserMenu";
import {Logo} from "./Logo";
import {useAppSelector} from "../store";

export function Header({muted}: {muted?: boolean}) {
    const org = useAppSelector(state => state.auth.org);

    return (
        <div className={`app-header ${muted ? 'muted' : ''}`}>
            <Logo/>
            {org && <h3 style={{margin: 0}}>{org.title} workspace</h3>}
            <div style={{display: 'flex', gap: '1em', alignItems: 'center', justifyContent: 'right', width: 300}}>
                <UserMenu />
            </div>
        </div>
    );
}