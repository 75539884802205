import {Card, Dimmer, Divider, Header as SemanticHeader, Loader, Message, Popup, Segment, Tab} from "semantic-ui-react";
import {useGetNodeQuery, useUpdateNodeMutation} from "../../apis/nodes.api";
import {useParams} from "react-router-dom";
import {Layout} from "../../components/Layout";
import {NodeParams} from "./NodeParams";
import {Hint} from "../../components/Hint";
import {NodeGeneralOptions} from "./NodeGeneralOptions";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Editor} from "./Editor";
import {DebugWindow} from "./DebugWindow";
import SyntaxHighlighter from "react-syntax-highlighter/dist/cjs/light";
import typescript from "react-syntax-highlighter/dist/esm/languages/hljs/typescript";
import {useEffect} from "react";
import {resetEditor} from "../../slices/nodes.slice";
import {useAppDispatch, useAppSelector} from "../../store";
import {Help} from "./Help";
import {Header} from "./Header";
import {useDebounce} from "../../hooks/useDebounce";
import {NodeTestCases} from "../../components/node/test-cases/NodeTestCases";

SyntaxHighlighter.registerLanguage('typescript', typescript);

export function NodeEditor() {
    const {nodeUuid, nodeVersion} = useParams();
    const code = useAppSelector((state) => state.nodes.editor.code);
    const {data: node, refetch} = useGetNodeQuery({
        nodeUuid,
        nodeVersion: parseInt(nodeVersion || '')
    } as any, {skip: !nodeUuid || !nodeVersion})
    const [updateNode, updateResult] = useUpdateNodeMutation();
    const debouncedCode = useDebounce(code, 1000);
    const dispatch = useAppDispatch();

    useEffect(() => {
        refetch();

        return () => {
            dispatch(resetEditor());
        }
    }, []);

    useEffect(() => {
        nodeUuid && code && code !== node?.code && updateNode({
            uuid: nodeUuid,
            version: parseInt(nodeVersion || '0'),
            code
        });
    }, [debouncedCode]);

    return (
        <Layout
            headerContent={<Header/>}
            leftSidebarContent={(
                <PerfectScrollbar>
                    <Help/>
                </PerfectScrollbar>
            )}
            mainContent={(
                node ? (
                    <div style={{display: 'flex', height: 'calc(100% + 2em)', flexDirection: 'column', margin: '-1em'}}>
                        {updateResult?.isError && <Message error
                                                           content={(updateResult?.error as any)?.data?.error?.description || 'Unknown error'}
                                                           style={{margin: '1em'}}/>}
                        <Editor node={node}/>
                        <DebugWindow/>
                    </div>
                ) : (
                    <Dimmer active inverted><Loader/></Dimmer>
                )
            )}
            rightSidebarContent={(
                <PerfectScrollbar>
                    <Segment basic style={{height: '100%'}}>
                        <SemanticHeader style={{marginTop: '0.25em'}}>
                            Node options
                            <Hint position="bottom left" floated="right">
                                <>
                                    <p><b>General</b>: common node params.</p>
                                    <p><b>Params</b>: node params which must be filled on adding to the rule flow.</p>
                                </>
                            </Hint>
                        </SemanticHeader>
                        <Divider/>
                        <Card.Content style={{paddingTop: 0, paddingBottom: '2em'}}>
                            {node && <Tab menu={{text: true}} panes={[
                                {
                                    menuItem: 'General',
                                    render: () => <NodeGeneralOptions node={node}/>
                                },
                                {
                                    menuItem: 'Params',
                                    render: () => <NodeParams node={node}/>
                                },
                                {
                                    menuItem: 'Test cases',
                                    render: () => <NodeTestCases node={node}/>
                                }
                            ]}
                            />}
                        </Card.Content>

                    </Segment>
                </PerfectScrollbar>
            )}
        />
    )
}