import {DebugDataItemInterface} from "../../interfaces/debug-data.interface";
import {NodeInterface} from "../../interfaces/node.interface";
import {Card, Message, Segment, Select} from "semantic-ui-react";
import {docco} from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter/dist/cjs/light";
import {useAppDispatch, useAppSelector} from "../../store";
import {setDebugIteration} from "../../slices/debug.slice";
import {TriggerInterface} from "../../interfaces/trigger.interface";

export function DebugNode({data, node}: {
    data?: DebugDataItemInterface;
    node?: NodeInterface | TriggerInterface;
}) {
    const debugData = useAppSelector((state) => state.debug.debugData);
    const selectedIteration = useAppSelector((state) => state.debug.selectedIteration);
    const dispatch = useAppDispatch();

    if (!node || !data) {
        return (
            <p>No debug data</p>
        );
    }

    return (
        <>
            <p>
                Node name: {node.name}
            </p>
            {debugData.length > 1 &&
                <Select
                    fluid
                    value={selectedIteration}
                    onChange={(_e, {value}) => dispatch(setDebugIteration(Number(value)))}
                    options={debugData.map((data, index) => ({
                        key: index,
                        value: index,
                        text: `Iteration #${index + 1}`
                    }))}
                />
            }
            {data.error && <Message error header="Error" content={JSON.stringify(data.error)}/>}
            <h5>Input data</h5>
            <SyntaxHighlighter language="json" style={{...docco}}>
                {JSON.stringify(data.input, null, 2) || 'None'}
            </SyntaxHighlighter>
            <h5>Node params</h5>
            <SyntaxHighlighter language="json" style={{...docco}}>
                {JSON.stringify(data.params, null, 2) || 'None'}
            </SyntaxHighlighter>
            <h5>Resolved params</h5>
            <SyntaxHighlighter language="json" style={{...docco}}>
                {JSON.stringify(data.resolvedParams, null, 2) || 'None'}
            </SyntaxHighlighter>
            <h5>Output</h5>
            <SyntaxHighlighter language="json" style={{...docco}}>
                {JSON.stringify(data.output, null, 2) || 'None'}
            </SyntaxHighlighter>
            <h5>Logs</h5>
            <SyntaxHighlighter language="json" style={{...docco}}>
                {JSON.stringify(data.logs, null, 2) || 'None'}
            </SyntaxHighlighter>
            <h5>Scratchpad state</h5>
            <SyntaxHighlighter language="json" style={{...docco}}>
                {JSON.stringify(data.scratchpad, null, 2) || 'None'}
            </SyntaxHighlighter>
        </>
    );
}