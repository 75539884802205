import {RuleInterface} from "../interfaces/rule.interface";
import {RuleStatusEnum} from "../interfaces/rule-status.enum";
import {RuleSharePermissionsEnum} from "../interfaces/rule-share-permissions.enum";

export function getRuleLink(rule: RuleInterface, orgName: string): string {

    let section = 'edit';

    if (rule.status === RuleStatusEnum.ACTIVE) {
        section = 'release';
    }

    if (rule.status === RuleStatusEnum.PUBLIC) {
        section = 'public';
    }

    if (section === 'edit' && (rule.permissions && !rule.permissions?.includes(RuleSharePermissionsEnum.CAN_EDIT))) {
        section = 'view'
    }

    return `/org/${orgName}/rules/${section}/${rule.uuid}/${rule.version}`

}