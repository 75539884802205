import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface SettingsStateInterface {
    rulesViewMode: 'cards' | 'table';
    nodesViewMode: 'cards' | 'table';
}

const initialState: SettingsStateInterface = {
    rulesViewMode: 'cards',
    nodesViewMode: 'cards',
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setRulesViewMode: (state, action: PayloadAction<'cards' | 'table'>) => {
            state.rulesViewMode = action.payload;
        },
        setNodesViewMode: (state, action: PayloadAction<'cards' | 'table'>) => {
            state.nodesViewMode = action.payload;
        }
    },
});

export const {setRulesViewMode, setNodesViewMode} = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;