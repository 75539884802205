import {NavLink, Route, Routes, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {isArray, maxBy} from "lodash";
import {Dimmer, Divider, Loader, Segment, Tab} from "semantic-ui-react";
import {useFindRulesQuery, useGetRuleQuery} from "../../../../apis/rules.api";
import {RuleStatusEnum} from "../../../../interfaces/rule-status.enum";
import {RuleSortEnum} from "../../../../interfaces/rule-sort.enum";
import {RuleInterface} from "../../../../interfaces/rule.interface";
import {useAppDispatch, useAppSelector} from "../../../../store";
import {setTitle} from "../../../../slices/marketplace.slice";
import {OverviewTab} from "./OverviewTab";
import {VersionsTab} from "./VersionsTab";
import {Flow} from "../../../../components/Flow";
import {setPosition, setZoom} from "../../../../slices/flow.slice";
import {DataLoader} from "../../../rule-editor/DataLoader";

export function RulePage() {
    const {ruleUuid} = useParams();
    const [latestVersion, setLatestVersion] = useState<RuleInterface>();
    const dispatch = useAppDispatch();
    const ready = useAppSelector(state => state.editor.ready);
    const {data: rule, isFetching: isRuleLoading} = useGetRuleQuery({
        ruleUuid,
        ruleVersion: latestVersion?.version
    } as any, {skip: !latestVersion || !ruleUuid});
    const {data: rules, isFetching: isRulesLoading} = useFindRulesQuery({
        uuid: ruleUuid,
        limit: -1,
        status: RuleStatusEnum.PUBLIC,
        sort: RuleSortEnum.NEWEST,
        order: 'desc',
        groupByVersion: false
    }, {skip: !ruleUuid});

    useEffect(() => {
        if (rules?.data && isArray(rules?.data)) {
            setLatestVersion(maxBy(rules.data, 'version'));
        }
    }, [rules]);

    useEffect(() => {
        dispatch(setPosition());
        dispatch(setZoom());
    });

    return (
        <>
            <h2 style={{marginTop: '2em'}}>{rule?.name}</h2>
            {(rule && rules?.data) && (
                <Tab
                    renderActiveOnly={false}
                    activeIndex={-1}
                    menu={{secondary: true, pointing: true}}
                    panes={[
                        {
                            menuItem: {
                                as: NavLink,
                                content: 'Overview',
                                to: '',
                                end: true,
                                key: 'overview'
                            },
                            pane: (
                                <Routes>
                                    <Route index element={<OverviewTab rule={rule}/>}/>
                                </Routes>
                            )
                        },
                        {
                            menuItem: {
                                as: NavLink,
                                content: 'Algorithm',
                                to: 'algorithm',
                                key: 'algorithm'
                            },
                            pane: (
                                <Routes>
                                    <Route path="algorithm" element={(
                                        <>
                                            <Divider hidden/>
                                            <DataLoader ruleUuid={ruleUuid} ruleVersion={Number(latestVersion?.version)}/>
                                            <Segment style={{height: 'calc(100vh - 375px)'}}>
                                                {!isRuleLoading && !isRulesLoading && rule && ready && (
                                                    <Flow rule={rule} readOnly/>
                                                )}
                                            </Segment>
                                        </>
                                    )}/>
                                </Routes>
                            )
                        },
                        {
                            menuItem: {
                                as: NavLink,
                                content: 'Versions',
                                to: 'versions',
                                key: 'versions'
                            },
                            pane: (
                                <Routes>
                                    <Route path="versions" element={<VersionsTab rules={rules.data}/>}/>
                                </Routes>
                            )
                        },
                    ]}
                />
            )}
            <Dimmer active={isRuleLoading || isRulesLoading} inverted style={{backgroundColor: 'transparent', height: '100vh'}}>
                <Loader inverted content='Loading'/>
            </Dimmer>
        </>
    )
}