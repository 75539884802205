import {Button, Dimmer, Form, Loader, Message, Modal} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {useCreateNodeMutation} from "../../apis/nodes.api";
import {NodeTypeEnum} from "../../interfaces/node-type.enum";
import {useNavigate} from "react-router-dom";

export function CreateNodeModal({open, onClose}: {open: boolean; onClose?: () => void}) {

    const [createNode, {isLoading, isSuccess, isError, error, data}] = useCreateNodeMutation();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        setName('');
        setDescription('');
    }, [open])

    useEffect(() => {
        if (isSuccess) {
            onClose?.call(null);
            data?.uuid &&  navigate([data.uuid, data.version].join('/'))
        }
    }, [isSuccess]);

    return (
        <Modal closeIcon open={open} onClose={onClose} size="mini">
            <Dimmer active={isLoading} inverted><Loader /></Dimmer>
            <Modal.Header>Create new node</Modal.Header>
            <Modal.Content>
                {isError && !(error as any)?.data?.errors?.name && !(error as any)?.data?.errors?.description &&
                    <Message error content={(error as any)?.data?.error?.description || 'Unknown error'} />
                }
                <Form>
                    <Form.Input
                        autoFocus
                        label="Name"
                        type="text"
                        value={name}
                        error={(error as any)?.data?.errors?.name?.[0]}
                        onChange={(_e, {value}) => setName(value)}
                    />
                    <Form.TextArea
                        label="Description"
                        value={description}
                        error={(error as any)?.data?.errors?.description?.[0]}
                        onChange={(_e, {value}) => setDescription(value?.toString() || '')}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button primary content="Create" icon="check" onClick={() => {
                    createNode({
                        name,
                        description,
                        type: NodeTypeEnum.CUSTOM,
                        output: {
                            description: '',
                            type: null,
                            of: null,
                        },
                        params: [],
                        code: 'function(input, nodeParams, instance, flowNode) {\n\t\n}'
                    })
                }} />
            </Modal.Actions>
        </Modal>
    );
}