import {Button, Checkbox, Dimmer, Dropdown, Icon, Loader} from "semantic-ui-react";
import {useDeleteInstanceMutation, useFindInstancesQuery} from "../../../apis/instances.api";
import {useAppDispatch, useAppSelector} from "../../../store";
import {RuleInterface} from "../../../interfaces/rule.interface";
import {setCurrentInstance, setDebugData, setDebugUuid, startDebug} from "../../../slices/debug.slice";
import {useFindDebugDataQuery, useGetDebugDataQuery} from "../../../apis/instances.api";
import {useEffect, useState} from "react";
import {CreateInstanceModal} from "../../../components/instance/CreateInstanceModal";
import {InstanceInterface} from "../../../interfaces/instance.interface";
import {ConfirmDeleteModal} from "../../../components/ConfirmDeleteModal";

const sidebarStyles: any = {
    position: 'absolute',
    zIndex: 2,
    maxHeight: 'calc(100% - 40px)',
    borderRadius: 20,
    height: 'auto',
    border: 0,
    background: 'transparent',
    display: 'flex',
    left: 'calc(50% - 250px)',
    width: 500,
    bottom: 20,
    gap: 5,
    top: 'auto',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}

export function InstanceControls({rule}: {rule: RuleInterface}) {
    const {data: instances, isFetching, isSuccess, isError, error, refetch} = useFindInstancesQuery({
        ruleUuid: rule?.uuid as string,
        ruleVersion: rule?.version as number,
        limit: -1
    }, {
        skip: !rule,
        refetchOnMountOrArgChange: true
    });
    const currentInstance = useAppSelector(state => state.debug.currentInstance);
    const debugQuery = useFindDebugDataQuery({
        instanceUuid: currentInstance?.uuid as string
    }, {
        skip: !currentInstance?.uuid
    });
    const [deleteInstance, deleteInstanceResult] = useDeleteInstanceMutation();
    const debugUuid = useAppSelector(state => state.debug.debugUuid);
    const debugDataQuery = useGetDebugDataQuery(debugUuid as string, {skip: !debugUuid});
    const debugModeEnabled = useAppSelector(state => state.debug.debugModeEnabled);
    const [createInstanceOpen, setCreateInstanceOpen] = useState(false);
    const [editInstance, setEditInstance] = useState<InstanceInterface>();
    const [deleteInstanceUuid, setDeleteInstanceUuid] = useState<string>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (currentInstance && debugDataQuery.isSuccess && debugDataQuery.data) {
            dispatch(startDebug());
            dispatch(setDebugData([debugDataQuery.data]));
        }
    }, [currentInstance, debugDataQuery.isSuccess, debugDataQuery.data]);

    useEffect(() => {
        if (!debugModeEnabled) {
            dispatch(setDebugUuid(undefined));
            dispatch(setDebugData([]));
        }
    }, [debugModeEnabled]);

    return (
        <div style={sidebarStyles}>
            <Dropdown
                title="Current instance"
                trigger={
                    <Button
                        content={currentInstance?.uuid?.substring(0, 8) || 'Select instance'}
                        // basic={!currentInstance}
                        // className={!currentInstance ? "bg-white" : ""}
                        basic className="bg-white"
                        style={{width: 135}}
                        icon={currentInstance ? 'dot circle' : undefined}
                        circular color="teal"
                    />
                }
                icon={false}
                upward
            >
                <Dropdown.Menu style={{maxHeight: '50vh', overflowY: 'auto', marginBottom: 5}}>
                    <Dimmer active={isFetching} inverted><Loader size={'small'} /></Dimmer>
                    <Dropdown.Item key={`instance--1`} selected={!currentInstance} onClick={() => dispatch(setCurrentInstance(undefined))}>
                        No instance
                    </Dropdown.Item>
                    {instances?.data?.map((instance, i) => (
                        <Dropdown.Item key={`instance-${i}`} selected={currentInstance?.uuid === instance.uuid} onClick={() => dispatch(setCurrentInstance(instance))}>
                            <b>{instance.uuid.substring(0, 8)}</b>: {new Date(instance.createdAt).toLocaleString()} <br />
                            <p style={{fontSize: 12, opacity: 0.8, marginTop: 5, marginBottom: 0}}>
                                <span style={{whiteSpace: "pre-wrap", wordBreak: 'break-word'}}>Trigger&nbsp;params:&nbsp;{JSON.stringify(instance.triggerParams) || 'None'}</span><br />
                                <span style={{whiteSpace: "pre-wrap", wordBreak: 'break-word'}}>Rule&nbsp;params:&nbsp;{JSON.stringify(instance.ruleParams) || 'None'}</span>
                            </p>
                        </Dropdown.Item>
                    ))}
                    <Dropdown.Item onClick={() => setCreateInstanceOpen(true)}>
                        <Icon name="add" /> Create instance
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown
                title="Latest executions"
                disabled={!currentInstance}
                trigger={
                        <Button basic content={debugUuid ? debugUuid.substring(0, 8) : "Executions"}
                                style={{width: 135}}
                                icon={debugUuid ? 'bug' : undefined}
                                className="bg-white" circular color="teal"
                        />
                }
                icon={false}
            >
                <Dropdown.Menu style={{maxHeight: '50vh', overflowY: 'auto', marginBottom: 5, minWidth: 135}}>
                    <Dropdown.Header>
                        <Button size="mini" basic color="teal" circular content="Reload" icon="refresh"
                            loading={debugQuery.isFetching}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                debugQuery.refetch();
                            }}
                        />
                    </Dropdown.Header>
                    {debugQuery.data?.data?.map((instanceRun, i) => (
                        <Dropdown.Item
                            key={`debug-data-${i}`}
                            selected={debugUuid === instanceRun.uuid}
                            onClick={() => dispatch(setDebugUuid(instanceRun.uuid))}
                        >
                            <b>{instanceRun.uuid.substring(0, 8)}</b>: {new Date(instanceRun.startedAt).toLocaleString()} <br />
                            <p style={{fontSize: 12, opacity: 0.8, marginTop: 5, marginBottom: 0}}>
                                Duration: {instanceRun.duration}
                            </p>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <Button
                title="Edit instance"
                disabled={!currentInstance}
                icon="edit" circular color="teal" basic className="bg-white"
                onClick={() => setEditInstance(currentInstance)}
            />
            <Button
                title="Delete instance"
                disabled={!currentInstance}
                icon="trash" circular color="teal" basic className="bg-white"
                onClick={() => setDeleteInstanceUuid(currentInstance?.uuid)}
            />

            <CreateInstanceModal
                ruleUuid={rule.uuid}
                ruleVersion={rule.version}
                open={createInstanceOpen || !!editInstance}
                edit={editInstance}
                onClose={() => {
                    setCreateInstanceOpen(false);
                    setEditInstance(undefined);
                }}
            />

            {deleteInstanceUuid && (
                <ConfirmDeleteModal
                    open={true}
                    onYes={() => {
                        deleteInstance(deleteInstanceUuid);
                        setDeleteInstanceUuid(undefined);
                        dispatch(setCurrentInstance(undefined));
                    }}
                    onNo={() => setDeleteInstanceUuid(undefined)}
                    title={'Delete instance?'}
                    text={'Do you want to delete this rule instance?'}
                />
            )}
        </div>
    )
}