import {Button, Dimmer, Loader, Message, Modal} from "semantic-ui-react";
import {FillTriggerParamsForm} from "../trigger/FillTriggerParamsForm";
import {FillParamsForm} from "../rule/FillParamsForm";
import {cloneElement, ReactElement, useEffect, useState} from "react";
import {useCreateInstanceMutation, useUpdateInstanceMutation} from "../../apis/instances.api";
import {InstanceInterface} from "../../interfaces/instance.interface";
import {isBoolean} from "lodash";
import {useFindTriggersQuery} from "../../apis/triggers.api";
import {ParamInterface} from "../../interfaces/param.interface";
import {extractTriggerParams} from "../../helpers/params";
import {useGetRuleQuery} from "../../apis/rules.api";
import {useAppSelector} from "../../store";

export function CreateInstanceModal({ruleUuid, ruleVersion, children, edit, open, onClose}: {
    ruleUuid?: string;
    ruleVersion?: number;
    children?: ReactElement;
    edit?: InstanceInterface;
    open?: boolean;
    onClose?: () => void;
}) {
    const {data: rule, isFetching} = useGetRuleQuery({ruleUuid, ruleVersion} as any);
    const [createInstance, createInstanceResult] = useCreateInstanceMutation();
    const [updateInstance, updateInstanceResult] = useUpdateInstanceMutation();
    const triggers = useAppSelector(state => state.editor.triggers);
    const [isOpen, setIsOpen] = useState(open || false);
    const [formData, setFormData] = useState<any>({
        triggerParams: edit?.triggerParams || {},
        ruleParams: edit?.ruleParams || {},
    });
    const triggerParams: ParamInterface[] = triggers && rule ? extractTriggerParams(rule, triggers) : [];

    function submit() {
        if (edit) {
            updateInstance({
                ruleInstanceUuid: edit.uuid,
                ...formData
            });
        } else if (rule) {
            createInstance({
                ruleUuid: rule.uuid,
                ruleVersion: rule.version,
                ...formData
            });
        }
    }

    useEffect(() => {
        edit && setFormData({
            triggerParams: edit.triggerParams,
            ruleParams: edit.ruleParams,
        });
    }, [edit]);

    useEffect(() => {
        isBoolean(open) && setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (createInstanceResult.isSuccess || updateInstanceResult.isSuccess) {
            onClose && onClose();
            setIsOpen(false);
        }
    }, [createInstanceResult.isSuccess, updateInstanceResult.isSuccess]);

    return (
        <>
            {children && cloneElement(children, {onClick: () => setIsOpen(true)})}
            <Modal size="small" closeIcon onClose={() => {
                onClose && onClose();
                setIsOpen(false);
            }} open={isOpen} style={{marginBottom: 50}}>
                <Dimmer inverted
                        active={createInstanceResult.isLoading || updateInstanceResult.isLoading || isFetching}>
                    <Loader inverted/>
                </Dimmer>
                <Modal.Header>{edit ? 'Update' : 'Create'} rule instance</Modal.Header>
                {rule && (
                    <>
                        <Modal.Content scrolling>
                            {edit?.uuid && (
                                <p>Instance UUID: <b>{edit.uuid}</b></p>
                            )}
                            <h4>Trigger params</h4>
                            {triggerParams.length > 0 ? (
                                <FillTriggerParamsForm
                                    rule={rule}
                                    value={formData.triggerParams}
                                    onChange={(values) => {
                                        setFormData({
                                            ...formData,
                                            triggerParams: values
                                        })
                                    }}
                                />
                            ) : <p>Rule has no trigger params</p>}
                            <h4>Rule params</h4>
                            {rule.params?.length > 0 ? (
                                <FillParamsForm
                                    rule={rule}
                                    value={formData.ruleParams}
                                    onChange={(values) => {
                                        setFormData({
                                            ...formData,
                                            ruleParams: values
                                        })
                                    }}
                                />
                            ) : <p>Rule has no any params</p>}
                        </Modal.Content>
                        <Modal.Actions>
                            {createInstanceResult.isError && <Message error style={{textAlign: 'left'}}
                                                                      content={(createInstanceResult.error as any)?.data?.error?.description || 'Unknown error'}/>}
                            {updateInstanceResult.isError && <Message error style={{textAlign: 'left'}}
                                                                      content={(updateInstanceResult.error as any)?.data?.error?.description || 'Unknown error'}/>}
                            <Button primary content="Save" onClick={() => submit()}/>
                        </Modal.Actions>
                    </>
                )}
            </Modal>
        </>
    );
}