export enum ParamTypeEnum {
    ARRAY = 'array',
    OBJECT = 'object',
    ENUM = 'enum',
    STRING = 'string',
    TEXT = 'text',
    INT = 'int',
    DOUBLE = 'double',
    BOOL = 'bool',
    DATETIME = 'datetime',
    TIME = 'time',
    DATE = 'date',
    EMAIL = 'email',
    PHONE_NUMBER = 'phone_number',
    JS_CODE = 'code_javascript',
    ANY = 'any',
    UUID = 'uuid'
}