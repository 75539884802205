import {Button, Divider, Grid, Header, Icon, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {logOut} from "../../slices/auth.slice";
import {useAppDispatch} from "../../store";

export function Forbidden({canBackToHomepage = true}) {
    const dispatch = useAppDispatch();

    return (
        <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
            <Grid.Column style={{maxWidth: 450}}>
                <Segment style={{padding: '2em'}}>
                    <Header as='h2' color='teal' textAlign='center'>
                        <Icon name="warning circle"/>
                        Access denied
                    </Header>
                    <p>You are not authorized to access this page.<br/>Contact your administrator.</p>
                    <Divider hidden />
                    <Button primary onClick={() => {
                        dispatch(logOut());
                        window.location.href = process.env.REACT_APP_BASE_URL + '/sso/auth';
                    }}>
                        Log into another account
                    </Button>
                    <div style={{margin: '1em'}}>or</div>
                    <Button basic as={Link} to={'/'} content={'Back to homepage'}/>
                </Segment>
            </Grid.Column>
        </Grid>
    )
}