import {Card, Table} from "semantic-ui-react";
import {RuleInterface} from "../../../interfaces/rule.interface";
import {useState} from "react";
import {CreateRuleModal} from "../../../components/rule/CreateRuleModal";
import {useDeleteRuleMutation} from "../../../apis/rules.api";
import {ConfirmDeleteModal} from "../../../components/ConfirmDeleteModal";
import {RuleListCardItem} from "./RuleListCardItem";
import {RuleListTableRow} from "./RuleListTableRow";

export function RulesList({rules, view}: {
    rules: RuleInterface[];
    view?: 'table' | 'cards'
}) {
    const [createModalOpened, setCreateModalOpened] = useState(false);
    const [deleteModalOpened, setDeleteModalOpened] = useState(false)
    const [activeRule, setActiveRule] = useState<RuleInterface>();
    const [deleteRule] = useDeleteRuleMutation();

    return (
        <>
            {view === 'table' ? (
                rules.length > 0 && (
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={1}>Arn</Table.HeaderCell>
                                <Table.HeaderCell width={4}>Name</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Category / tags</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Instance count</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Created at / created by</Table.HeaderCell>
                                <Table.HeaderCell width={2} textAlign={"right"}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {rules.map((rule, index) => (
                                <RuleListTableRow
                                    key={`rule-table-item-${index}`}
                                    rule={rule}
                                    onDelete={(rule) => {
                                        setActiveRule(rule);
                                        setDeleteModalOpened(true);
                                    }}
                                />
                            ))}
                        </Table.Body>
                    </Table>
                )
            ) : (
                <Card.Group itemsPerRow={3} stackable doubling>
                    {rules.map((rule, index) => (
                        <RuleListCardItem
                            key={`rule-card-${index}`}
                            rule={rule}
                            onDelete={(rule) => {
                                setActiveRule(rule);
                                setDeleteModalOpened(true);
                            }}
                        />
                    ))}
                </Card.Group>
            )}

            {createModalOpened && <CreateRuleModal open onClose={() => setCreateModalOpened(false)}/>}

            <ConfirmDeleteModal
                open={deleteModalOpened}
                onYes={() => {
                    activeRule && deleteRule({
                        ruleUuid: activeRule.uuid,
                        ruleVersion: activeRule.version,
                    });
                    setDeleteModalOpened(false);
                }}
                onNo={() => setDeleteModalOpened(false)}
            />
        </>
    )
}