import {RuleNodeInterface, RuleNodeTypeEnum} from "../interfaces/rule-node.interface";
import {Handle, Position} from "reactflow";
import {memo} from "react";
import {Button, Icon, Loader} from "semantic-ui-react";
import {useParams} from "react-router-dom";
import {useGetRuleQuery} from "../apis/rules.api";
import {FireInstanceModal} from "./instance/FireInstanceModal";
import {useAppSelector} from "../store";
import {Hint} from "./Hint";
import './FlowNode.scss';

export const FlowNode = memo(({data, isConnectable, type}: {
    data: {
        data: RuleNodeInterface
    };
    isConnectable: boolean;
    type: string;
}) => {
    const {ruleUuid, ruleVersion} = useParams();
    const nodes = useAppSelector(state => state.editor.nodes);
    const triggers = useAppSelector(state => state.editor.triggers);
    const currentInstance = useAppSelector(state => state.debug.currentInstance);
    const {data: activeRule} = useGetRuleQuery({ruleUuid, ruleVersion: parseInt(ruleVersion as string)} as any, {skip: !ruleUuid});
    const isInput = type === RuleNodeTypeEnum.INPUT || type === RuleNodeTypeEnum.TRIGGER;
    let originalNode: any = [...nodes, ...triggers].find(item => item.uuid === data.data.nodeUuid);

    return (
        <>
            {!isInput && <Handle
                type="target"
                position={Position.Top}
                style={{background: '#555'}}
                isConnectable={isConnectable}
            />}
            <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                {(!originalNode) ? (
                    <Loader size="mini" inline active />
                ) : (
                    <>
                        <div style={{lineHeight: 1.2}}>
                            <div>
                                {originalNode?.name}
                                {data.data.nodeVersion === 0 && <Hint
                                    trigger={<Icon style={{marginLeft: 5}} name="warning sign" color="orange" title="You are using development version of this node" />}
                                    content="You are using development version of this node"
                                    position={'top center'}
                                />}
                            </div>
                            {isInput && <div style={{fontSize: '80%', opacity: 0.5, marginTop: '0.25em', wordBreak: 'break-all'}}>
                                {data.data.description || originalNode?.event
                                    ?.replace('{instanceUuid}', currentInstance?.uuid || '{instanceUuid}')
                                    .replace(/\<(.*?)\>/g, (match: string, contents: string) => {
                                        return currentInstance?.triggerParams?.[contents] || match;
                                    })
                                }
                            </div>}
                            {!isInput && data.data.description && <div style={{fontSize: '80%', opacity: 0.5, marginTop: '0.25em'}}>{data.data.description}</div>}
                        </div>
                        {isInput && activeRule && originalNode && (
                            <>
                                {currentInstance ? (
                                    <FireInstanceModal instance={currentInstance} triggerUuid={originalNode.uuid}>
                                        <Button icon="play" size="mini" color="teal" circular />
                                    </FireInstanceModal>
                                ) : (
                                    <Hint
                                        trigger={
                                            <Button icon="play" size="mini" color="teal" circular style={{opacity: 0.5}}
                                                onMouseDownCapture={(e: any) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            />
                                        }
                                        content="Select or create rule instance first"
                                        position="right center"
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
            <Handle
                type="source"
                position={Position.Bottom}
                // id="a"
                style={{background: '#555'}}
                isConnectable={isConnectable}
            />
            {/*<Handle*/}
            {/*    type="source"*/}
            {/*    position={Position.Left}*/}
            {/*    id="b"*/}
            {/*    style={{ background: '#555' }}*/}
            {/*    isConnectable={isConnectable}*/}
            {/*/>*/}
            {/*<Handle*/}
            {/*    type="source"*/}
            {/*    position={Position.Right}*/}
            {/*    id="c"*/}
            {/*    style={{ background: '#555' }}*/}
            {/*    isConnectable={isConnectable}*/}
            {/*/>*/}
            {/*<Handle*/}
            {/*  type="source"*/}
            {/*  position="right"*/}
            {/*  id="b"*/}
            {/*  style={{ bottom: 10, top: 'auto', background: '#555' }}*/}
            {/*  isConnectable={isConnectable}*/}
            {/*/>*/}
        </>
    );
})

//
// export function FlowNode({node, index}: {
//     node: RuleNodeInterface;
//     index?: number;
// }) {
//     const nodes = useAppSelector(state => state.nodes.list);
//     const originalNode = nodes.find(item => item.uuid === node.nodeUuid);
//
//     if (!originalNode) {
//         return <div>&mdash;</div>
//     }
//
//     return (
//         <div>
//             {originalNode.name}
//         </div>
//     );
// }