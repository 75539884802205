import {useFindRuleSharesQuery} from "../../../apis/share.api";
import {Button, Label} from "semantic-ui-react";
import {ShareRuleModal} from "../../../components/rule/ShareRuleModal";
import {RuleInterface} from "../../../interfaces/rule.interface";
import {isArray} from "lodash";

export function ShareRuleButton({rule}: {
    rule: RuleInterface
}) {
    const existsOrgs = useFindRuleSharesQuery({
        ruleUuid: rule.uuid,
        ruleVersion: rule.version,
    }, {
        skip: isArray(rule.permissions) // skip if rule is shared
    });

    if (isArray(rule.permissions)) {
        return <></>;
    }

    return (
        <ShareRuleModal rule={rule}>
            <Button
                title="Share rule"
                icon="users"
                size="huge"
                color="teal"
                circular
                basic
                className="bg-white btn-share icon"
                style={{position: 'relative'}}
                content={
                    (existsOrgs.data?.data?.length || 0) > 0 && (
                        <Label
                            color='teal'
                            circular
                            floating
                            style={{
                                top: 'auto',
                                bottom: '-0.75em'
                            }}>
                            {existsOrgs.data?.data?.length}
                        </Label>
                    )}
            />
        </ShareRuleModal>
    )
}