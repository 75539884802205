import {GlobalEventInterface} from "../../interfaces/global-event.interface";
import {Tab, Table} from "semantic-ui-react";
import SyntaxHighlighter from "react-syntax-highlighter/dist/cjs/light";
import {xcode} from "react-syntax-highlighter/dist/esm/styles/hljs";
import json from "react-syntax-highlighter/dist/esm/languages/hljs/json";

SyntaxHighlighter.registerLanguage('json', json);

export function EventDetails({event}: {event: GlobalEventInterface}) {

    return (
        <Tab
            panes={[
                {
                    menuItem: 'General',
                    render: () => <Tab.Pane style={{paddingLeft: '1.5em'}}>
                        <Table celled basic="very">
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={3}>Name</Table.Cell>
                                    <Table.Cell>{event.title}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Event</Table.Cell>
                                    <Table.Cell>{event.event}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Source</Table.Cell>
                                    <Table.Cell>{event.source}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <h4>Event params</h4>
                        <Table celled basic="very">
                            <Table.Header>
                                <Table.HeaderCell width={3}>Name</Table.HeaderCell>
                                <Table.HeaderCell>Description</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                                {Object.keys(event.params).map((key) => (
                                    <Table.Row>
                                        <Table.Cell>{key}</Table.Cell>
                                        <Table.Cell>{event.params[key]}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </Tab.Pane>
                }, {
                    menuItem: 'Schema',
                    render: () => (
                        <Tab.Pane>
                            <SyntaxHighlighter language="json" style={{...xcode}}>
                                {JSON.stringify(event.schema, null, 2)}
                            </SyntaxHighlighter>
                        </Tab.Pane>
                    )
                }, {
                    menuItem: 'Data example',
                    render: () => <Tab.Pane>
                        <SyntaxHighlighter language="json" style={{...xcode}}>
                            {JSON.stringify(event.dataExample, null, 2)}
                        </SyntaxHighlighter>
                    </Tab.Pane>
                }
            ]}
        />
    );
}