import {Card, Dimmer, Divider, Header as SemanticHeader, Loader, Segment} from "semantic-ui-react";
import {useGetNodeQuery} from "../../apis/nodes.api";
import {useParams} from "react-router-dom";
import {Layout} from "../../components/Layout";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Editor} from "./Editor";
import {DebugWindow} from "./DebugWindow";
import SyntaxHighlighter from "react-syntax-highlighter/dist/cjs/light";
import typescript from "react-syntax-highlighter/dist/esm/languages/hljs/typescript";
import {useEffect} from "react";
import {resetEditor} from "../../slices/nodes.slice";
import {useAppDispatch} from "../../store";
import {Header} from "./Header";
import {NodeInfo} from "../../components/NodeInfo";

SyntaxHighlighter.registerLanguage('typescript', typescript);

export function NodeViewer() {
    const {nodeUuid, nodeVersion} = useParams();
    const {data: node, refetch} = useGetNodeQuery({
        nodeUuid,
        nodeVersion: parseInt(nodeVersion || '')
    } as any, {skip: !nodeUuid || !nodeVersion})
    const dispatch = useAppDispatch();

    useEffect(() => {
        refetch();

        return () => {
            dispatch(resetEditor());
        }
    }, []);

    return (
        <Layout
            headerContent={<Header/>}
            leftSidebarContent={(
                <PerfectScrollbar>
                    <Segment basic>
                        <SemanticHeader style={{marginTop: '0.25em'}}>
                            Node info
                        </SemanticHeader>
                        <Divider/>
                        <Card.Content style={{paddingTop: '1em'}}>
                            <NodeInfo node={node} basic/>
                        </Card.Content>
                    </Segment>
                </PerfectScrollbar>
            )}
            mainContent={(
                node ? (
                    <div style={{display: 'flex', height: 'calc(100% + 2em)', flexDirection: 'column', margin: '-1em'}}>
                        <Editor node={node} readonly/>
                        <DebugWindow/>
                    </div>
                ) : (
                    <Dimmer active inverted><Loader/></Dimmer>
                )
            )}
        />
    )
}