import {useParams} from "react-router-dom";
import {useGetRuleQuery} from "../../apis/rules.api";
import {UserMenu} from "../../components/UserMenu";
import {Logo} from "../../components/Logo";

export function Header({muted, debugModeEnabled}: {muted?: boolean, debugModeEnabled?: boolean}) {
    const {ruleUuid, ruleVersion} = useParams();
    const {data: activeRule} = useGetRuleQuery({
        ruleUuid,
        ruleVersion: parseInt(ruleVersion as string),
        getDependencies: true,
        getPermissions: true
    } as any, {
        skip: !ruleUuid
    });

    return (
        <div className={`app-header ${muted ? 'muted' : ''}`}>
            <Logo to={'..'}/>
            {activeRule && (
                <h3 style={{flex: '1 1 0', textAlign: 'center', margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {activeRule.name}
                </h3>
            )}
            <div style={{display: 'flex', gap: '1em', alignItems: 'center', justifyContent: 'end', width: 300}}>
                <UserMenu />
            </div>
        </div>
    );
}