import {useEffect, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useAppDispatch, useAppSelector} from "../../store";
import {Button, Segment, Header, Divider} from "semantic-ui-react";
import { stopDebug} from "../../slices/debug.slice";
import {RuleNodeInterface} from "../../interfaces/rule-node.interface";
import {NodeInterface} from "../../interfaces/node.interface";
import {DebugDataItemInterface} from "../../interfaces/debug-data.interface";
import {DebugNode} from "./DebugNode";
import {TriggerInterface} from "../../interfaces/trigger.interface";

export function Debug({showHeader = true}: {showHeader?: boolean}) {
    const debugData = useAppSelector((state) => state.debug.debugData);
    const selectedIteration = useAppSelector((state) => state.debug.selectedIteration);
    const debugNodeUuid = useAppSelector((state) => state.debug.debugNodeUuid);
    const nodes = useAppSelector(state => state.editor.nodes);
    const triggers = useAppSelector(state => state.editor.triggers);
    const flow = useAppSelector((state) => state.flow.flow);
    const [debugNode, setDebugNode] = useState<DebugDataItemInterface | undefined>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setDebugNode(debugNodeUuid && debugData[selectedIteration] ? debugData[selectedIteration][debugNodeUuid][0] : undefined);
    }, [debugNodeUuid, selectedIteration]);

    function getNode(nodeUuid: string): NodeInterface | TriggerInterface | undefined {
        const ruleNode = getRuleNode(nodeUuid);
        return [...nodes, ...triggers].find((item) => item.uuid === ruleNode?.nodeUuid);
    }

    function getRuleNode(uuid: string): RuleNodeInterface | undefined {
        return flow.find((node) => node.uuid === uuid);
    }

    return (
        <PerfectScrollbar  style={{flex: 1}}>
            <Segment basic style={{flex: 1}}>
                {showHeader && (
                    <>
                        <Header style={{marginTop: '0.25em'}}>
                            Debug
                            <Button className="close-btn" icon="close" onClick={() => dispatch(stopDebug())}/>
                        </Header>
                        <Divider/>
                    </>
                )}
                {
                    debugNodeUuid ? (
                        <DebugNode data={debugNode} node={getNode(debugNodeUuid)}/>
                    ) : (
                        <>
                            <p>
                                Select node to see debug data
                            </p>
                            {/*<h4>Iterations</h4>*/}
                            {/*{debugData.map((item, index) => (*/}
                            {/*    <p>*/}
                            {/*        <Button*/}
                            {/*            basic={index !== selectedIteration}*/}
                            {/*            color="grey"*/}
                            {/*            content={`Iteration #${index + 1}`} fluid*/}
                            {/*            onClick={() => dispatch(setDebugIteration(index))}*/}
                            {/*        />*/}
                            {/*    </p>*/}
                            {/*))}*/}
                        </>
                    )
                }
            </Segment>
        </PerfectScrollbar>
    );
}