import {createApi} from '@reduxjs/toolkit/query/react';
import {RuleInterface} from "../interfaces/rule.interface";
import {baseQuery} from "../helpers/base-query";
import {TestRuleInterface} from "../interfaces/test-rule.interface";
import {CreateRuleInterface} from "../interfaces/create-rule.interface";
import {CommitRuleInterface} from "../interfaces/commit-rule.interface";
import {RuleStatusEnum} from "../interfaces/rule-status.enum";
import {RuleSortEnum} from "../interfaces/rule-sort.enum";
import {PaginationInterface} from "../interfaces/pagination.interface";
import {PublishRuleInterface} from "../interfaces/publish-rule.interface";

export const rulesApi = createApi({
    reducerPath: 'rulesApi',
    baseQuery: baseQuery,
    tagTypes: ['Rules',  'Rule'],
    endpoints: (builder) => ({
        findRules: builder.query<{
            data: RuleInterface[];
            pagination: PaginationInterface;
        }, {
            name?: string;
            status?: RuleStatusEnum;
            createdBy?: number;
            orgId?: number;
            page?: number;
            limit?: number;
            sort?: RuleSortEnum;
            order?: 'asc' | 'desc';
            groupByVersion?: boolean;
            uuid?: string;
        }>({
            query: (params) => ({
                url: 'rules',
                params
            }),
            providesTags: ['Rules']
        }),

        getRule: builder.query<RuleInterface, {
            ruleUuid: string;
            ruleVersion: number;
            getDependencies?: boolean;
            getPermissions?: boolean;
        }>({
            query: ({ruleUuid, ruleVersion, ...params}) => ({
                url: `rules/${ruleUuid}/${ruleVersion}`,
                params
            }),
            providesTags: ['Rule']
        }),

        createRule: builder.mutation<RuleInterface, CreateRuleInterface>({
            query: (body) => ({
                url: `rules`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Rules'],
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                const { data: createdRule } = await queryFulfilled;
                dispatch(
                    rulesApi.util.updateQueryData('findRules', {}, (draft) => {
                        draft.data.unshift(createdRule);
                    })
                );
            }
        }),

        commitRule: builder.mutation<RuleInterface, CommitRuleInterface>({
            query: (body) => ({
                url: `rules/commit`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Rules'],
            async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
                const { data: commited } = await queryFulfilled;

                for (const { endpointName, originalArgs } of rulesApi.util.selectInvalidatedBy(getState(), ['Rule'])) {
                    if (
                        endpointName === 'getRule' &&
                        originalArgs.ruleUuid === commited.uuid &&
                        originalArgs.ruleVersion === commited.version
                    ) {
                        dispatch(
                            rulesApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
                                Object.assign(draft, commited);
                            })
                        )
                    }
                }
            }
        }),

        publishRule: builder.mutation<RuleInterface, PublishRuleInterface>({
            query: (body) => ({
                url: `rules/publish`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Rules'],
            async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
                const { data: poblished } = await queryFulfilled;

                for (const { endpointName, originalArgs } of rulesApi.util.selectInvalidatedBy(getState(), ['Rule'])) {
                    if (
                        endpointName === 'getRule' &&
                        originalArgs.ruleUuid === poblished.uuid &&
                        originalArgs.ruleVersion === poblished.version
                    ) {
                        dispatch(
                            rulesApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
                                Object.assign(draft, poblished);
                            })
                        )
                    }
                }
            }
        }),

        updateRule: builder.mutation<RuleInterface, Partial<Pick<RuleInterface, 'name' | 'description' | 'status' | 'params' | 'flow' | 'category' | 'tags'>> & Pick<RuleInterface, 'uuid'> & Pick<RuleInterface, 'version'> & {triggers?: string[]}>({
            query: ({uuid, version, ...body}) => ({
                url: `rules/${uuid}/${version}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Rules'],
            async onQueryStarted({ uuid, version, ...patch }, { dispatch, queryFulfilled, getState }) {
                const { data: updatedRule } = await queryFulfilled;

                for (const { endpointName, originalArgs } of rulesApi.util.selectInvalidatedBy(getState(), ['Rule'])) {
                    if (
                        endpointName === 'getRule' &&
                        originalArgs.ruleUuid === updatedRule.uuid &&
                        originalArgs.ruleVersion === updatedRule.version
                    ) {
                        dispatch(
                            rulesApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
                                Object.assign(draft, updatedRule);
                            })
                        )
                    }
                }
            },
        }),

        deleteRule: builder.mutation<void, {ruleUuid: string; ruleVersion: number}>({
            query: ({ruleUuid, ruleVersion}) => ({
                url: `rules/${ruleUuid}/${ruleVersion}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Rules'],
        }),

        testRule: builder.mutation<any, TestRuleInterface>({
            query: (body) => ({
                url: `rules/test`,
                method: 'PATCH',
                body
            }),
        })
    }),
})

export const {useFindRulesQuery, useGetRuleQuery, useCreateRuleMutation, useUpdateRuleMutation, useDeleteRuleMutation, useTestRuleMutation, useCommitRuleMutation, usePublishRuleMutation} = rulesApi;