import {Divider} from "semantic-ui-react";
import {RuleInterface} from "../../../interfaces/rule.interface";
import {VersionsList} from "../../../components/rule/VersionsList";

export function VersionsTab({rules}: {rules: RuleInterface[]}) {
    return (
        <>
            <Divider hidden/>
            <VersionsList rules={rules} asDeveloper/>
        </>
    );
}