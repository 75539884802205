import {ChangeEvent, ReactElement, useEffect, useState} from "react";
import {Button, Dimmer, Form, Input, Loader, Message, Modal, Select, TextArea} from "semantic-ui-react";
import {ParamInterface} from "../../interfaces/param.interface";
import {cloneDeep, get, isNumber, size} from "lodash";
import {floatify} from "../../helpers/common";
import {paramTypes} from "../../helpers/params";

export function AddParam({children, params, onSubmit, editIndex, isError, isSuccess, isLoading, requiredField, error}: {
    children: ReactElement;
    params: ParamInterface[];
    onSubmit?: (param: ParamInterface, params: ParamInterface[]) => void;
    editIndex?: number;
    isError?: boolean;
    isLoading?: boolean;
    isSuccess?: boolean;
    requiredField?: boolean;
    error?: any
}) {
    const initialState: any = {
        title: '',
        name: '',
        description: '',
        type: [],
        of: undefined,
        enum: [],
        required: true
    };
    const [formData, setFormData] = useState<ParamInterface>(isNumber(editIndex) && params[editIndex] ? params[editIndex] : cloneDeep(initialState));
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        let data = isNumber(editIndex) && params[editIndex] ? params[editIndex] : cloneDeep(initialState);
        setFormData({
            ...data,
            required: data.required || true,
        });
    }, [modalOpen]);

    useEffect(() => {
        isSuccess && setModalOpen(false);
    }, [isSuccess]);

    function save() {
        let newParams = cloneDeep(params);
        if (isNumber(editIndex)) {
            newParams[editIndex] = formData;
        } else {
            newParams.push(formData);
        }

        onSubmit && onSubmit(formData, newParams);
    }

    function handleChange(e: ChangeEvent<any>, data: any) {
        setFormData({
            ...formData,
            [data.name]: data.value
        })
    }

    function handeEnumChange(index: number, name: 'value' | 'title', value: string) {
        if (!formData.enum?.[index]) {
            return;
        }
        const items = cloneDeep(formData.enum || []);
        items[index][name] = floatify(value);

        setFormData({...formData, enum: items});
    }

    function addEnum() {
        const items = cloneDeep(formData.enum || []);
        items.push({
            title: '',
            value: ''
        });
        setFormData({...formData, enum: items});
    }

    function removeEnum(index: number) {
        const items = cloneDeep(formData.enum || []);
        items.splice(index, 1);
        setFormData({...formData, enum: items});
    }

    function getError(field: string) {
        return get(error, `data.errors['params.${editIndex}.${field}'][0]`)?.replace(`params[${editIndex}].`, '');
    }

    function getEnumError(index: number, field: string) {
        return get(error, `data.errors['params.${editIndex}.enum.${index}.${field}'][0]`)?.replace(`params[${editIndex}].enum[${index}].`, '');
    }

    return (
        <>
            <span onClick={() => setModalOpen(true)}>{children}</span>
            <Modal open={modalOpen} size="small" closeIcon onClose={() => setModalOpen(false)}>
                <Modal.Header>{isNumber(editIndex) ? 'Edit' : 'Add'} rule param</Modal.Header>
                <Modal.Content>
                    {isError && !size(error?.data?.errors) && <Message error content={error?.data?.error?.description || 'Unknown error'} />}
                    <Dimmer active={isLoading} inverted><Loader inverted /></Dimmer>
                    <Form>
                        <Form.Input
                            label="Title"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            error={getError('title')}
                            required
                        />
                        <Form.Input
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            error={getError('name')}
                            required
                        />
                        <Form.Input
                            label="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            error={getError('description')}
                            required
                        />
                        <Form.Select
                            label="Type"
                            options={paramTypes}
                            name="type"
                            value={formData.type}
                            onChange={handleChange}
                            error={getError('type')}
                            multiple
                            closeOnChange
                            required
                        />
                        <Form.Select
                            label="Of"
                            options={paramTypes}
                            name="of"
                            value={formData.of}
                            onChange={handleChange}
                            error={getError('of')}
                            closeOnChange
                        />
                        <Form.Field>
                            <label>Enum</label>
                            {formData.enum?.map((item, index) => (
                                <Form.Group widths="equal" key={index} style={{alignItems: 'start'}}>
                                    <Form.Input
                                        placeholder="Value"
                                        name="value"
                                        value={item.value}
                                        error={getEnumError(index, 'value')}
                                        onChange={(e, data) => handeEnumChange(index, data.name, data.value)}
                                        required
                                    />
                                    <Form.Input
                                        placeholder="Title"
                                        name="title"
                                        value={item.title}
                                        error={getEnumError(index, 'title')}
                                        onChange={(e, data) => handeEnumChange(index, data.name, data.value)}
                                        required
                                    />
                                    <Button size="mini" icon="minus" style={{height: '3.5em'}} onClick={() => removeEnum(index)} />
                                </Form.Group>
                            ))}
                        </Form.Field>
                        <Button icon="plus" onClick={() => addEnum()} />
                        {requiredField && (
                            <Form.Checkbox
                                label="Value is required"
                                name="required"
                                checked={formData.required}
                                onChange={(e: ChangeEvent<any>, data: any) => {
                                    setFormData({
                                        ...formData,
                                        [data.name]: data.checked
                                    })
                                }}
                                error={getError('required')}
                                required
                                style={{marginTop: '1.5em'}}
                            />
                        )}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="teal" onClick={() => save()} content={isNumber(editIndex) ? 'Save' : 'Add'} icon="check"/>
                </Modal.Actions>
            </Modal>
        </>
    )
}