import {Button, Label, Table} from "semantic-ui-react";
import {Arn} from "../../../components/Arn";
import {Link, useParams} from "react-router-dom";
import {RuleStatusEnum} from "../../../interfaces/rule-status.enum";
import {RuleInterface} from "../../../interfaces/rule.interface";
import {useAppSelector} from "../../../store";
import {RuleSettingsModal} from "../../../components/rule/RuleSettingsModal";
import {getRuleLink} from "../../../helpers/rules";
import {RuleSharePermissionsEnum} from "../../../interfaces/rule-share-permissions.enum";

export function RuleListTableRow({rule, onDelete}: {
    rule: RuleInterface;
    onDelete: (rule: RuleInterface) => void;
}) {
    const {organization} = useParams();
    const orgId = useAppSelector(state => state.auth.orgId);

    return (
        <Table.Row>
            <Table.Cell>
                <Arn arn={rule.arn} secondary text={false} />
            </Table.Cell>
            <Table.Cell>
                <Link to={getRuleLink(rule, organization as string)}>
                    {rule.name}
                </Link>
                <p style={{opacity: 0.5, marginBottom: 0}}>{rule.description}</p>
                {rule.orgId !== orgId && (
                    <div style={{marginTop: 5}}>
                        <Label size={'small'} color={'teal'} basic circular icon={'users'} content={`Shared by org ${rule.orgId}`} />
                        {!!rule.permissions && !rule.permissions.includes(RuleSharePermissionsEnum.CAN_EDIT) && (
                            <Label size={'small'} color={'teal'} basic circular content={`Readonly`} />
                        )}
                    </div>
                )}
            </Table.Cell>
            <Table.Cell>
                {rule.category || <>&mdash;</>}
                {(rule.tags?.length || 0) > 0 && (
                    <p style={{opacity: 0.5, marginBottom: 0}}>#{rule.tags?.join(', #')}</p>
                )}
            </Table.Cell>
            <Table.Cell>
                {rule.status === RuleStatusEnum.DRAFT && <Label color="orange" basic content="Draft"/>}
                {rule.status === RuleStatusEnum.ACTIVE &&
                    <Label color="blue" content={`Version: ${rule.version}`}/>}
                {rule.status === RuleStatusEnum.PUBLIC &&
                    <Label color="green" content={`Latest: v${rule.version}`}/>}
            </Table.Cell>
            <Table.Cell>{rule.instanceCount}</Table.Cell>
            <Table.Cell>
                {new Date(rule.createdAt).toLocaleString()}
                {rule.createdBy ? <Arn arn={rule.createdBy} secondary /> : <>&mdash;</>}
            </Table.Cell>
            <Table.Cell textAlign={"right"} singleLine>
                {(!rule.permissions || rule.permissions?.includes(RuleSharePermissionsEnum.CAN_EDIT)) && (
                    <RuleSettingsModal rule={rule}>
                        <Button title={'Edit rule'} basic icon={'edit outline'} circular size={'mini'} />
                    </RuleSettingsModal>
                )}
                {/*<Button title={'Duplicate rule'} basic icon={'clone outline'} circular/>*/}
                {rule.orgId === orgId && (
                    <Button
                        title={'Delete rule'} basic icon={'trash alternate outline'}
                        circular size={'mini'}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onDelete(rule);
                        }}
                    />
                )}
            </Table.Cell>
        </Table.Row>
    )
}
