import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RuleInterface} from "../interfaces/rule.interface";
import {findRules, getRule} from "../thunks/rules.thunk";

export interface RulesStateInterface {
    list: RuleInterface[];
    activeRule: RuleInterface | undefined;
}

const initialState: RulesStateInterface = {
    list: [],
    activeRule: undefined
}

export const rulesSlice = createSlice({
    name: 'rules',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(findRules.fulfilled, (state, action) => {
            state.list = action.payload.data;
        });
        builder.addCase(getRule.fulfilled, (state, action) => {
            state.activeRule = action.payload;
        });
    }
});

export const {} = rulesSlice.actions;

export const rulesReducer = rulesSlice.reducer;