export enum NodeTestCaseStatusEnum {
    SUCCESS = 'success',
    FAILED = 'failed',
    NEW = 'new',
    PENDING = 'pending'
}

export interface NodeTestCaseInterface {
    uuid: string;
    name: string;
    input: any;
    nodeParams: Record<string, any>;
    instance: object;
    flowNode: object;
    eventData: any;
    assertions: string;
    status: NodeTestCaseStatusEnum;
    testFailureMessage: string;
}