import {api} from './api';
import {isUndefined, omitBy} from "lodash";
import {RuleInterface} from "../interfaces/rule.interface";
import {CreateRuleInterface} from "../interfaces/create-rule.interface";
import {UpdateRuleInterface} from "../interfaces/update-rule.interface";

export class RuleService {

    static async find({limit}: {
        limit?: number
    }) {
        const response = await api.get('/rules', {
            params: {
                limit
            }
        });
        return response.data;
    }

    static async get(ruleUuid: string) {
        const response = await api.get(`/rules/${ruleUuid}`);
        return response.data;
    }

    static async create(ruleData: CreateRuleInterface) {
        const response = await api.post('/rules', {
            name: ruleData.name,
            description: ruleData.description,
            status: 'active',
            triggers: [],
            params: [],
            flow: ruleData.flow
        });
        return response.data;
    }

    static async update(ruleUuid: string, ruleData: UpdateRuleInterface) {
        const response = await api.post(`/rules/${ruleUuid}`, omitBy({
            name: ruleData.name,
            description: ruleData.description,
            status: ruleData.status,
            // triggers: ruleData.triggers,
            params: ruleData.params,
            flow: ruleData.flow
        }, isUndefined));
        return response.data;
    }

    static async test(ruleUuid: string, ruleVersion: number, triggerParams: object, ruleParams: object, eventData: object, runTimes = 1) {
        const response = await api.patch('/rules/test', {
            ruleUuid,
            ruleVersion,
            triggerParams,
            ruleParams,
            eventData,
            runTimes
        });
        return response.data;
    }

}