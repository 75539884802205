import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Divider, Select} from "semantic-ui-react";
import {map} from 'lodash';
import {CreateInstanceModal} from "../../../components/instance/CreateInstanceModal";
import {RuleInstances} from "../../../components/rule/RuleInstances";
import {RuleInterface} from "../../../interfaces/rule.interface";
import {SwitchVersionModal} from "../../../components/instance/SwitchVersionModal";
import {ConfirmDeleteModal} from "../../../components/ConfirmDeleteModal";
import {useDeleteAllInstancesMutation} from "../../../apis/instances.api";

export function InstancesTab({rules}: { rules: RuleInterface[] }) {
    const params = useParams();
    const [version, setVersion] = useState(params.version);
    const [deleteIsOpen, setDeleteIsOpen] = useState(false);
    const rule = rules.find((rule) => rule.version.toString() === version);
    const [deleteAll, deletionResult] = useDeleteAllInstancesMutation();

    useEffect(() => {
        if (deletionResult.isSuccess) {
            setDeleteIsOpen(false);
        }
    }, [deletionResult.isSuccess])

    return (
        <>
            <Divider hidden/>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Select
                    placeholder="Select version..."
                    value={version}
                    options={rules.map((rule) => ({
                        key: `v-${rule.version}`,
                        value: rule.version.toString(),
                        text: `Version ${rule.version}`,
                    }))}
                    onChange={(e, {value}) => setVersion(value as string)}
                />
                {rule && (
                    <div style={{textAlign: 'right'}}>
                        <CreateInstanceModal ruleUuid={rule.uuid} ruleVersion={rule.version}>
                            <Button icon="plus" content="Create an instance"/>
                        </CreateInstanceModal>
                        <SwitchVersionModal ruleUuid={rule.uuid} fromVersion={rule.version} availableVersions={map(rules, 'version')}>
                            <Button icon="exchange" content="Switch to another version"/>
                        </SwitchVersionModal>
                        {deleteIsOpen && <ConfirmDeleteModal
                            open={deleteIsOpen}
                            isLoading={deletionResult.isLoading}
                            onYes={() => {
                                deleteAll({
                                    ruleUuid: rule.uuid,
                                    ruleVersion: rule.version
                                })
                            }}
                            onNo={() => setDeleteIsOpen(false)}
                            title={'Delete all instances?'}
                            text={'Do you want to delete all instances for this rule version?'}
                        />}
                        <Button icon="trash alternate outline" content="Delete instances" onClick={() => setDeleteIsOpen(true)}/>
                    </div>
                )}
            </div>
            <Divider hidden/>
            {rule && <RuleInstances rule={rule} createButton={false} debugInModal/>}
        </>
    )
}
