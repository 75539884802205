import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../helpers/base-query";
import {GlobalEventInterface} from "../interfaces/global-event.interface";

export const eventsApi = createApi({
    reducerPath: 'eventsApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        findEvents: builder.query<GlobalEventInterface[], {
            limit?: number;
        }>({
            query: () => `dictionary/events`,
        }),
    }),
})

export const {useFindEventsQuery} = eventsApi;