import {Button, Dimmer, List, Loader, Message, Pagination, Segment} from "semantic-ui-react";
import {RuleInterface} from "../../interfaces/rule.interface";
import {useDeleteInstanceMutation, useFindInstancesQuery} from "../../apis/instances.api";
import {useEffect, useState} from "react";
import {CreateInstanceModal} from "../instance/CreateInstanceModal";
import {InstanceInterface} from "../../interfaces/instance.interface";
import {ConfirmDeleteModal} from "../ConfirmDeleteModal";
import {InstanceLogs} from "../../screens/rule-editor/InstanceLogs";
import {FireInstanceModal} from "../instance/FireInstanceModal";
import './RuleInstances.scss'

export function RuleInstances({
    rule,
    createButton = true,
    size = 'default',
    debugInModal = false
}: {
    rule: RuleInterface;
    createButton?: boolean;
    size?: 'default' | 'mini';
    debugInModal?: boolean;
}) {
    const [page, setPage] = useState(1);
    const [activeInstance, setActiveInstance] = useState<InstanceInterface>();
    const [editInstance, setEditInstance] = useState<InstanceInterface>();
    const [deleteInstanceUuid, setDeleteInstanceUuid] = useState<string>();
    const {data: instances, isFetching, isSuccess, isError, error, refetch} = useFindInstancesQuery({
        ruleUuid: rule.uuid,
        ruleVersion: rule.version,
        page: page,
        limit: 15
    });
    const [deleteInstance, deleteInstanceResult] = useDeleteInstanceMutation();

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        document.querySelector('#rule-instances-list')?.closest('.scrollbar-container')?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [page]);

    if (activeInstance) {
        return (
            <InstanceLogs rule={rule} instance={activeInstance} debugInModal={debugInModal} size={size} onBack={() => setActiveInstance(undefined)}/>
        )
    }

    return (
        <>
            <Segment basic={size === 'mini'} style={size === 'mini' ? {padding: 0, minHeight: '3.5em'} : {minHeight: '3.5em'}}>
                {isError && <Message error content={(error as any)?.data?.error?.description || 'Unknown error'}/>}
                <Dimmer active={isFetching || deleteInstanceResult.isLoading} inverted><Loader/></Dimmer>
                {createButton !== false && (
                    <CreateInstanceModal ruleUuid={rule.uuid} ruleVersion={rule.version}>
                        <Button content="Create instance" fluid style={{marginBottom: '1.5em'}}/>
                    </CreateInstanceModal>
                )}
                {editInstance &&
                    <CreateInstanceModal ruleUuid={rule.uuid} ruleVersion={rule.version} edit={editInstance} open={true}
                                         onClose={() => setEditInstance(undefined)}/>}
                {deleteInstanceUuid && (
                    <ConfirmDeleteModal
                        open={true}
                        onYes={() => {
                            deleteInstance(deleteInstanceUuid);
                            setDeleteInstanceUuid(undefined);
                        }}
                        onNo={() => setDeleteInstanceUuid(undefined)}
                        title={'Delete instance?'}
                        text={'Do you want to delete this rule instance?'}
                    />
                )}
                {!isFetching && !instances?.data.length && <div>No instances found</div>}
                <List id="rule-instances-list" divided className="rule-instances-list" style={{position: 'relative', marginTop: 0}}>
                    {instances?.data.map((instance, i) => (
                        <List.Item style={{padding: '10px 0', background: 'white'}}
                                   key={`item-${i}`}>
                            <List.Content floated='right'>
                                {instance.invocationsCount > 0 && (
                                    <Button basic icon="bug" size="mini" content={instance.invocationsCount}
                                            title="Debug invocations" style={{paddingRight: '0.75em'}}
                                            onClick={() => setActiveInstance(instance)}/>
                                )}
                                <FireInstanceModal instance={instance}>
                                    <Button basic icon="lightning" size="mini" title="Fire instance"/>
                                </FireInstanceModal>
                                <Button basic icon="pencil" size="mini" title="Edit instance"
                                        onClick={() => setEditInstance(instance)}/>
                                <Button basic icon="trash" size="mini" title="Delete instance"
                                        onClick={() => setDeleteInstanceUuid(instance.uuid)}/>
                            </List.Content>
                            <List.Content>
                                <p style={{marginTop: 5}}>
                                    Created by: {instance.createdBy ? `account #${instance.createdBy}` : 'automatically'}<br />
                                    <span style={{whiteSpace: "pre-wrap", wordBreak: 'break-word'}}>Trigger&nbsp;params:&nbsp;{JSON.stringify(instance.triggerParams) || 'None'}</span><br />
                                    <span style={{whiteSpace: "pre-wrap", wordBreak: 'break-word'}}>Rule&nbsp;params:&nbsp;{JSON.stringify(instance.ruleParams) || 'None'}</span>
                                </p>
                                <div style={{opacity: 0.5, fontSize: 12}}>
                                    Created at: {new Date(instance.createdAt).toLocaleString()}<br />
                                    Latest invocation: {instance.latestInvocationDate ? new Date(instance.latestInvocationDate).toLocaleString() : <>&mdash;</>}
                                </div>
                                {/*<details style={{marginTop: 15}}>*/}
                                {/*    <summary>*/}
                                {/*        <Icon name="angle right" />*/}
                                {/*        <span>show details</span>*/}
                                {/*    </summary>*/}
                                {/*    <div style={{marginTop: 15}}>*/}
                                {/*        <h5>Instance UUID</h5>*/}
                                {/*        <p>{instance.uuid}</p>*/}
                                {/*        <h5>Instance triggers</h5>*/}
                                {/*        <ul style={{paddingLeft: '1.25em'}}>*/}
                                {/*            <li>My trigger</li>*/}
                                {/*            <li>My trigger</li>*/}
                                {/*        </ul>*/}
                                {/*        <h5>Trigger params</h5>*/}
                                {/*        <SyntaxHighlighter language="json" style={{...docco}}>*/}
                                {/*            {JSON.stringify(instance.triggerParams, null, 2) || 'None'}*/}
                                {/*        </SyntaxHighlighter>*/}
                                {/*        <h5>Rule params</h5>*/}
                                {/*        <SyntaxHighlighter language="json" style={{...docco}}>*/}
                                {/*            {JSON.stringify(instance.ruleParams, null, 2) || 'None'}*/}
                                {/*        </SyntaxHighlighter>*/}
                                {/*    </div>*/}
                                {/*</details>*/}
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            </Segment>
            {!!(instances?.pagination?.total && instances?.pagination?.limit && instances?.pagination?.page) && (
                <Pagination
                    style={size === 'mini' ? {display: 'flex', justifyContent: 'center'} : {}}
                    fluid={size === 'mini'}
                    size={size === 'mini' ? 'mini' : undefined}
                    activePage={page}
                    totalPages={Math.ceil(instances.pagination.total / instances.pagination.limit)}
                    onPageChange={((event, {activePage}) => setPage(activePage as number))}
                    firstItem={false}
                    lastItem={false}
                    siblingRange={0}
                />
            )}
        </>
    )
}