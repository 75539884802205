import {Navigate, useLocation} from "react-router-dom";
import {useAppSelector} from "../store";
import {ReactElement} from "react";

export function RequireGuest({ children }: { children: ReactElement }) {
    const auth = useAppSelector((state) => state.auth);
    let location = useLocation();

    if (auth.loggedIn && auth.org) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
}