import {Button, Dimmer, Form, Loader, Message, Modal} from "semantic-ui-react";
import {cloneElement, ReactElement, useEffect, useState} from "react";
import {find, pick} from "lodash";
import {RuleInterface} from "../../interfaces/rule.interface";
import {RuleShareInterface} from "../../interfaces/rule-share.interface";
import {useLazyCheckOrgByNameQuery} from "../../apis/organizations.api";
import {
    useFindRuleSharesQuery,
    useShareRuleMutation,
    useStopShareRuleMutation,
    useUpdateRuleMutation
} from "../../apis/share.api";
import {useAppSelector} from "../../store";

export function ShareRuleModal({children, rule}: { children: ReactElement; rule: RuleInterface }) {
    const orgId = useAppSelector(state => state.auth.orgId);
    const [isOpen, setIsOpen] = useState(false);
    const [list, setList] = useState<RuleShareInterface[]>([]);
    const [orgName, setOrgName] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    const [error, setError] = useState('');
    const [orgError, setOrgError] = useState('');
    const [toDelete, setToDelete] = useState<string[]>([]);
    const [checkOrgByName] = useLazyCheckOrgByNameQuery();
    const [shareRule] = useShareRuleMutation();
    const [updateRule] = useUpdateRuleMutation();
    const [stopShare] = useStopShareRuleMutation();
    const existsOrgs = useFindRuleSharesQuery({
        ruleUuid: rule.uuid,
        ruleVersion: rule.version,
    }, {
        skip: !isOpen
    });

    useEffect(() => {
        if (isOpen) {
            existsOrgs.refetch()
        } else {
            setOrgName('');
            setPermissions([]);
            setToDelete([]);
            setError('');
            setOrgError('');
            setList([]);
            setLoading(false);
            setAddLoading(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (existsOrgs.isSuccess) {
            setList(existsOrgs.data?.data || [])
        }
    }, [existsOrgs.status])

    async function add() {
        if (list.find(item => item.sharedOrgName === orgName)) {
            setOrgError('You\'ve already shared this rule with this organization')
            return;
        }

        setOrgError('');
        setAddLoading(true);
        const result = await checkOrgByName(orgName);
        setAddLoading(false);

        if (!result.data?.id) {
            setOrgError('Organization not found')
        } else if (result.data.id === rule.orgId) {
            setOrgError('Rule belongs to this organization')
        } else {
            setList([
                ...list,
                {
                    ruleUuid: rule.uuid,
                    ruleVersion: rule.version,
                    sharedOrgId: result.data.id,
                    sharedOrgName: orgName,
                    permissions
                } as any
            ]);
            setOrgName('');
            setPermissions([]);
        }
    }

    async function submit() {
        setLoading(true);
        setError('');

        const toUpdate = list
            .filter(i => !!i.uuid)
            .filter(item => {
                const exists = find(existsOrgs.data?.data, {uuid: item.uuid});
                return exists && [...exists.permissions].sort().join() !== [...item.permissions].sort().join()
            });
        const toAdd = list.filter(i => !i.uuid);

        const result = await Promise.all([
            ...toAdd.map(item => shareRule(item as any)),
            ...toUpdate.map(item => updateRule(pick(item, 'uuid', 'permissions'))),
            ...toDelete.map(stopShare)
        ]);
        await existsOrgs.refetch();

        setLoading(false);

        const hasError: any = result.find((item: any) => !!item.error);

        if (hasError) {
            setError(hasError?.error?.data?.error?.description || 'Unknown error');
        } else {
            setIsOpen(false);
        }
    }

    return (
        <>
            {cloneElement(children, {
                onClick: () => setIsOpen(true)
            })}
            <Modal closeIcon open={isOpen} size="small" onClose={() => setIsOpen(false)}>
                <Modal.Header>Share rule</Modal.Header>
                <Modal.Content>
                    {(existsOrgs.isError || error) && <Message error content={error || (existsOrgs.error as any)?.data?.error?.description || 'Unknown error' } />}
                    <Dimmer active={loading || existsOrgs.isFetching} inverted><Loader /></Dimmer>
                    <Form>
                        <h5>Share rule with organizations</h5>
                        {list.map((ruleShare, i) => (
                            <Form.Group key={`rule-share-${i}`}>
                                <Form.Field width={8}>
                                    <div style={{padding: '0.5em'}}>{ruleShare.sharedOrgName}</div>
                                </Form.Field>
                                <Form.Select
                                    multiple
                                    width={6}
                                    options={[
                                        {key: 'can-view', value: 'can-view', text: 'can view'},
                                        {key: 'can-edit', value: 'can-edit', text: 'can edit'},
                                        {key: 'can-duplicate', value: 'can-duplicate', text: 'can duplicate'},
                                    ]}
                                    placeholder={'Permissions'}
                                    closeOnChange
                                    value={ruleShare.permissions}
                                    onChange={(event, {value}) => {
                                        const newList = [...list];
                                        newList[i] = {
                                            ...ruleShare,
                                            permissions: value as any
                                        }
                                        setList(newList);
                                    }}
                                />
                                <Form.Button
                                    content="Del"
                                    width={2}
                                    fluid
                                    onClick={() => {
                                        if (ruleShare.uuid) {
                                            setToDelete([...toDelete, ruleShare.uuid])
                                        }
                                        setList(list.filter((_i, idx) => idx !== i));
                                    }}
                                />
                            </Form.Group>
                        ))}
                        <Form.Group style={{alignItems: 'start'}}>
                            <Form.Input
                                width={8}
                                placeholder="Organization name"
                                value={orgName}
                                error={orgError || undefined}
                                onChange={(event, {value}) => setOrgName(value)}
                            />
                            <Form.Select
                                width={6}
                                multiple
                                options={[
                                    {key: 'can-view', value: 'can-view', text: 'can view'},
                                    {key: 'can-edit', value: 'can-edit', text: 'can edit'},
                                    {key: 'can-duplicate', value: 'can-duplicate', text: 'can duplicate'},
                                ]}
                                placeholder={'Permissions'}
                                closeOnChange
                                value={permissions}
                                onChange={(event, {value}) => setPermissions(value as any)}
                            />
                            <Form.Button
                                width={2}
                                content="Add"
                                fluid
                                primary
                                disabled={!orgName.trim() || !permissions.length || addLoading}
                                loading={addLoading}
                                onClick={add}
                            />
                        </Form.Group>
                    </Form>
                    <br />
                    <Message content={<>Notice that your are going to share <b>rule's version {rule.version}</b>. Other rule versions will not be shared.</>} />
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Cancel" onClick={() => setIsOpen(false)} />
                    <Button primary content="Apply" icon="check" onClick={submit} />
                </Modal.Actions>
            </Modal>
        </>
    )
}