import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface GlobalStateInterface {
    sync: boolean;
}

const initialState: GlobalStateInterface = {
    sync: false
}

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setSync: (draft: GlobalStateInterface, action: PayloadAction<boolean>) => {
            draft.sync = action.payload;
        }
    },
});

export const {setSync} = globalSlice.actions;

export const globalReducer = globalSlice.reducer;