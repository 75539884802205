import {Divider, Label, List} from "semantic-ui-react";
import {Node} from "./Node";
import {useState} from "react";
import {useAppSelector} from "../store";
import {NodeInterface} from "../interfaces/node.interface";
import {compact, flatten, intersection, maxBy, uniq, xor} from "lodash";

export function NodesList() {
    const nodes = useAppSelector(state => state.editor.nodes);
    const [tagFilter, setTagFilter] = useState<string[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
    const categories = compact(uniq(nodes.map(node => node.category)));
    const tags = compact(uniq(flatten(nodes.map(node => node.tags))));
    const filterNodes = (node: NodeInterface): boolean => {
        const maxVersion = maxBy(nodes.filter(item => item.uuid === node.uuid), 'version')?.version;
        return node.version === maxVersion &&
            (!categoryFilter.length || categoryFilter.includes(node.category || '')) &&
            (!tagFilter.length || intersection(tagFilter, node.tags).length > 0);
    }

    return (
        <>
            {(categories.length > 0 || tags.length > 0) && (
                <>
                    <div style={{paddingBottom: '0.5em', display: 'flex', gap: 5, flexWrap: 'wrap'}}>
                        {categories.map(category => (
                            <Label
                                basic={!categoryFilter.includes(category)}
                                style={{borderWidth: 1, cursor: 'pointer', margin: 0}}
                                content={category}
                                onClick={() => setCategoryFilter(xor(categoryFilter, [category]))}
                            />
                        ))}
                    </div>
                    <div style={{paddingBottom: '1em', display: 'flex', gap: 5, flexWrap: 'wrap'}}>
                        {tags.map(tag => (
                            <Label
                                basic={!tagFilter.includes(tag)}
                                style={{borderWidth: 1, cursor: 'pointer', margin: 0}}
                                content={`#${tag}`}
                                onClick={() => setTagFilter(xor(tagFilter, [tag]))}
                            />
                        ))}
                    </div>
                </>
            )}
            <List divided style={{margin: 0}}>
                {nodes.filter(filterNodes).map((node, i) => <Node key={i} node={node}/>)}
            </List>
        </>
    );
}