import {Button, Divider, Form, Header, Radio, Segment} from "semantic-ui-react";
import {removeNode, setActiveNodeUuid, updateNode} from "../../slices/flow.slice";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import {RuleEdgeInterface} from "../../interfaces/rule-edge.interface";
import {NodeInterface} from "../../interfaces/node.interface";
import {RemoveNodeModal} from "../RemoveNodeModal";
import {cloneDeep} from "lodash";
import {useUpdateRuleMutation} from "../../apis/rules.api";
import {RuleInterface} from "../../interfaces/rule.interface";
import {TriggerInterface} from "../../interfaces/trigger.interface";

export function EdgeParams({rule, readOnly}: {rule: RuleInterface, readOnly?: boolean}) {
    const dispatch = useAppDispatch();
    const activeNodeUuid = useAppSelector(state => state.flow.activeNodeUuid);
    const flow = useAppSelector(state => state.flow.flow);
    const nodes = useAppSelector(state => state.editor.nodes);
    const triggers = useAppSelector(state => state.editor.triggers);
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [activeNode, setActiveNode] = useState<RuleEdgeInterface>();
    const [source, setSource] = useState<NodeInterface | TriggerInterface>();
    const [target, setTarget] = useState<NodeInterface>();
    const [updateRule] = useUpdateRuleMutation();

    useEffect(() => {
        setActiveNode(flow.find(node => node.uuid === activeNodeUuid));
    }, [activeNodeUuid, flow]);

    useEffect(() => {
        if (!activeNode) {
            return;
        }
        setSource(
            nodes.find(node => node.uuid === flow.find(node => node.uuid === activeNode.source)?.nodeUuid) ||
            triggers.find(trigger => trigger.uuid === flow.find(node => node.uuid === activeNode.source)?.nodeUuid)
        );
        setTarget(nodes.find(node => node.uuid === flow.find(node => node.uuid === activeNode.target)?.nodeUuid));
    }, [activeNode]);

    if (!activeNode) {
        return <></>;
    }

    function handleIfChange(value: boolean | undefined) {
        if (!activeNode) {
            return;
        }

        dispatch(updateNode({
            uuid: activeNode.uuid,
            data: {
                if: value
            }
        }));

        const newFlow = cloneDeep(flow);
        const node = newFlow.find((node) => node.uuid === activeNode.uuid);
        if (node) {
            node.if = value;
            updateRule({
                uuid: rule.uuid,
                version: rule.version,
                flow: newFlow
            });
        }
    }

    function remove() {
        if (!activeNode) {
            return;
        }
        const newFlow = cloneDeep(flow).filter((node) => node.uuid !== activeNode.uuid);
        dispatch(removeNode(activeNode.uuid));
        setRemoveModalOpen(false);
        updateRule({
            uuid: rule.uuid,
            version: rule.version,
            flow: newFlow
        });
    }

    if (!activeNode) {
        return <></>;
    }

    return (
        <Segment basic>
            <Header style={{marginTop: '0.25em'}}>
                Edge
                <Button className="close-btn" icon="close" onClick={() => dispatch(setActiveNodeUuid(undefined))}/>
            </Header>
            <Divider/>
            <p>Source: {source?.name}</p>
            <p>Target: {target?.name}</p>
            {readOnly ? (
                <div>
                    Go ahead:&nbsp;
                    {activeNode.if === undefined && <b>Always</b>}
                    {activeNode.if === true && <b>Only if true</b>}
                    {activeNode.if === false && <b>Only if false</b>}
                </div>
            ) : (
                <Form>
                    <Form.Field>
                        <h5>Go ahead</h5>
                        <Radio
                            label='Always'
                            name='if'
                            value='this'
                            checked={activeNode.if === undefined}
                            onChange={() => handleIfChange(undefined)}
                        />

                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label='Only if true'
                            name='if'
                            value='this'
                            checked={activeNode.if === true}
                            onChange={() => handleIfChange(true)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label='Only if false'
                            name='if'
                            value='this'
                            checked={activeNode.if === false}
                            onChange={() => handleIfChange(false)}
                        />
                    </Form.Field>
                </Form>
            )}
            {!readOnly && <>
                <Divider hidden/>
                <Button color="red" basic content="Remove edge" fluid
                    onClick={() => setRemoveModalOpen(true)}/>
                <RemoveNodeModal
                    open={removeModalOpen}
                    isEdge={true}
                    onYes={() => remove()}
                    onNo={() => setRemoveModalOpen(false)} />
            </>}
        </Segment>
    );
}