import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {nodesReducer} from "./slices/nodes.slice";
import {flowReducer} from "./slices/flow.slice";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {createLogger} from 'redux-logger';
import {debugReducer} from "./slices/debug.slice";
import {rulesReducer} from "./slices/rules.slice";
import {rulesApi} from "./apis/rules.api";
import {triggersApi} from "./apis/triggers.api";
import {eventsApi} from "./apis/events.api";
import {globalReducer} from "./slices/global.slice";
import {SyncStatusMiddleware} from "./middlewares/sync-status.middleware";
import {nodesApi} from "./apis/nodes.api";
import {instancesApi} from "./apis/instances.api";
import {authReducer} from "./slices/auth.slice";
import {AuthorizationMiddleware} from "./middlewares/authorization.middleware";
import {marketplaceReducer} from "./slices/marketplace.slice";
import {organizationsApi} from "./apis/organizations.api";
import {editorReducer} from "./slices/editor.slice";
import {settingsReducer} from "./slices/settings.slice";
import {shareApi} from "./apis/share.api";

const persistConfig = {
    key: 'root',
    version: 2,
    storage,
    whitelist: ['auth', 'settings']
}

const rootReducer = combineReducers({
    global: globalReducer,
    auth: authReducer,
    editor: editorReducer,
    rules: rulesReducer,
    nodes: nodesReducer,
    flow: flowReducer,
    debug: debugReducer,
    marketplace: marketplaceReducer,
    settings: settingsReducer,
    [rulesApi.reducerPath]: rulesApi.reducer,
    [triggersApi.reducerPath]: triggersApi.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    [nodesApi.reducerPath]: nodesApi.reducer,
    [instancesApi.reducerPath]: instancesApi.reducer,
    [organizationsApi.reducerPath]: organizationsApi.reducer,
    [shareApi.reducerPath]: shareApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

const logger = createLogger({
    collapsed: true
})

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(logger)
            .concat(AuthorizationMiddleware)
            .concat(SyncStatusMiddleware)
            .concat(rulesApi.middleware)
            .concat(triggersApi.middleware)
            .concat(eventsApi.middleware)
            .concat(nodesApi.middleware)
            .concat(instancesApi.middleware)
            .concat(organizationsApi.middleware)
            .concat(shareApi.middleware)
});

let persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export {store, persistor};