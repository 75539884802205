import {Label, Message} from "semantic-ui-react";
import {useAppDispatch, useAppSelector} from "../store";
import {setNodeInfoUuid} from "../slices/flow.slice";
import {NodeInterface} from "../interfaces/node.interface";
import {NodeStatusEnum} from "../interfaces/node-status.enum";
import {RuleNodeTypeEnum} from "../interfaces/rule-node.interface";

export function Node({node}: {node: NodeInterface}) {
    const dispatch = useAppDispatch();
    const orgId = useAppSelector(state => state.auth.orgId);

    const onDragStart = (event: any, nodeType: any) => {
        event.dataTransfer.setData('application/reactflow', JSON.stringify({
            type: RuleNodeTypeEnum.NODE,
            uuid: node.uuid,
            version: node.version
        }));
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <Message
            basic
            style={{
                backgroundColor: node.status !== NodeStatusEnum.PUBLIC ? '#dbdbdb' : undefined,
                cursor: 'grab',
            }}
            onClick={() => dispatch(setNodeInfoUuid(node.uuid))}
            onDragStart={(event: any) => onDragStart(event, 'default')} draggable>
            {node.name}
            {node.status !== NodeStatusEnum.PUBLIC && (
                <span style={{float: 'right'}}>
                    <Label color='yellow' size='mini'>{node.status}</Label>
                    {node.orgId !== orgId && <Label color='teal' size='mini' content={'shared'} />}
                </span>
            )}
        </Message>
    );
}