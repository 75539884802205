import {Button, Dimmer, Form, Loader, Message, Modal} from "semantic-ui-react";
import {usePublishRuleMutation} from "../../apis/rules.api";
import {ReactElement, useEffect, useState} from "react";
import {RuleInterface} from "../../interfaces/rule.interface";

export function PublishRuleModal({rule, children, disabled}: {rule: RuleInterface; children: ReactElement, disabled?: boolean}) {

    const [publishRule, {isLoading, isSuccess, isError, error}] = usePublishRuleMutation();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            setIsOpen(false);
        }
    }, [isSuccess]);

    return (
        <>
            <span onClick={() => !disabled && setIsOpen(true)}>{children}</span>
            <Modal closeIcon open={isOpen} onClose={() => setIsOpen(false)} size="mini">
                <Dimmer active={isLoading} inverted><Loader /></Dimmer>
                <Modal.Header>Publish release</Modal.Header>
                <Modal.Content>
                    {isError && !(error as any)?.data?.errors && <Message error content={(error as any)?.data?.error?.description || 'Unknown error'} />}
                    <p>Confirm publishing release <b>v{rule.version}</b></p>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Cancel" onClick={() => setIsOpen(false)} />
                    <Button primary content="Publish" icon="check" onClick={() => {
                        publishRule({
                            ruleUuid: rule.uuid,
                            ruleVersion: rule.version,
                        })
                    }} />
                </Modal.Actions>
            </Modal>
        </>
    );
}