import {RuleInterface} from "../interfaces/rule.interface";
import {TriggerInterface} from "../interfaces/trigger.interface";
import {uniq} from "lodash";
import {ParamTypeEnum} from "../interfaces/param-type.enum";
import {ParamInterface} from "../interfaces/param.interface";

export const paramTypes = [
    {key: 'string', value: 'string', text: 'String'},
    {key: 'text', value: 'text', text: 'Text'},
    {key: 'int', value: 'int', text: 'Int'},
    {key: 'double', value: 'double', text: 'Double'},
    {key: 'bool', value: 'bool', text: 'Boolean'},
    {key: 'datetime', value: 'datetime', text: 'Datetime'},
    {key: 'date', value: 'date', text: 'Date'},
    {key: 'time', value: 'time', text: 'Time'},
    {key: 'email', value: 'email', text: 'Email'},
    {key: 'phone_number', value: 'phone_number', text: 'Phone number'},
    {key: 'array', value: 'array', text: 'Array'},
    {key: 'object', value: 'object', text: 'Object'},
    {key: 'uuid', value: 'uuid', text: 'Uuid'},
    {key: 'enum', value: 'enum', text: 'Enum'},
    {key: 'any', value: 'any', text: 'Any'},
];

export function extractTriggerParams(rule: RuleInterface, triggers: TriggerInterface[]): ParamInterface[] {
    if (!rule || !triggers) {
        return [];
    }
    return uniq(
        rule.triggers
            .map(ruleTrigger => triggers?.find(trigger => trigger.uuid === ruleTrigger.triggerUuid)?.event)
            .join(',')
            .match(/\<(.*?)\>/g)
        || [])
        ?.map(name => ({
            title: name.replace(/[\<\>]/g, ''),
            name: name.replace(/[\<\>]/g, ''),
            type: ParamTypeEnum.STRING
        }));
}