import {RuleInterface} from "../../interfaces/rule.interface";
import {cloneElement, ReactElement, useEffect, useState} from "react";
import {Button, Dimmer, Form, Loader, Message, Modal} from "semantic-ui-react";
import {useUpdateRuleMutation} from "../../apis/rules.api";

export function RuleSettingsModal({children, rule}: { children: ReactElement; rule: RuleInterface }) {
    const [isOpen, setIsOpen] = useState(false);
    const [updateRule, {isLoading, isSuccess, isError, error}] = useUpdateRuleMutation();
    const [formData, setFormData] = useState({
        name: rule.name,
        description: rule.description || '',
        category: rule.category,
        tags: rule.tags?.join(', '),
    });

    useEffect(() => {
        if (rule) {
            setFormData({
                name: rule.name,
                description: rule.description || '',
                category: rule.category,
                tags: rule.tags?.join(', '),
            });
        }
    }, [rule]);

    useEffect(() => {
        if (isSuccess) {
            setIsOpen(false);
        }
    }, [isSuccess]);

    function save() {
        updateRule({
            uuid: rule.uuid,
            version: rule.version,
            ...formData,
            tags: formData.tags?.split(/[\s,]/).map(i => i.trim()).filter(i => !!i),
        });
    }

    function handleChange(e: any, data: any) {
        setFormData({
            ...formData,
            [data.name]: data.value
        });
    }

    return (
        <>
            {cloneElement(children, {
                onClick: (e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsOpen(true)
                }
            })}
            <Modal open={isOpen} onClose={() => setIsOpen(false)} size="mini" closeIcon>
                <Dimmer active={isLoading} inverted><Loader /></Dimmer>
                <Modal.Header>Rule settings</Modal.Header>
                <Modal.Content>
                    {isError && !(error as any)?.data?.errors && <Message error content={(error as any)?.data?.error?.description || 'Unknown error'} />}
                    <Form>
                        <Form.Input
                            name="name"
                            label="Title"
                            value={formData.name}
                            onChange={handleChange}
                            error={(error as any)?.data?.errors?.name}
                        />
                        <Form.TextArea
                            name="description"
                            label="Description (optional)"
                            value={formData.description}
                            onChange={handleChange}
                            error={(error as any)?.data?.errors?.description}
                        />
                        <Form.Input
                            name="category"
                            label="Category (optional)"
                            type="text"
                            value={formData.category}
                            error={(error as any)?.data?.errors?.category?.[0]}
                            onChange={handleChange}
                        />
                        <Form.Input
                            name="tags"
                            label="Tags (optional, separated by comma or space)"
                            type="text"
                            value={formData.tags}
                            error={(error as any)?.data?.errors?.tags?.[0]}
                            onChange={handleChange}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary icon="check" content="Save" onClick={() => save()} />
                </Modal.Actions>
            </Modal>
        </>
    )
}