import {TriggerInterface} from "../../interfaces/trigger.interface";
import {Button, Dimmer, Form, Grid, Loader, Message, Segment} from "semantic-ui-react";
import {useFindEventsQuery} from "../../apis/events.api";
import {useEffect, useState} from "react";
import {GlobalEventInterface} from "../../interfaces/global-event.interface";
import {EventDetails} from "./EventDetails";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useCreateTriggerMutation, useUpdateTriggerMutation} from "../../apis/triggers.api";
import {TriggerStatusEnum} from "../../interfaces/trigger-status.enum";
import {useNavigate} from "react-router-dom";

export function Editor({trigger}: { trigger?: TriggerInterface }) {

    const {data: events} = useFindEventsQuery({});
    const [formData, setFormData] = useState({
        name: '',
        event: '',
        status: TriggerStatusEnum.ACTIVE,
    });
    const [event, setEvent] = useState<GlobalEventInterface>();
    const [createTrigger, createTriggerResult] = useCreateTriggerMutation();
    const [updateTrigger, updateTriggerResult] = useUpdateTriggerMutation();
    const navigate = useNavigate();

    useEffect(() => {
        setFormData({
            name: trigger?.name || '',
            event: trigger?.event || '',
            status: trigger?.status || TriggerStatusEnum.ACTIVE,
        })
    }, [trigger])

    useEffect(() => {
        setEvent(events?.find((event) => event.event === formData.event));
    }, [formData.event]);

    useEffect(() => {
        if (createTriggerResult?.data?.uuid) {
            navigate(`/triggers/${createTriggerResult.data.uuid}`)
        }
    }, [createTriggerResult?.data]);

    function submit() {
        if (trigger?.uuid) {
            updateTrigger({
                uuid: trigger.uuid,
                ...formData
            })
        } else {
            createTrigger(formData);
        }
    }

    return (
        <PerfectScrollbar>
            <Grid style={{margin: 0}}>
                <Grid.Column width={12}>
                        <h2>Edit trigger</h2>
                        <Segment style={{display: 'flex', flexDirection: 'column'}}>
                            <Dimmer inverted active={createTriggerResult?.isLoading || updateTriggerResult?.isLoading}><Loader /></Dimmer>
                            {
                                (createTriggerResult?.isError || updateTriggerResult?.isError) &&
                                !((createTriggerResult?.error || updateTriggerResult?.error) as any)?.data?.errors && (
                                    <Message error content={((createTriggerResult?.error || updateTriggerResult?.error) as any)?.data?.error?.description || 'Unknown error'} />
                                )
                            }
                            <Form>
                                <Form.Input
                                    name="name"
                                    value={formData.name}
                                    onChange={(_e, {value}) => {
                                        setFormData({...formData, name: value});
                                    }}
                                    label="Trigger name"
                                    error={((createTriggerResult?.error || updateTriggerResult?.error) as any)?.data?.errors?.name}
                                />
                                <Form.Select
                                    label="Event"
                                    value={formData.event}
                                    onChange={(_e, {value}) => {
                                        setFormData({...formData, event: value?.toString() || ''});
                                    }}
                                    search
                                    options={events?.map((item) => ({
                                        key: item.event,
                                        value: item.event,
                                        text: item.title,
                                        content: (
                                            <div>
                                                <div>{item.title}</div>
                                                <div style={{
                                                    opacity: 0.5,
                                                    fontWeight: 'normal',
                                                    marginTop: 5
                                                }}>{item.event}</div>
                                            </div>
                                        )
                                    })) || []}
                                    error={((createTriggerResult?.error || updateTriggerResult?.error) as any)?.data?.errors?.event}
                                />
                                <Form.Checkbox
                                    label="Active"
                                    checked={formData.status === TriggerStatusEnum.ACTIVE}
                                    onChange={(e, {checked}) => {
                                        setFormData({...formData, status: checked ? TriggerStatusEnum.ACTIVE : TriggerStatusEnum.INACTIVE});
                                    }}
                                />
                                <Button content="Save trigger" primary icon="check" floated="right" onClick={() => submit()}/>
                            </Form>
                        </Segment>
                        {!!event && (
                            <>
                                <h3>Event details</h3>
                                <EventDetails event={event}/>
                            </>
                        )}
                </Grid.Column>
            </Grid>
        </PerfectScrollbar>
    )
}