import {Button, Card, Icon, Label, Table} from "semantic-ui-react";
import {Link, useParams} from "react-router-dom";
import {useState} from "react";
import {NodeInterface} from "../../../interfaces/node.interface";
import {CreateNodeModal} from "../../../components/node/CreateNodeModal";
import {useDeleteNodeMutation} from "../../../apis/nodes.api";
import {NodeStatusEnum} from "../../../interfaces/node-status.enum";
import {ConfirmDeleteModal} from "../../../components/ConfirmDeleteModal";
import {Arn} from "../../../components/Arn";
import {getNodeLink} from "../../../helpers/nodes";

export function NodesList({nodes, create, view}: { nodes: NodeInterface[]; create?: boolean; view?: 'table' | 'cards' }) {
    const [createModalOpened, setCreateModalOpened] = useState(false);
    const [deleteModalOpened, setDeleteModalOpened] = useState(false);
    const [activeNode, setActiveNode] = useState<NodeInterface>();
    const [deleteNode] = useDeleteNodeMutation();
    const {organization} = useParams();

    return (
        <>
            {view === 'table' ? (
                nodes.length > 0 && (
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={1}>Arn</Table.HeaderCell>
                                <Table.HeaderCell width={4}>Name</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Category / tags</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Version</Table.HeaderCell>
                                <Table.HeaderCell width={2}>Used in rules</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Created at / created by</Table.HeaderCell>
                                <Table.HeaderCell width={2} textAlign={"right"}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {nodes.map((node, i) => (
                                <Table.Row key={`node-table-row-${i}`}>
                                    <Table.Cell>
                                        <Arn arn={node.arn} secondary text={false} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Link to={getNodeLink(node, organization as string)}>
                                            {node.name}
                                        </Link>
                                        <p style={{opacity: 0.5}}>{node.description}</p>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {node.category || <>&mdash;</>}
                                        {(node.tags?.length || 0) > 0 && (
                                            <p style={{opacity: 0.5, marginBottom: 0}}>#{node.tags?.join(', #')}</p>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {node.status === NodeStatusEnum.DRAFT && <Label basic color="orange" content="Draft" />}
                                        {node.status === NodeStatusEnum.ACTIVE && <Label color="blue" content={`Version: ${node.version}`} />}
                                        {node.status === NodeStatusEnum.PUBLIC && <Label color="green" content={`Latest: v${node.version}`} />}
                                    </Table.Cell>
                                    <Table.Cell>{node.usageCount}</Table.Cell>
                                    <Table.Cell>
                                        {new Date(node.createdAt).toLocaleString()}
                                        {node.createdBy ? <Arn arn={node.createdBy} secondary /> : <>&mdash;</>}
                                    </Table.Cell>
                                    <Table.Cell textAlign={"right"} singleLine>
                                        {/*<Button title={'Duplicate rule'} basic icon={'clone outline'} circular/>*/}
                                        <Button
                                            title={'Delete rule'} basic icon={'trash alternate outline'}
                                            circular size={'mini'}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setActiveNode(node);
                                                setDeleteModalOpened(true);
                                            }}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                )
            ) : (
                <Card.Group itemsPerRow={3} stackable doubling>
                    {nodes.map((node, i) => (
                        <Card key={`node-${i}`}>
                            <Card.Content  as={Link} to={getNodeLink(node, organization as string)}>
                                <Card.Header>{node.name}</Card.Header>
                                <Card.Description>
                                    {node.description}
                                </Card.Description>
                                <br/>
                                <Card.Description>
                                    {node.status === NodeStatusEnum.DRAFT && <Label basic color="orange" content="Draft" />}
                                    {node.status === NodeStatusEnum.ACTIVE && <Label color="blue" content={`Version: ${node.version}`} />}
                                    {node.status === NodeStatusEnum.PUBLIC && <Label color="green" content={`Latest: v${node.version}`} />}
                                </Card.Description>
                                <br/>
                                <Card.Description>
                                    category: {node.category || <>&mdash;</>}<br/>
                                    tags: {(node.tags?.length || 0) > 0 ? '#' + node.tags?.join(', #') : <>&mdash;</>}<br/>
                                    updated at: {new Date(node.updatedAt).toLocaleString()}<br />
                                    created by: {node.createdBy ? `#${node.createdBy}` : 'AffirmXH'}<br />
                                    using in rules: {node.usageCount}
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div>
                                    <Icon name='clock outline'/> {new Date(node.createdAt).toLocaleDateString()}
                                </div>
                                <div style={{marginLeft: 'auto'}}>
                                    <Button
                                        title={'Delete rule'} basic
                                        icon={'trash alternate outline'} circular size={'mini'}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setActiveNode(node);
                                            setDeleteModalOpened(true);
                                        }}
                                    />
                                </div>
                            </Card.Content>
                        </Card>
                    ))}
                    {create && (
                        <Card style={{minHeight: 175}}  onClick={() => setCreateModalOpened(true)}>
                            <Card.Content style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Card.Header>Create new node</Card.Header>
                            </Card.Content>
                        </Card>
                    )}
                </Card.Group>
            )}

            {createModalOpened && <CreateNodeModal open onClose={() => setCreateModalOpened(false)}/>}

            <ConfirmDeleteModal
                open={deleteModalOpened}
                onYes={() => {
                    activeNode && deleteNode({
                        nodeUuid: activeNode.uuid,
                        nodeVersion: activeNode.version,
                    });
                    setDeleteModalOpened(false);
                }}
                onNo={() => setDeleteModalOpened(false)}
            />
        </>
    )
}