import {Link, useParams} from "react-router-dom";
import {Button, Card, Container, Dimmer, Divider, Grid, List, Loader, Segment} from "semantic-ui-react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {NodeInfo} from "../../../../components/NodeInfo";
import {useFindNodesQuery, useGetNodeQuery} from "../../../../apis/nodes.api";
import {NodeStatusEnum} from "../../../../interfaces/node-status.enum";
import {VersionsList} from "../../../../components/node/VersionsList";
import {groupBy} from "lodash";

export function NodePage() {
    const {nodeUuid, nodeVersion} = useParams();
    const {data: node, isLoading, isError} = useGetNodeQuery({nodeUuid, nodeVersion} as any);
    const {data: nodes} = useFindNodesQuery({
        uuid: nodeUuid,
        status: NodeStatusEnum.PUBLIC,
        limit: -1,
        getUsages: true
    })

    return (
        <>
            {node ? (
                <Container>
                    <h2 style={{marginTop: '2em'}}>{node.name}</h2>
                    <Divider hidden/>
                    <Card fluid>
                        <Card.Content>
                            <Grid columns={2}>
                                <Grid.Column width={10}>
                                    <NodeInfo node={node} basic extended/>
                                    <Divider hidden/>
                                    <h4>Node version history</h4>
                                    <Divider/>
                                    {nodes?.data && <VersionsList nodes={nodes.data} asDeveloper/>}
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <Button fluid icon="play" basic color="black" content="Debug"
                                            as={Link}
                                            to={`/marketplace/nodes/view/${node.uuid}/${node.version}`}
                                            style={{margin: '5px 0 0 0'}}/>
                                </Grid.Column>
                            </Grid>
                        </Card.Content>
                    </Card>
                </Container>
            ) : (
                <Dimmer inverted active={isLoading}><Loader/></Dimmer>
            )}
        </>

    )
}