import {Button, Icon, Item, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {CreateInstanceModal} from "../instance/CreateInstanceModal";
import {RuleInterface} from "../../interfaces/rule.interface";

export function VersionsList({rules, asDeveloper}: { rules?: RuleInterface[]; asDeveloper?: boolean }) {

    return (
        <Segment>
            <Item.Group divided>
                {rules?.map((rule, i) => (
                    <Item key={`rule-${i}`}>
                        <Item.Content>
                            <Item.Header
                                as={Link}
                                to={`${rule.uuid}/${rule.version}`}
                            >v{rule.version}</Item.Header>
                            <Item.Description>
                                Release description:<br/>{rule.commitMessage || 'Release description was not provided'}
                            </Item.Description>
                            <Item.Extra>
                                <div style={{paddingTop: '1.5em'}}>
                                    <Icon name="clock outline"/>last
                                    update: {new Date(rule.updatedAt).toLocaleDateString()},&nbsp;
                                    {asDeveloper ? 'instances' : 'installations'}: {rule.instanceCount}
                                </div>
                            </Item.Extra>
                        </Item.Content>
                        <Item.Content style={{flex: '0 0 200px'}}>
                            <CreateInstanceModal ruleUuid={rule.uuid} ruleVersion={rule.version}>
                                <Button fluid primary icon="plus" content={asDeveloper ? 'Create an instance' : 'Install'}/>
                            </CreateInstanceModal>
                            <Button fluid basic color="black" icon="play" content={asDeveloper ? 'Debug' : 'Try'} as={Link}
                                    to={`/rules/releases/${rule.uuid}/${rule.version}`} style={{margin: '5px 0 0 0'}}/>
                            {asDeveloper && (
                                <Button
                                    fluid
                                    basic
                                    color="black"
                                    icon="edit"
                                    content="Manage instances"
                                    style={{margin: '5px 0 0 0'}}
                                    as={Link}
                                    to={`../instances/${rule.version}`}
                                />
                            )}
                        </Item.Content>
                    </Item>
                ))}
            </Item.Group>
        </Segment>
    );
}
