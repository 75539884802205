import {Layout} from "../../components/Layout";
import {Divider, Icon, Menu, Segment} from "semantic-ui-react";
import {NavLink, useParams, Outlet} from "react-router-dom";
import {useAppSelector} from "../../store";

export function Homepage({section}: { section: string }) {
    const {organization} = useParams();
    const org = useAppSelector(state => state.auth.org);

    return (
        <Layout
            leftSidebarContent={(
                <Segment basic>
                    <Menu vertical fluid secondary size="large" style={{margin: 0}}>
                        <Menu.Item
                            as={NavLink}
                            to="/marketplace/rules"
                            className={section === 'marketplace' ? 'active' : undefined}
                        >
                            <span>
                                <Icon name="shop"/>
                                &nbsp;Marketplace
                            </span>
                        </Menu.Item>
                    </Menu>
                    <Divider/>
                    <Menu vertical fluid secondary size="large" style={{margin: 0}}>
                        {org && (
                            <>
                                <Menu.Item
                                    as={NavLink}
                                    to={`/org/${org.name}/rules/drafts/recent`}
                                    className={section === 'rules' && !!organization ? 'active' : undefined}
                                >
                                    <span>
                                        <Icon name="sitemap"/>
                                        &nbsp;Rules
                                    </span>
                                </Menu.Item>
                                <Menu.Item
                                    as={NavLink}
                                    to={`/org/${org.name}/nodes/drafts/recent`}
                                    className={section === 'nodes' && !!organization ? 'active' : undefined}
                                >
                                    <span>
                                        <Icon name="code"/>
                                        &nbsp;Nodes
                                    </span>
                                </Menu.Item>
                                <Menu.Item
                                    as={NavLink}
                                    to={`/org/${org.name}/triggers`}
                                    className={section === 'triggers' && !!organization ? 'active' : undefined}
                                >
                                    <span>
                                        <Icon name="lightning"/>
                                        &nbsp;Triggers
                                    </span>
                                </Menu.Item>
                            </>
                        )}
                    </Menu>
                </Segment>
            )}
            mainContent={<Outlet/>}
        />
    );
}