import {RuleNodeInterface} from "../interfaces/rule-node.interface";
import {Handle, Position} from "reactflow";
import {memo} from "react";
import {useFindTriggersQuery} from "../apis/triggers.api";
import {Button, Loader} from "semantic-ui-react";
import {FireInstanceModal} from "./instance/FireInstanceModal";
import {useAppSelector} from "../store";
import {Hint} from "./Hint";
import './FlowNode.scss';

export const FlowTriggerNode = memo(({data, isConnectable, type}: {
    data: {
        data: RuleNodeInterface
    };
    isConnectable: boolean;
    type: string;
}) => {
    // const {data: triggers, isLoading: isTriggersLoading} = useFindTriggersQuery({limit: -1});
    const triggers = useAppSelector(state => state.editor.triggers);
    const currentInstance = useAppSelector(state => state.debug.currentInstance);
    const originalTrigger = triggers.find(item => item.uuid === data.data.nodeUuid);
    const event = originalTrigger?.event
        ?.replace('{instanceUuid}', currentInstance?.uuid || '{instanceUuid}')
        .replace(/\<(.*?)\>/g, (match: string, contents: string): any => {
            return currentInstance?.triggerParams?.[contents] || match;
        });

    return (
        <>
            <div
                title={originalTrigger?.name + '\n' + event}
                style={{display: 'flex', flexDirection: 'column', alignItems: 'start', lineHeight: 1.2, width: '100%', paddingLeft: 24}}
            >
                {!originalTrigger ? (
                    <Loader size="mini" inline active />
                ) : (
                    <>
                        <div style={{whiteSpace: 'nowrap', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {originalTrigger?.name}
                        </div>
                        <div style={{fontSize: '80%', textAlign: 'left', marginTop: 0, opacity: 0.5, whiteSpace: 'nowrap', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {event}
                        </div>
                        {data.data.description && (
                            <div style={{fontSize: '80%', textAlign: 'left', marginTop: 5, opacity: 0.5, lineHeight: 1, whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                                {data.data.description}
                            </div>
                        )}
                        {currentInstance ? (
                            <FireInstanceModal instance={currentInstance} triggerUuid={originalTrigger.uuid}>
                                <Button
                                    icon="play"
                                    size="mini"
                                    color="teal"
                                    circular
                                    style={{
                                        marginTop: '0.75em', fontSize: '0.75em',
                                        position: 'absolute',
                                        top: 'calc(50% - 18px)',
                                        left: 6
                                    }}
                                />
                            </FireInstanceModal>
                        ) : (
                            <Hint
                                trigger={
                                    <Button
                                        icon="play"
                                        size="mini"
                                        color="teal"
                                        circular
                                        style={{
                                            marginTop: '0.75em', fontSize: '0.75em',
                                            position: 'absolute',
                                            top: 'calc(50% - 18px)',
                                            left: 6,
                                        }}
                                        onMouseDownCapture={(e: any) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    />
                                }
                                content="Select or create rule instance first"
                                position="right center"
                            />
                        )}
                    </>
                )}
            </div>
            <Handle
                type="source"
                position={Position.Bottom}
                style={{background: '#555'}}
                isConnectable={isConnectable}
            />
        </>
    );
})
