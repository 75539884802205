import {Outlet} from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './App.scss';

function App() {

    return (
        <Outlet/>
    );
}

export default App;
