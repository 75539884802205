export function stringifyValue(value: any) {
    return typeof value === 'object' ? JSON.stringify(value, null, 2) : value;
}

export function floatify(value: any) {
    return value?.toString().match(/^\d+(\.\d+)?$/) ? parseFloat(value) : value;
}

export function createContextFromEvent(e: MouseEvent) {
    const left = e.clientX
    const top = e.clientY
    const right = left + 1
    const bottom = top + 1

    return {
        getBoundingClientRect: () => ({
            left,
            top,
            right,
            bottom,

            height: 0,
            width: 0,
        }),
    }
}
