import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface MarketplaceStateInterface {
    title: string;
}

const initialState: MarketplaceStateInterface = {
    title: ''
}

export const marketplaceSlice = createSlice({
    name: 'marketplace',
    initialState,
    reducers: {
        setTitle: (draft: MarketplaceStateInterface, action: PayloadAction<string>) => {
            draft.title = action.payload;
        }
    },
});

export const {setTitle} = marketplaceSlice.actions;

export const marketplaceReducer = marketplaceSlice.reducer;