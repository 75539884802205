import {Dimmer, Loader} from "semantic-ui-react";
import {useGetTriggerQuery} from "../../apis/triggers.api";
import {useParams} from "react-router-dom";
import {Layout} from "../../components/Layout";
import PerfectScrollbar from "react-perfect-scrollbar";
import SyntaxHighlighter from "react-syntax-highlighter/dist/cjs/light";
import json from "react-syntax-highlighter/dist/esm/languages/hljs/json";
import {useEffect} from "react";
import {useAppDispatch} from "../../store";
import {Help} from "./Help";
import {Editor} from "./Editor";

SyntaxHighlighter.registerLanguage('json', json);

export function TriggerEditor() {
    const {triggerUuid} = useParams();
    const {data: trigger} = useGetTriggerQuery(triggerUuid || '', {skip: !triggerUuid || triggerUuid === 'new'})
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            // dispatch(resetEditor());
        }
    }, []);

    const schema = {
        "openapi": "3.0.0",
        "info": {
            "version": "1.0.0",
            "title": "Metrics-generated"
        },
        "paths": {},
        "components": {
            "schemas": {
                "AWSEvent": {
                    "type": "object",
                    "required": ["detail-type", "resources", "detail", "id", "source", "time", "region", "version", "account"],
                    "x-amazon-events-detail-type": "metrics:generated",
                    "x-amazon-events-source": "app.cycles",
                    "properties": {
                        "detail": {
                            "$ref": "#/components/schemas/Metrics-generated"
                        },
                        "account": {
                            "type": "string"
                        },
                        "detail-type": {
                            "type": "string"
                        },
                        "id": {
                            "type": "string"
                        },
                        "region": {
                            "type": "string"
                        },
                        "resources": {
                            "type": "array",
                            "items": {
                                "type": "object"
                            }
                        },
                        "source": {
                            "type": "string"
                        },
                        "time": {
                            "type": "string",
                            "format": "date-time"
                        },
                        "version": {
                            "type": "string"
                        }
                    }
                },
                "Metrics-generated": {
                    "type": "object",
                    "required": ["data"],
                    "properties": {
                        "data": {
                            "$ref": "#/components/schemas/Data"
                        }
                    }
                },
                "Data": {
                    "type": "object",
                    "required": ["3"],
                    "properties": {
                        "3": {
                            "type": "array",
                            "items": {
                                "$ref": "#/components/schemas/DataItem"
                            }
                        }
                    }
                },
                "DataItem": {
                    "type": "object",
                    "required": ["metricType", "index", "deviceId", "measuredAtTimezoneOffsetMin", "measuredAtUtc", "userId", "value"],
                    "properties": {
                        "deviceId": {
                            "type": "number"
                        },
                        "index": {
                            "type": "number"
                        },
                        "measuredAtTimezoneOffsetMin": {
                            "type": "number"
                        },
                        "measuredAtUtc": {
                            "type": "string",
                            "format": "date-time"
                        },
                        "metricType": {
                            "type": "number"
                        },
                        "userId": {
                            "type": "number"
                        },
                        "value": {
                            "type": "number",
                            "format": "double"
                        }
                    }
                }
            }
        }
    };

    return (
        <Layout
            leftSidebarContent={(
                <PerfectScrollbar>
                    <Help />
                </PerfectScrollbar>
            )}
            mainContent={(
                trigger || triggerUuid === 'new' ? (
                    <Editor trigger={trigger} />
                ) : (
                    <Dimmer active inverted><Loader /></Dimmer>
                )
            )}
        />
    )
}