import {NodeInterface} from "../../interfaces/node.interface";
import {Button, Dimmer, Form, Loader, Message} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {useUpdateNodeMutation} from "../../apis/nodes.api";
import {paramTypes} from "../../helpers/params";
import {set} from "lodash";
import {NodeTypeEnum} from "../../interfaces/node-type.enum";

export function NodeGeneralOptions({node}: { node: NodeInterface }) {
    const [updateNode, {isLoading, isSuccess, isError, error}] = useUpdateNodeMutation();

    const [formData, setFormData] = useState({
        name: node.name,
        description: node.description,
        type: node.type,
        category: node.category,
        tags: node.tags?.join(', '),
        output: {
            type: node.output.type,
            of: node.output.of,
            description: node.output.description,
        }
    });

    useEffect(() => {
        setFormData({
            name: node.name,
            description: node.description,
            type: node.type,
            category: node.category,
            tags: node.tags?.join(', '),
            output: {
                type: node.output.type,
                of: node.output.of,
                description: node.output.description,
            }
        });
    }, [node]);

    function save() {
        updateNode({
            uuid: node.uuid,
            version: node.version,
            ...formData,
            tags: formData.tags?.split(/[\s,]/).map(i => i.trim()).filter(i => !!i),
        });
    }

    function handleChange(e: any, data: any) {
        let value = data.value;
        if (data.name === 'output.of') {
            value = data.value || null;
        }
        setFormData(set({...formData}, data.name, value));
    }

    return (
        <>
            <Form>
                <Dimmer active={isLoading} inverted><Loader inverted/></Dimmer>
                {isError && !(error as any)?.data?.errors && <Message content={(error as any)?.data?.error?.description} />}
                <Form.Input
                    label="Title"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={(error as any)?.data?.errors?.name}
                />
                <Form.TextArea
                    label="Description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    error={(error as any)?.data?.errors?.description}
                />
                <Form.Input
                    label="Category (optional)"
                    type="text"
                    name="category"
                    value={formData.category}
                    error={(error as any)?.data?.errors?.category?.[0]}
                    onChange={handleChange}
                />
                <Form.Input
                    label="Tags (optional, separated by comma or space)"
                    type="text"
                    name="tags"
                    value={formData.tags}
                    error={(error as any)?.data?.errors?.tags?.[0]}
                    onChange={handleChange}
                />
                <Form.Select
                    label="Node type"
                    options={[
                        {key: NodeTypeEnum.SYSTEM, value: NodeTypeEnum.SYSTEM, text: 'System'},
                        {key: NodeTypeEnum.CUSTOM, value: NodeTypeEnum.CUSTOM, text: 'Default'},
                        {key: NodeTypeEnum.STICKY, value: NodeTypeEnum.STICKY, text: 'Sticky note'},
                        {key: NodeTypeEnum.SCRATCHPAD_STATE, value: NodeTypeEnum.SCRATCHPAD_STATE, text: 'Scratchpad state'},
                        {key: NodeTypeEnum.INSTANCE_INFO, value: NodeTypeEnum.INSTANCE_INFO, text: 'Instance info'},
                    ]}
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    closeOnChange
                    error={(error as any)?.data?.errors?.['type']}
                />
                <Form.Select
                    label="Result type"
                    options={paramTypes}
                    name="output.type"
                    value={formData.output.type}
                    onChange={handleChange}
                    clearable
                    closeOnChange
                    error={(error as any)?.data?.errors?.['output.type']}
                />
                <Form.Select
                    label="Result of"
                    options={paramTypes}
                    name="output.of"
                    value={formData.output.of}
                    onChange={handleChange}
                    clearable
                    closeOnChange
                    error={(error as any)?.data?.errors?.['output.of']}
                />
                <Form.TextArea
                    label="Result description"
                    name="output.description"
                    value={formData.output.description}
                    onChange={handleChange}
                    error={(error as any)?.data?.errors?.['output.description']}
                />
                <Button fluid color="teal" content="Update" onClick={() => save()} />
            </Form>
        </>
    );
}