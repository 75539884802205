import PerfectScrollbar from "react-perfect-scrollbar";
import {NodesList} from "../../components/NodesList";
import {Flow} from "../../components/Flow";
import {Debug} from "../../components/debug/Debug";
import {NodeParams} from "../../components/params/NodeParams";
import {EdgeParams} from "../../components/params/EdgeParams";
import {Layout} from "../../components/Layout";
import {useAppDispatch, useAppSelector} from "../../store";
import {useEffect, useState} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import {useGetRuleQuery} from "../../apis/rules.api";
import {
    Dimmer,
    Loader,
    Segment,
    Tab,
    Button,
    Label, Message,
} from "semantic-ui-react";
import {NodeInfo} from "../../components/NodeInfo";
import {Header} from "./Header";
import {TriggersList} from "../../components/TriggersList";
import {RuleNodeTypeEnum} from "../../interfaces/rule-node.interface";
import {RuleEdgeTypeEnum} from "../../interfaces/rule-edge.interface";
import {TriggerParams} from "../../components/params/TriggerParams";
import {InstanceControls} from "./components/InstanceControls";
import {DataLoader} from "./DataLoader";
import {setCurrentInstance, stopDebug} from "../../slices/debug.slice";
import {reset} from "../../slices/editor.slice";
import {setActiveNodeUuid, setPosition, setZoom} from "../../slices/flow.slice";
import {RuleControls} from "./components/RuleControls";

const sidebarContentStyles: any = {
    position: 'absolute',
    zIndex: 1,
    inset: '0 0 0 0',
    height: '100%',
    background: 'white',
}

export function RuleEditor() {
    const ready = useAppSelector(state => state.editor.ready);
    const flow = useAppSelector(state => state.flow.flow);
    const activeNodeUuid = useAppSelector(state => state.flow.activeNodeUuid);
    const nodeInfoUuid = useAppSelector(state => state.flow.nodeInfoUuid);
    const selectNodeOutputMode = useAppSelector(state => state.flow.selectNodeOutputMode);
    const debugModeEnabled = useAppSelector(state => state.debug.debugModeEnabled);
    const activeNode = flow?.find(node => node.uuid === activeNodeUuid);
    const {ruleUuid, ruleVersion} = useParams();
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();

    const {data: activeRule, isError, error, isFetching: isRuleFetching, refetch: refetchRule} = useGetRuleQuery({
        ruleUuid: ruleUuid as string,
        ruleVersion: parseInt(ruleVersion as string),
        getDependencies: true,
        getPermissions: true
    }, {
        refetchOnMountOrArgChange: true
    });

    useEffect(() => {
        setLoading(!activeRule || isRuleFetching || !ready);
    }, [isRuleFetching, activeRule, ready]);

    useEffect(() => {
        dispatch(setPosition());
        dispatch(setZoom());

        return () => {
            dispatch(reset());
            dispatch(stopDebug());
            dispatch(setCurrentInstance());
            dispatch(setActiveNodeUuid(undefined));
        }
    }, []);

    return (
        <>
            {/*<Dimmer active >*/}
            {/*    <Message*/}
            {/*        header={'Rule is locked'}*/}
            {/*        content={<div style={{paddingTop: '0.5em'}}>*/}
            {/*            This rule is currently being edited by another user<br />*/}
            {/*            You can wait or <Link to={'/rules'}>back to the homepage</Link>.*/}
            {/*        </div>}*/}
            {/*        style={{padding: '3em'}}*/}
            {/*    />*/}
            {/*</Dimmer>*/}
            <Layout
                muted={selectNodeOutputMode}
                headerContent={<Header muted={selectNodeOutputMode} debugModeEnabled={debugModeEnabled}/>}
                noPadding
                mainContent={<>
                    <DataLoader ruleUuid={ruleUuid} ruleVersion={Number(ruleVersion)}/>
                    {
                        loading || !activeRule ? (
                            <Dimmer active inverted>
                                {isError && (error as any)?.status === 403 && <Navigate to="/forbidden" replace />}
                                {isError && (error as any)?.status !== 403 && (
                                    <Message
                                        error
                                        header={'Error'}
                                        content={<>
                                            {(error as any)?.data?.error?.description || 'Unknown error'}<br />
                                            Try again or <Link to={'/rules'}>back to homepage</Link>
                                        </>}
                                        style={{color: 'black', textAlign: 'left'}}
                                    />
                                )}
                                {loading && <Loader/>}
                            </Dimmer>
                        ) : (
                            <>
                                <RuleControls rule={activeRule} debugModeEnabled={debugModeEnabled} />
                                <InstanceControls rule={activeRule}/>

                                {!loading && activeRule && <Flow rule={activeRule}/>}
                            </>
                        )
                    }
                </>}
                rightSidebarContent={loading || !activeRule ? undefined : (
                    <>
                        <div style={{...sidebarContentStyles}}>
                            <PerfectScrollbar className={debugModeEnabled ? 'waiting' : ''}>
                                <Segment basic>
                                    <Tab
                                        renderActiveOnly={true}
                                        menu={{secondary: true, pointing: true}}
                                        panes={[
                                            {
                                                menuItem: 'Nodes',
                                                render: () => <NodesList/>
                                            },
                                            {
                                                menuItem: 'Triggers',
                                                render: () => <TriggersList/>
                                            },
                                        ]}
                                    />
                                </Segment>
                            </PerfectScrollbar>
                        </div>

                        <div style={{...sidebarContentStyles, zIndex: debugModeEnabled ? 2 : undefined}}>
                            <Debug/>
                        </div>

                        <div style={{
                            ...sidebarContentStyles,
                            zIndex: activeNode?.type === RuleNodeTypeEnum.NODE ? 2 : undefined
                        }}>
                            <NodeParams rule={activeRule}/>
                        </div>

                        <div style={{
                            ...sidebarContentStyles,
                            zIndex: activeNode?.type === RuleEdgeTypeEnum.EDGE ? 2 : undefined
                        }}>
                            <EdgeParams rule={activeRule}/>
                        </div>

                        <div style={{
                            ...sidebarContentStyles,
                            zIndex: activeNode?.type === RuleNodeTypeEnum.TRIGGER ? 2 : undefined
                        }}>
                            <TriggerParams rule={activeRule}/>
                        </div>

                        <div style={{...sidebarContentStyles, zIndex: nodeInfoUuid ? 2 : undefined}}>
                            <NodeInfo nodeUuid={nodeInfoUuid}/>
                        </div>
                    </>
                )}
                // rightSidebarClassName={debugModeEnabled ? 'sidebar-right--lg' : ''}
            />
        </>
    );
}