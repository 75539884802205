import {Container, Dimmer, Divider, Loader, Message, Segment} from "semantic-ui-react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useEffect} from "react";
import {useFindTriggersQuery} from "../../apis/triggers.api";
import {TriggersList} from "./TriggersList";
import {useParams} from "react-router-dom";
import {useAppSelector} from "../../store";

export function Triggers() {
    const {organization} = useParams();
    const auth = useAppSelector((state) => state.auth);
    const org = useAppSelector(state => state.auth.org);
    const {data: triggers, isError, error, isLoading, refetch} = useFindTriggersQuery({
        createdBy: organization ? undefined : auth.accountId,
        orgId: org?.id,
    });

    useEffect(() => {
        refetch();
    }, []);

    return (
        <>
            {isLoading && <Dimmer active inverted style={{backgroundColor: 'transparent'}}>
                <Loader inverted content='Loading'/></Dimmer>
            }
            <PerfectScrollbar>
                <Segment basic>
                    <Container>
                        <h1>{organization ? `Triggers` : 'My Triggers'}</h1>
                        <Divider hidden/>
                        {isError &&
                            <Message error content={(error as any).data?.error?.description || 'Unknown error'}/>}
                        {!isLoading && triggers?.data && (
                            <TriggersList triggers={triggers.data} create={true}/>
                        )}
                    </Container>
                </Segment>
            </PerfectScrollbar>
        </>
    )
}