import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LogsInterface} from "../interfaces/logs.interface";
import {DebugDataInterface} from "../interfaces/debug-data.interface";
import {InstanceInterface} from "../interfaces/instance.interface";
import {instancesApi, useGetInstanceQuery} from "../apis/instances.api";

export interface DebugSliceInterface {
    currentInstance?: InstanceInterface;
    debugModeEnabled: boolean;
    debugNodeUuid?: string;
    logs: LogsInterface[];
    debugUuid?: string;
    debugData: DebugDataInterface[];
    selectedIteration: number;
}

const initialState: DebugSliceInterface = {
    currentInstance: undefined,
    debugModeEnabled: false,
    debugNodeUuid: undefined,
    logs: [],
    debugUuid: undefined,
    debugData: [],
    selectedIteration: 0
}

export const debugSlice = createSlice({
    name: 'debug',
    initialState,
    reducers: {
        setCurrentInstance: (state, action: PayloadAction<InstanceInterface | undefined>) => {
            state.currentInstance = action.payload;
        },
        startDebug: (state) => {
            state.debugModeEnabled = true;
            state.debugNodeUuid = undefined;
            state.logs = [];
            state.selectedIteration = 0;
        },
        stopDebug: (state) => {
            state.debugModeEnabled = false;
            state.debugNodeUuid = undefined;
            state.logs = [];
            state.debugData = [];
        },
        setDebugNodeUuid: (state, action) => {
            state.debugNodeUuid = action.payload;
        },
        setDebugLogs: (state, action: PayloadAction<LogsInterface[]>) => {
            state.logs = action.payload;
            state.selectedIteration = 0;
        },
        setDebugUuid: (state, action: PayloadAction<string | undefined>) => {
            state.debugUuid = action.payload;
        },
        setDebugData: (state, action: PayloadAction<DebugDataInterface[]>) => {
            state.debugData = action.payload;
            state.selectedIteration = 0;
        },
        setDebugIteration: (state, action: PayloadAction<number>) => {
            state.selectedIteration = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(instancesApi.endpoints.getInstance.matchFulfilled, (state, action) => {
            if (action.payload.uuid === state.currentInstance?.uuid) {
                state.currentInstance = action.payload;
            }
        })
        builder.addMatcher(instancesApi.endpoints.updateInstance.matchFulfilled, (state, action) => {
            if (action.payload.uuid === state.currentInstance?.uuid) {
                state.currentInstance = action.payload;
            }
        })
    }
});

export const {startDebug, stopDebug, setDebugNodeUuid, setDebugLogs, setDebugUuid, setDebugData, setDebugIteration, setCurrentInstance} = debugSlice.actions;

export const debugReducer = debugSlice.reducer;