import {InstanceInterface} from "../../interfaces/instance.interface";
import {useFindDebugDataQuery, useGetDebugDataQuery} from "../../apis/instances.api";
import {Button, Dimmer, Divider, List, Loader, Message, Pagination, Segment} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {setDebugData, startDebug} from "../../slices/debug.slice";
import {useAppDispatch, useAppSelector} from "../../store";
import {FireInstanceModal} from "../../components/instance/FireInstanceModal";
import {ViewDebugModal} from "../../components/debug/ViewDebugModal";
import {RuleInterface} from "../../interfaces/rule.interface";

export function InstanceLogs({rule, instance, onBack, size = 'default', debugInModal = false}: {
    rule: RuleInterface;
    instance: InstanceInterface;
    onBack: () => void;
    size?: 'default' | 'mini';
    debugInModal?: boolean;
}) {
    const dispatch = useAppDispatch();
    const [page, setPage] = useState(1);
    const [debugUuid, setDebugUuid] = useState<string>();
    const {data, isLoading, isFetching, isError, error, refetch} = useFindDebugDataQuery({
        instanceUuid: instance.uuid,
        page: page,
        limit: 15
    });
    const debugDataQuery = useGetDebugDataQuery(debugUuid as string, {skip: !debugUuid});
    const debugModeEnabled = useAppSelector(state => state.debug.debugModeEnabled);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (debugDataQuery.isSuccess && debugDataQuery.data) {
            dispatch(startDebug());
            dispatch(setDebugData([debugDataQuery.data]));
        }
    }, [debugDataQuery.isSuccess, debugDataQuery.data]);

    useEffect(() => {
        if (!debugModeEnabled) {
            setDebugUuid(undefined);
            dispatch(setDebugData([]));
        }
    }, [debugModeEnabled]);

    useEffect(() => {
        document.querySelector('#instance-logs-list')?.closest('.scrollbar-container')?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [page]);

    return (
        <>
            {debugUuid && debugInModal && <ViewDebugModal ruleUuid={rule.uuid} ruleVersion={rule.version} debugUuid={debugUuid} onClose={() => setDebugUuid(undefined)} />}
            <Segment basic={size === 'mini'} style={size === 'mini' ? {padding: 0, minHeight: '3.5em'} : {minHeight: '3.5em'}}>
                <Button icon={'arrow left'} onClick={() => onBack()}/>
                <FireInstanceModal instance={instance}>
                    <Button content="Fire instance" icon={'lightning'} floated="right"/>
                </FireInstanceModal>
                <Divider/>
                {isError && <Message error content={(error as any)?.data?.error?.description || 'Unknown error'}/>}
                {debugDataQuery.isError &&
                    <Message error content={(debugDataQuery.error as any)?.data?.error?.description || 'Unknown error'}/>}
                <Dimmer active={isFetching || debugDataQuery.isFetching} inverted><Loader/></Dimmer>
                <List divided style={{position: 'relative'}}>
                    {data?.data.map((instanceRun, i) => (
                        <List.Item style={{padding: '10px 0'}} key={`item-${i}`}>
                            <List.Content floated="right">
                                {new Date().getTime() - new Date(instanceRun.startedAt).getTime() > 1000 * 60 * 60 * 24 * 30 ? (
                                    <p>Debug data not available</p>
                                ) : (
                                    <Button content="Debug" size="mini" onClick={() => setDebugUuid(instanceRun.uuid)}/>
                                )}
                            </List.Content>
                            <List.Content>
                                <p style={{marginTop: 5}}>{new Date(instanceRun.startedAt).toLocaleString()}</p>
                                <div style={{opacity: 0.5, fontSize: 12}}>
                                    Duration: {instanceRun.duration}ms
                                </div>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            </Segment>
            {!!(data?.pagination?.total && data?.pagination?.limit && data?.pagination?.page) && (
                <Pagination
                    style={size === 'mini' ? {display: 'flex', justifyContent: 'center'} : {}}
                    fluid={size === 'mini'}
                    size={size === 'mini' ? 'mini' : undefined}
                    activePage={page}
                    totalPages={Math.ceil(data.pagination.total / data.pagination.limit)}
                    onPageChange={((event, {activePage}) => setPage(activePage as number))}
                    firstItem={false}
                    lastItem={false}
                    siblingRange={0}
                />
            )}
        </>
    );
}