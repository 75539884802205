import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {RootState} from "../store";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: ((headers, { getState }) => {
        const token = (getState() as RootState).auth.accessToken;
        headers.set('Authorization', `Bearer ${token}`)
        return headers;
    })
});