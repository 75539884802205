import {Container, Divider, Segment, Tab} from "semantic-ui-react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {NavLink, Outlet} from 'react-router-dom';
import {useAppSelector} from "../../store";

export function Marketplace() {
    const title = useAppSelector(state => state.marketplace.title);

    return (
        <PerfectScrollbar>
            <Segment basic style={{margin: 0}}>
                <Container>
                    <h1>{title}</h1>
                    <Divider hidden/>
                    <Tab menu={{secondary: true, pointing: true}}
                         renderActiveOnly={false}
                         activeIndex={-1}
                         panes={[{
                             menuItem: {
                                 as: NavLink,
                                 content: 'Rules',
                                 to: '/marketplace/rules',
                                 key: 'rules'
                             }
                         }, {
                             menuItem: {
                                 as: NavLink,
                                 content: 'Nodes',
                                 to: '/marketplace/nodes',
                                 key: 'nodes'
                             }
                         }]}
                         style={{marginBottom: '2em'}}
                    />
                    <Outlet/>
                </Container>
            </Segment>
        </PerfectScrollbar>
    )
}