import {createApi} from '@reduxjs/toolkit/query/react';
import {InstanceInterface} from "../interfaces/instance.interface";
import {baseQuery} from "../helpers/base-query";
import {CreateInstanceInterface} from "../interfaces/create-instance.interface";
import {PaginationInterface} from "../interfaces/pagination.interface";
import {InstanceRunInterface} from "../interfaces/instance-run.interface";
import {DebugDataInterface} from "../interfaces/debug-data.interface";

export const instancesApi = createApi({
    reducerPath: 'instancesApi',
    baseQuery: baseQuery,
    tagTypes: ['Instance', 'Instances', 'Scratchpad', 'DebugDataItem', 'DebugData'],
    endpoints: (builder) => ({
        findInstances: builder.query<{
            data: InstanceInterface[];
            pagination: PaginationInterface;
        }, {
            ruleUuid: string;
            ruleVersion: number;
            page?: number;
            limit?: number;
        }>({
            providesTags: ['Instances'],
            query: (params) => ({
                url: 'instances',
                params
            })
        }),

        getInstance: builder.query<InstanceInterface, string>({
            query: (ruleInstanceUuid) => `instances/${ruleInstanceUuid}`,
            providesTags: ['Instance'],
        }),

        getTriggerInstances: builder.query<{
            data: {
                triggerInstanceUuid: string;
                triggerInstanceEvent: string;
                triggerUuid: string;
                triggerName: string;
                triggerEvent: string;
                triggerVersion: number;
            }[]
        }, string>({
            query: (ruleInstanceUuid) => `instances/${ruleInstanceUuid}/triggers`
        }),

        createInstance: builder.mutation<InstanceInterface, CreateInstanceInterface>({
            query: (body) => ({
                url: `instances`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Instances'],
        }),

        updateInstance: builder.mutation<InstanceInterface, Partial<Pick<CreateInstanceInterface, 'ruleParams' | 'triggerParams'>> & { ruleInstanceUuid: string }>({
            query: ({ruleInstanceUuid, ...body}) => ({
                url: `instances/${ruleInstanceUuid}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Instances', 'Instance'],
            async onQueryStarted({ruleInstanceUuid, ...patch}, {dispatch, queryFulfilled}) {
                const {data: updatedInstance} = await queryFulfilled;
                dispatch(
                    instancesApi.util.updateQueryData('getInstance', ruleInstanceUuid, (draft) => {
                        Object.assign(draft, updatedInstance)
                    })
                );
            },
        }),

        switchVersion: builder.mutation<{ updatedCount: number }, {
            ruleUuid: string;
            fromVersion: number;
            toVersion: number;
        }>({
            query: (body) => ({
                url: `instances/switch`,
                method: 'POST',
                body
            }),
            invalidatesTags: (_result, error) => {
                return error ? [] : ['Instances'];
            },
        }),

        deleteInstance: builder.mutation<void, string>({
            query: (ruleInstanceUuid) => ({
                url: `instances/${ruleInstanceUuid}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Instances'],
        }),

        deleteAllInstances: builder.mutation<void, {
            ruleUuid: string;
            ruleVersion: number;
        }>({
            query: (body) => ({
                url: `instances/delete`,
                method: 'POST',
                body
            }),
            invalidatesTags: (_result, error) => {
                return error ? [] : ['Instances'];
            },
        }),

        fireInstance: builder.mutation<any, {
            ruleInstanceUuid: string;
            triggerInstanceUuid: string;
            eventData: object;
        }>({
            query: ({ruleInstanceUuid, triggerInstanceUuid, eventData}) => ({
                url: `instances/${ruleInstanceUuid}/triggers/${triggerInstanceUuid}`,
                method: 'POST',
                body: {eventData}
            }),
            invalidatesTags: ['Instance', 'Instances', 'DebugDataItem', 'Scratchpad'],
        }),

        getScratchpad: builder.query<object, string>({
            query: (ruleInstanceUuid) => `instances/${ruleInstanceUuid}/scratchpad`,
            providesTags: ['Scratchpad'],
        }),

        resetScratchpad: builder.mutation<void, string>({
            query: (ruleInstanceUuid) => ({
                url: `instances/${ruleInstanceUuid}/scratchpad`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Scratchpad'],
        }),

        findDebugData: builder.query<{
            data: InstanceRunInterface[];
            pagination: PaginationInterface;
        }, {
            instanceUuid: string
            page?: number;
            limit?: number;
        }>({
            query: ({instanceUuid, page, limit}) => `instances/debug?instanceUuid=${instanceUuid}&page=${page || 1}&limit=${limit || 20}`,
            providesTags: ['DebugDataItem'],
        }),

        getDebugData: builder.query<DebugDataInterface, string>({
            query: (uuid) => `instances/debug/${uuid}`,
            providesTags: ['DebugData'],
        })
    }),
})

export const {
    useFindInstancesQuery,
    useGetInstanceQuery,
    useGetTriggerInstancesQuery,
    useCreateInstanceMutation,
    useUpdateInstanceMutation,
    useSwitchVersionMutation,
    useDeleteInstanceMutation,
    useDeleteAllInstancesMutation,
    useFireInstanceMutation,
    useGetScratchpadQuery,
    useResetScratchpadMutation,
    useFindDebugDataQuery,
    useGetDebugDataQuery
} = instancesApi;