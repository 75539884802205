import {Button, Form, Grid, Menu, Modal} from "semantic-ui-react";
import {ParamInterface} from "../../interfaces/param.interface";
import {flatten, intersection, isArray, isBoolean, isNumber, isObject, isString} from "lodash";
import {RuleInterface} from "../../interfaces/rule.interface";
import {useAppDispatch, useAppSelector} from "../../store";
import {useEffect, useState} from "react";
import {Mentions} from "../mentions/Mentions";
import {getVarsList} from "../../helpers/vars";
import {ParamTypeEnum} from "../../interfaces/param-type.enum";
import {JsonEditor} from "../JsonEditor";
import {setSelectedNodeOutput, setSelectNodeOutputMode} from "../../slices/flow.slice";
import {useFindTriggersQuery} from "../../apis/triggers.api";

export function SetValueModal({open, rule, param, value, onChange, onClose}: {
    open: boolean;
    rule: RuleInterface;
    param: ParamInterface;
    value: any;
    onChange: (param: ParamInterface, value: any) => void;
    onClose: () => void;
}) {
    const nodes = useAppSelector(state => state.editor.nodes);
    const triggers = useAppSelector(state => state.editor.triggers);
    const flow = useAppSelector(state => state.flow.flow);
    const selectNodeOutputMode = useAppSelector(state => state.flow.selectNodeOutputMode);
    const selectedNodeOutput = useAppSelector(state => state.flow.selectedNodeOutput);
    const [activeTab, setActiveTab] = useState(0);
    const [currentValue, setCurrentValue] = useState(value);
    const [error, setError] = useState('');
    const dispatch = useAppDispatch();

    const vars = getVarsList(rule, param, flow, nodes, triggers);
    const mentionsVars = flatten(Object.values(vars)).map(item => ({
        id: item.id,
        display: item.fullTitle || item.title
    }));

    useEffect(() => {
        if (selectNodeOutputMode && selectedNodeOutput) {
            setCurrentValue(`$outputs[${selectedNodeOutput}]`)
            dispatch(setSelectedNodeOutput(undefined));
            dispatch(setSelectNodeOutputMode(false));
        }
    }, [selectNodeOutputMode, selectedNodeOutput])

    useEffect(() => {
        if (vars.global.map(item => item.id).indexOf(currentValue) !== -1) {
            setActiveTab(3);
        } else if (vars.enum?.map(item => item.id).indexOf(currentValue) !== -1) {
            setActiveTab(6);
        } else if (vars.ruleParams.map(item => item.id).indexOf(currentValue) !== -1) {
            setActiveTab(2);
        } else if (vars.triggerParams.map(item => item.id).indexOf(currentValue) !== -1) {
            setActiveTab(7);
        } else if (vars.variables.map(item => item.id).indexOf(currentValue) !== -1) {
            setActiveTab(1);
        } else if (vars.outputs.map(item => item.id).indexOf(currentValue) !== -1) {
            setActiveTab(0);
        } else if (intersection(param.type.toString().split(','), ['string', 'text', 'any', 'int', 'double', 'bool', 'time', 'date', 'datetime', 'email', 'phone_number', 'uuid']).length && (isString(currentValue) || isNumber(currentValue) || !currentValue)) {
            setActiveTab(4);
        } else if (intersection(param.type.toString().split(','), ['array', 'object', 'any']).length && (isObject(currentValue) || isArray(currentValue) || !currentValue)) {
            setActiveTab(5);
        }
    }, [value]);

    return (
        <Modal open={open && !selectNodeOutputMode} onClose={onClose} closeIcon closeOnDimmerClick closeOnEscape>
            <Modal.Header>Set node param value</Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Column width={4}>
                        <Menu fluid vertical tabular>
                            <Menu.Item
                                name='Nodes outputs'
                                active={activeTab === 0}
                                disabled={!vars.outputs.length}
                                onClick={() => setActiveTab(0)}
                            />
                            <Menu.Item
                                name='Enum'
                                active={activeTab === 6}
                                disabled={!vars.enum.length}
                                onClick={() => setActiveTab(6)}
                            />
                            <Menu.Item
                                name='Variables'
                                active={activeTab === 1}
                                disabled={!vars.variables.length}
                                onClick={() => setActiveTab(1)}
                            />
                            <Menu.Item
                                name='RuleParams'
                                disabled={!vars.ruleParams.length}
                                active={activeTab === 2}
                                onClick={() => setActiveTab(2)}
                            />
                            <Menu.Item
                                name='TriggerParams'
                                disabled={!vars.triggerParams.length}
                                active={activeTab === 7}
                                onClick={() => setActiveTab(7)}
                            />
                            <Menu.Item
                                name='Globals'
                                active={activeTab === 3}
                                onClick={() => setActiveTab(3)}
                            />
                            <Menu.Item
                                name='Custom value'
                                disabled={!intersection(param.type.toString().split(','), [
                                    'string', 'text', 'any', 'int', 'double', 'bool', 'time', 'date', 'datetime', 'email', 'phone_number', 'uuid'
                                ]).length}
                                active={activeTab === 4}
                                onClick={() => setActiveTab(4)}
                            />
                            <Menu.Item
                                name='JSON'
                                disabled={!intersection(param.type.toString().split(','), ['array', 'object', 'any']).length}
                                active={activeTab === 5}
                                onClick={() => setActiveTab(5)}
                            />
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        {activeTab === 0 && <>
                            <p>
                                Select node to use its output as node param value.<br />
                                Allowed types: <b>{param.type.toString().split(',').join(', ')}</b>
                             </p>
                            <p>
                                <Button content="Select node on the flow" onClick={() => {
                                    dispatch(setSelectNodeOutputMode(true));
                                }} />
                            </p>
                            <p>
                                or select from the list below:
                            </p>
                            {vars.outputs.map((item, i) => (
                                <Button
                                    key={i}
                                    primary={currentValue === item.id}
                                    onClick={() => setCurrentValue(item.id)}
                                    content={item.title} style={{marginBottom: 5}}/>
                            ))}
                        </>}
                        {activeTab === 6 && <>
                            <p>
                                Predefined param values
                            </p>
                            {vars.enum?.map((item, i) => (
                                <Button
                                    key={i}
                                    primary={currentValue === item.id}
                                    onClick={() => setCurrentValue(item.id)}
                                    content={item.title} style={{marginBottom: 5}}/>
                            ))}
                        </>}
                        {activeTab === 1 && <>
                            <p>
                                Select variable as node param value.<br />
                                Allowed types: <b>{param.type.toString().split(',').join(', ')}</b>
                            </p>
                            {vars.variables.map((item, i) => (
                                <Button
                                    key={i}
                                    primary={currentValue === item.id}
                                    onClick={() => setCurrentValue(item.id)}
                                    content={item?.title} style={{marginBottom: 5}}/>
                            ))}
                        </>}
                        {activeTab === 2 && <>
                            <p>
                                Select rule instance param value as node param value.<br />
                                Allowed types: <b>{param.type.toString().split(',').join(', ')}</b>
                            </p>
                            {vars.ruleParams.map((item, i) => (
                                <Button
                                    key={i}
                                    primary={currentValue === item.id}
                                    onClick={() => setCurrentValue(item.id)}
                                    content={item?.title} style={{marginBottom: 5}}/>
                            ))}
                        </>}
                        {activeTab === 7 && <>
                            <p>
                                Select trigger instance param value as node param value.<br />
                                Allowed types: <b>{param.type.toString().split(',').join(', ')}</b>
                            </p>
                            {vars.triggerParams.map((item, i) => (
                                <Button
                                    key={i}
                                    primary={currentValue === item.id}
                                    onClick={() => setCurrentValue(item.id)}
                                    content={item?.title} style={{marginBottom: 5}}/>
                            ))}
                        </>}
                        {activeTab === 3 && <>
                            <p>
                                Select global variable as node param value.<br />
                                Allowed types: <b>{param.type.toString().split(',').join(', ')}</b>
                            </p>
                            {vars.global.map((item, i) => (
                                <Button
                                    key={i}
                                    primary={currentValue === item.id}
                                    onClick={() => setCurrentValue(item.id)}
                                    content={item?.title} style={{marginBottom: 5}}/>
                            ))}
                        </>}
                        {activeTab === 4 && <>
                            <p>Allowed types: <b>{param.type.toString().split(',').join(', ')}</b></p>
                            {param.type.toString().split(',').indexOf(ParamTypeEnum.ANY) !== -1 &&
                                <p>Fill one of fields below</p>
                            }
                            <Form>
                            {
                                (
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.STRING) !== -1 ||
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.TEXT) !== -1 ||
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.EMAIL) !== -1 ||
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.PHONE_NUMBER) !== -1 ||
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.UUID) !== -1 ||
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.ANY) !== -1
                                ) &&
                                <Form.Field>
                                    <label>
                                        Enter custom value to use as node param value. You can use any other node output or
                                        variable in your template. To add variable to template type <b>$</b>.
                                    </label>
                                    <Mentions
                                        data={mentionsVars}
                                        singleLine={false}
                                        value={isString(currentValue) && !Date.parse(currentValue) && !currentValue?.toString().match(/^\d{2}\:\d{2}$/) ? currentValue : ''}
                                        onChange={(e, value, plainValue) => setCurrentValue(value)}
                                        displayTransform={(id, display) => {
                                            const item = mentionsVars.find(item => item.id === id);
                                            return item?.display || display;
                                        }}
                                    />
                                </Form.Field>
                            }
                            {
                                (
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.INT) !== -1 ||
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.DOUBLE) !== -1 ||
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.ANY) !== -1
                                ) && <Form.Input
                                    label="Integer or double"
                                    type="number"
                                    value={isNumber(currentValue) ? currentValue : ''}
                                    onChange={(e, {value}) => setCurrentValue(parseFloat(value))}
                                />
                            }
                            {
                                (
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.DATE) !== -1 ||
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.ANY) !== -1
                                ) &&<Form.Input
                                    label="Date"
                                    type="date"
                                    value={Date.parse(currentValue) ? currentValue : ''}
                                    onChange={(e, {value}) => setCurrentValue(value)}
                                />
                            }
                            {
                                (
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.DATETIME) !== -1 ||
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.ANY) !== -1
                                ) && <Form.Input
                                    label="Datetime"
                                    type="datetime-local"
                                    value={Date.parse(currentValue) ? currentValue : ''}
                                    onChange={(e, {value}) => setCurrentValue(value)}
                                />
                            }
                            {
                                (
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.TIME) !== -1 ||
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.ANY) !== -1
                                ) && <Form.Input
                                    type="time"
                                    label="Time"
                                    value={currentValue?.toString().match(/^\d{2}\:\d{2}$/) ? currentValue : ''}
                                    onChange={(e, {value}) => setCurrentValue(value)}
                                />
                            }
                            {
                                (
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.BOOL) !== -1 ||
                                    param.type.toString().split(',').indexOf(ParamTypeEnum.ANY) !== -1
                                ) && <Form.Field>
                                    <label>Boolean value</label>
                                    <Form.Checkbox
                                        label="True"
                                        checked={isBoolean(currentValue) ? currentValue : false}
                                        onChange={(e, {checked}) => setCurrentValue(checked)}
                                    />
                                </Form.Field>
                            }
                            </Form>
                        </>}
                        {activeTab === 5 && <>
                            <JsonEditor
                                value={value}
                                onChange={(value) => {
                                    setCurrentValue(value);
                                    setError('');
                                }}
                                onError={(error) => setError(error)}
                            />
                        </>}
                    </Grid.Column>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <span style={{color: 'red', paddingRight: 20}}>{error}</span>
                <Button
                    disabled={!!error}
                    color='green' content="Set" icon="check" style={{width: 150}} onClick={() => {
                    onChange(param, currentValue);
                    onClose();
                }} />
            </Modal.Actions>
        </Modal>
    );
}