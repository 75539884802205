import {Flow} from "../../components/Flow";
import {Debug} from "../../components/debug/Debug";
import {NodeParams} from "../../components/params/NodeParams";
import {EdgeParams} from "../../components/params/EdgeParams";
import {Layout} from "../../components/Layout";
import {useAppDispatch, useAppSelector} from "../../store";
import {useEffect, useState} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import {useGetRuleQuery} from "../../apis/rules.api";
import {Dimmer, Loader, Message} from "semantic-ui-react";
import {FlowDebug} from "../../components/FlowDebug";
import {Header} from "../rule-editor/Header";
import {RuleInfo} from "../../components/RuleInfo";
import {DataLoader} from "../rule-editor/DataLoader";
import {setActiveNodeUuid, setPosition, setZoom} from "../../slices/flow.slice";
import {reset} from "../../slices/editor.slice";
import {setCurrentInstance, stopDebug} from "../../slices/debug.slice";
import {InstanceControls} from "../rule-editor/components/InstanceControls";
import {RuleControls} from "../rule-editor/components/RuleControls";

export function RuleViewer() {
    const ready = useAppSelector(state => state.editor.ready);
    const flow = useAppSelector(state => state.flow.flow);
    const activeNodeUuid = useAppSelector(state => state.flow.activeNodeUuid);
    const activeNode = flow?.find(node => node.uuid === activeNodeUuid);
    const selectNodeOutputMode = useAppSelector(state => state.flow.selectNodeOutputMode);
    const debugModeEnabled = useAppSelector(state => state.debug.debugModeEnabled);
    const {ruleUuid, ruleVersion} = useParams();
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();

    const {data: activeRule, isError, error, isFetching: isRuleFetching, refetch: refetchRule} = useGetRuleQuery({
        ruleUuid: ruleUuid as string,
        ruleVersion: parseInt(ruleVersion as string),
        getDependencies: true,
        getPermissions: true
    }, {
        refetchOnMountOrArgChange: true
    });

    useEffect(() => {
        dispatch(setPosition());
        dispatch(setZoom());

        return () => {
            dispatch(reset());
            dispatch(stopDebug());
            dispatch(setCurrentInstance());
            dispatch(setActiveNodeUuid(undefined));
        }
    }, []);

    useEffect(() => {
        setLoading(!activeRule || isRuleFetching || !ready);
    }, [activeRule, isRuleFetching, ready]);

    return (
        <Layout
            muted={selectNodeOutputMode}
            headerContent={<Header muted={selectNodeOutputMode} />}
            mainContent={<>
                <DataLoader ruleUuid={ruleUuid} ruleVersion={Number(ruleVersion)}/>
                {
                    loading || !activeRule ? (
                        <Dimmer active inverted>
                            {isError && (error as any)?.status === 403 && <Navigate to="/forbidden" replace />}
                            {isError && (error as any)?.status !== 403 && (
                                <Message
                                    error
                                    header={'Error'}
                                    content={<>
                                        {(error as any)?.data?.error?.description || 'Unknown error'}<br />
                                        Try again or <Link to={'/rules'}>back to homepage</Link>
                                    </>}
                                    style={{color: 'black', textAlign: 'left'}}
                                />
                            )}
                            {loading && <Loader/>}
                        </Dimmer>
                    ) : (
                        <>
                            <RuleControls rule={activeRule} debugModeEnabled={debugModeEnabled} />
                            <InstanceControls rule={activeRule}/>
                            {debugModeEnabled ? <FlowDebug rule={activeRule}/> : <Flow rule={activeRule} readOnly/>}
                        </>
                    )
                }
            </>}
            rightSidebarContent={loading || !activeRule ? undefined : (
                <>
                    {activeRule && !activeNode && <RuleInfo rule={activeRule}/>}
                    {activeRule && (activeNode?.type === 'node' || activeNode?.type === 'input') && <NodeParams rule={activeRule} readOnly />}
                    {activeRule && activeNode?.type === 'edge' && <EdgeParams rule={activeRule} readOnly />}
                    {debugModeEnabled && (
                        <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'white', zIndex: 3}}>
                            <Debug/>
                        </div>
                    )}
                </>
            )}
        />
    );
}