import {Link, useParams} from "react-router-dom";
import {Button, Card, Icon, Label} from "semantic-ui-react";
import {RuleStatusEnum} from "../../../interfaces/rule-status.enum";
import {RuleInterface} from "../../../interfaces/rule.interface";
import {useAppSelector} from "../../../store";
import {RuleSettingsModal} from "../../../components/rule/RuleSettingsModal";
import {getRuleLink} from "../../../helpers/rules";
import {RuleSharePermissionsEnum} from "../../../interfaces/rule-share-permissions.enum";

export function RuleListCardItem({rule, onDelete}: {
    rule: RuleInterface;
    onDelete: (rule: RuleInterface) => void;
}) {
    const {organization} = useParams();
    const orgId = useAppSelector(state => state.auth.orgId);

    return (
        <Card >
            <Card.Content as={Link} to={getRuleLink(rule, organization as string)}>
                <Card.Header>
                    <div style={{paddingRight: '2.85em', wordBreak: 'break-word'}}>{rule.name}</div>
                </Card.Header>
                <Card.Description>
                    {rule.description}
                </Card.Description>
                <br/>
                <Card.Description>
                    {rule.status === RuleStatusEnum.DRAFT && (
                        <Label color="orange" basic content="Draft"/>
                    )}
                    {rule.status === RuleStatusEnum.ACTIVE && (
                        <Label color="blue" content={`Version: ${rule.version}`}/>
                    )}
                    {rule.status === RuleStatusEnum.PUBLIC && (
                        <Label color="green" content={`Latest: v${rule.version}`}/>
                    )}
                    {rule.orgId !== orgId && (
                        <>
                            <Label content={`Shared by org ${rule.orgId}`} basic icon={'users'} color={'teal'}/>
                            {!!rule.permissions && !rule.permissions.includes(RuleSharePermissionsEnum.CAN_EDIT) && (
                                <Label color={'teal'} basic content={`Readonly`} />
                            )}
                        </>
                    )}
                </Card.Description>
                <br/>
                <Card.Description>
                    category: {rule.category || <>&mdash;</>}<br/>
                    tags: {(rule.tags?.length || 0) > 0 ? '#' + rule.tags?.join(', #') : <>&mdash;</>}<br/>
                    instances: {rule.instanceCount}<br/>
                    updated at: {new Date(rule.updatedAt).toLocaleString()}
                </Card.Description>
            </Card.Content>
            <Card.Content extra style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <Icon name='clock outline'/> {new Date(rule.createdAt).toLocaleDateString()}
                </div>
                <div style={{marginLeft: 'auto'}}>
                    <Button title={'Duplicate rule'} basic icon={'clone outline'} circular size={'mini'}
                            style={{visibility: 'hidden'}}/>
                    {(!rule.permissions || rule.permissions?.includes(RuleSharePermissionsEnum.CAN_EDIT)) && (
                        <RuleSettingsModal rule={rule}>
                            <Button title={'Edit rule'} basic icon={'edit outline'} circular size={'mini'} />
                        </RuleSettingsModal>
                    )}
                    {rule.orgId === orgId && (
                        <Button
                            title={'Delete rule'} basic
                            icon={'trash alternate outline'} circular size={'mini'}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onDelete(rule);
                            }}
                        />
                    )}
                </div>
            </Card.Content>
        </Card>
    )
}