import {List} from "semantic-ui-react";
import {Trigger} from "./Trigger";
import {useAppSelector} from "../store";

export function TriggersList() {
    const triggers = useAppSelector(state => state.editor.triggers);

    return (
        <>
            <List divided style={{margin: 0}}>
                {triggers.map((trigger, i) => <Trigger key={i} trigger={trigger}/>)}
            </List>
        </>
    );
}