import {setSync} from "../slices/global.slice";
import {Action} from "redux";

export const SyncStatusMiddleware = ({dispatch}: {dispatch: any}) => (next: any) => (action: Action) => {
    if (action.type.endsWith('/pending')) {
        dispatch(setSync(true));
    }
    if (action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected')) {
        dispatch(setSync(false));
    }
    return next(action);
}