import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../helpers/base-query";
import {FindRulesShareResponseInterface} from "../interfaces/find-rules-share-response.interface";
import {RuleShareInterface} from "../interfaces/rule-share.interface";
import {NodeShareInterface} from "../interfaces/node-share.interface";
import {FindNodesShareResponseInterface} from "../interfaces/find-nodes-share-response.interface";

export const shareApi = createApi({
    reducerPath: 'shareApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        findRuleShares: builder.query<FindRulesShareResponseInterface, {
            ruleUuid: string;
            ruleVersion: number;
        }>({
            query: (params) => ({
                url: `share/rules`,
                params
            }),
        }),
        shareRule: builder.mutation<RuleShareInterface, Pick<RuleShareInterface, "ruleUuid" | "ruleVersion" | "sharedOrgName" | "permissions">>({
            query: (body) => ({
                method: 'POST',
                url: `share/rules`,
                body
            }),
        }),
        updateRule: builder.mutation<RuleShareInterface, Pick<RuleShareInterface, "uuid" | "permissions">>({
            query: ({uuid, ...body}) => ({
                method: 'PATCH',
                url: `share/rules/${uuid}`,
                body
            }),
        }),
        stopShareRule: builder.mutation<void, string>({
            query: (ruleShareUuid) => ({
                method: 'DELETE',
                url: `share/rules/${ruleShareUuid}`
            }),
        }),
        findNodeShares: builder.query<FindNodesShareResponseInterface, {
            nodeUuid: string;
            nodeVersion: number;
        }>({
            query: (params) => ({
                url: `share/nodes`,
                params
            }),
        }),
        shareNode: builder.mutation<NodeShareInterface, Pick<NodeShareInterface, "nodeUuid" | "nodeVersion" | "sharedOrgName" | "permissions">>({
            query: (body) => ({
                method: 'POST',
                url: `share/nodes`,
                body
            }),
        }),
        updateNode: builder.mutation<NodeShareInterface, Pick<NodeShareInterface, "uuid" | "permissions">>({
            query: ({uuid, ...body}) => ({
                method: 'PATCH',
                url: `share/nodes/${uuid}`,
                body
            }),
        }),
        stopShareNode: builder.mutation<void, string>({
            query: (nodeShareUuid) => ({
                method: 'DELETE',
                url: `share/nodes/${nodeShareUuid}`
            }),
        })
    }),
})

export const {
    useFindRuleSharesQuery,
    useShareRuleMutation,
    useUpdateRuleMutation,
    useStopShareRuleMutation,
    useFindNodeSharesQuery,
    useShareNodeMutation,
    useUpdateNodeMutation,
    useStopShareNodeMutation,
} = shareApi;