import {RuleNodeInterface} from "../interfaces/rule-node.interface";
import {memo, useState} from "react";
import {Button, Loader} from "semantic-ui-react";
import {useAppSelector} from "../store";
import SyntaxHighlighter from "react-syntax-highlighter/dist/cjs/light";
import {docco} from "react-syntax-highlighter/dist/esm/styles/hljs";
import {useGetInstanceQuery} from "../apis/instances.api";
import {CreateInstanceModal} from "./instance/CreateInstanceModal";
import {useParams} from "react-router-dom";
import './FlowNode.scss';

export const FlowInstanceInfoNode = memo(({data, isConnectable, type}: {
    data: {
        data: RuleNodeInterface
    };
    isConnectable: boolean;
    type: string;
}) => {
    const {ruleUuid, ruleVersion} = useParams();
    const currentInstance = useAppSelector(state => state.debug.currentInstance);
    const {data: instance, isFetching, isLoading, refetch} = useGetInstanceQuery(currentInstance?.uuid as string, {
        skip: !currentInstance?.uuid
    });
    const [edit, setEdit] = useState(false);

    return (
        <>
            {edit && ruleUuid && ruleVersion && <CreateInstanceModal
                ruleUuid={ruleUuid}
                ruleVersion={parseInt(ruleVersion)}
                open={edit}
                edit={currentInstance}
                onClose={() => setEdit(false)}
            />}
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '100%', width: '100%', padding: 10}}>
                {(isLoading) ? (
                    <Loader size="mini" active />
                ) : (
                    <>
                        {currentInstance && (
                            <>
                                <Button loading={isLoading || isFetching} icon="refresh" color="teal" circular style={{position: 'absolute', top: 5, right: 97}} size="mini" onClick={(e) => {
                                    refetch();
                                    e.preventDefault();
                                    e.stopPropagation();
                                }} />
                                <Button content="Edit params" color="teal" circular style={{position: 'absolute', top: 5, right: 2}} size="mini"
                                    onClick={() => setEdit(true)}
                                />
                            </>
                        )}
                        <div style={{lineHeight: 1.2}}>
                            <div>Instance info</div>
                        </div>
                        {currentInstance && instance ? (
                            <>
                                <p style={{width: '100%', marginBottom: 0}}>
                                    Invocations: {instance.invocationsCount}<br />
                                    Latest invocation: {new Date(instance.latestInvocationDate).toLocaleString()}
                                </p>
                                <label style={{width: '100%'}}>Trigger params:</label>
                                <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: 0, padding: 0,  backgroundColor: 'transparent', width: '100%'}}>
                                    {JSON.stringify(instance?.triggerParams || {}, null, 2)}
                                </SyntaxHighlighter>
                                <label style={{width: '100%'}}>Rule params:</label>
                                <SyntaxHighlighter language="typescript" style={{...docco}} customStyle={{margin: 0, padding: 0, backgroundColor: 'transparent', width: '100%'}}>
                                    {JSON.stringify(instance?.ruleParams || {}, null, 2)}
                                </SyntaxHighlighter>
                            </>
                        ) : (
                            <p style={{opacity: 0.5, marginTop: '1em'}}>No instance selected</p>
                        )}
                    </>
                )}
            </div>
        </>
    );
})