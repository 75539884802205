import {Dropdown, Icon} from "semantic-ui-react";
import {logOut} from "../slices/auth.slice";
import {Link} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../store";

export function UserMenu() {
    const auth = useAppSelector((state) => state.auth);
    const sync = useAppSelector((state) => state.global.sync);
    const dispatch = useAppDispatch();

    return (
        <div>
            {sync && <Icon name="refresh" loading title="Synchronization"/>}
            {
                auth.loggedIn ? (
                    <Dropdown
                        trigger={<span><Icon name="user" />{auth.name}</span>}
                        style={{alignItems: 'center', marginLeft: 15}}
                        text={auth.name}
                    >
                        <Dropdown.Menu direction="left" style={{marginTop: 15, marginRight: -5, width: 200}}>
                            <Dropdown.Item onClick={() => dispatch(logOut())}>Log out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                ) : (
                    <Link to={'/auth'}>Log in</Link>
                )
            }
        </div>
    )
}