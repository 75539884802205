import {useAppDispatch, useAppSelector} from "../store";
import {Button, Divider, Header, List, Message, Segment} from "semantic-ui-react";
import {setNodeInfoUuid} from "../slices/flow.slice";
import {isNumber, isString} from "lodash";
import {NodeInterface} from "../interfaces/node.interface";

export function NodeInfo({
    nodeUuid,
    node,
    basic = false,
    extended = false
}: {
    nodeUuid?: string;
    node?: NodeInterface;
    basic?: boolean;
    extended?: boolean;
}) {
    const nodes = useAppSelector(state => state.editor.nodes);
    node = node || nodes.find(node => node.uuid === nodeUuid);
    const dispatch = useAppDispatch();

    return (
        node ? (
            <Segment basic style={basic ? {padding: 0} : {}}>
                {!basic && (
                    <>
                        <Header style={{marginTop: '0.25em'}}>
                            {node.name}
                            <Button className="close-btn" icon="close"
                                    onClick={() => dispatch(setNodeInfoUuid(undefined))}/>
                        </Header>
                        <Divider/>
                    </>
                )}
                {extended ? (
                    <List divided>
                        <List.Item style={{padding: '10px 0'}}>
                            <List.Content>Created
                                by: {node.createdBy ? `Account #${node.createdBy}` : 'AffirmXH'}</List.Content>
                        </List.Item>
                        <List.Item style={{padding: '10px 0'}}>
                            <List.Content>Version: {node.version}</List.Content>
                        </List.Item>
                        <List.Item style={{padding: '10px 0'}}>
                            <List.Content>Description: {node.description}</List.Content>
                        </List.Item>
                        <List.Item style={{padding: '10px 0'}}>
                            <List.Content>Output: {node.outputType} {node.outputOf ? `of ${node.outputOf}` : ''} {node.outputDescription ? `(${node.outputDescription})` : ''}</List.Content>
                        </List.Item>
                        <List.Item style={{padding: '10px 0'}}>
                            <List.Content>Using in rules: {isNumber(node.rules?.length) ? node.rules?.length : 'unknown'}</List.Content>
                        </List.Item>
                        <List.Item style={{padding: '10px 0'}}>
                            <List.Content>Created at: {new Date(node.createdAt).toLocaleString()}</List.Content>
                        </List.Item>
                        <List.Item style={{padding: '10px 0'}}>
                            <List.Content>Updated at: {new Date(node.updatedAt).toLocaleString()}</List.Content>
                        </List.Item>
                    </List>
                ) : (
                    <>
                        <p>{node.description}</p>
                        <p>
                            Output: {node.outputType} {node.outputOf ? `of ${node.outputOf}` : ''} {node.outputDescription ? `(${node.outputDescription})` : ''}
                        </p>
                    </>
                )}
                <h4>Node params</h4>
                {node.params.map((param, i) => (
                    <Message>
                        <h5 style={{marginBottom: 5}}>{param.title}</h5>
                        {param.description && <p style={{opacity: 0.5}}>{param.description}</p>}
                        <table style={{borderCollapse: 'collapse'}}>
                            <tr>
                                <td>name</td>
                                <td>{param.name}</td>
                            </tr>
                            <tr>
                                <td>type</td>
                                <td>{isString(param.type) ? param.type : param.type.join(', ')} {param.of ? `of ${isString(param.of) ? param.of : param.of.join(', ')}` : ''}<br/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{paddingRight: 15}}>required</td>
                                <td>{param.required ? 'yes' : 'no'}</td>
                            </tr>
                            {param.enum?.length ? (
                                <tr>
                                    <td style={{verticalAlign: 'top'}}>enum</td>
                                    <td>{param.enum.map(item => item.value).join(', ')}</td>
                                </tr>
                            ) : ''}
                        </table>
                    </Message>
                ))}
            </Segment>
        ) : <></>
    )
}