import {ReactElement, useEffect, useState} from "react";
import {Button, Dimmer, Form, Loader, Message, Modal} from "semantic-ui-react";
import {useSwitchVersionMutation} from "../../apis/instances.api";

export function SwitchVersionModal({children, ruleUuid, fromVersion, availableVersions = []}: {
    children: ReactElement;
    ruleUuid: string;
    fromVersion: number;
    availableVersions?: number[];
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [switchVersion, {isLoading, isError, isSuccess, error}] = useSwitchVersionMutation();
    const [selectedVersion, setSelectedVersion] = useState<number>();

    useEffect(() => {
        if (isSuccess) {
            setIsOpen(false);
        }
    }, [isSuccess]);

    return (
        <>
            <span onClick={() => setIsOpen(true)}>{children}</span>
            <Modal open={isOpen} onClose={() => setIsOpen(false)} closeOnDimmerClick closeOnDocumentClick size="mini">
                <Dimmer active={isLoading} inverted><Loader /></Dimmer>
                <Modal.Header>Switch rule instances version</Modal.Header>
                <Modal.Content>
                    {isError && <Message error content={(error as any)?.data?.error?.description || 'Unknown error'} />}
                    <Form>
                        <Form.Select
                            label="New instances version"
                            placeholder="Select version..."
                            fluid
                            value={selectedVersion}
                            onChange={(e, {value}) => setSelectedVersion(value as number)}
                            options={
                                availableVersions
                                    .filter((version) => version !== fromVersion)
                                    .map((version) => ({
                                        key: `v-${version}`,
                                        value: version,
                                        text: `v${version}`
                                    }))
                            }
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Cancel" onClick={() => setIsOpen(false)}/>
                    <Button primary content="Switch" disabled={!selectedVersion} onClick={() => {
                        selectedVersion && switchVersion({
                            ruleUuid,
                            fromVersion,
                            toVersion: selectedVersion
                        })
                    }}/>
                </Modal.Actions>
            </Modal>
        </>
    )
}