import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from "react-redux";
import {store, persistor} from './store'
import {PersistGate} from "redux-persist/integration/react";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import {Homepage} from "./screens/homepage/Homepage";
import {RuleEditor} from "./screens/rule-editor/RuleEditor";
import {NodeEditor} from "./screens/node-editor/NodeEditor";
import {TriggerEditor} from "./screens/trigger-editor/TriggerEditor";
import {Auth} from "./screens/auth/Auth";
import {Sso} from "./screens/auth/Sso";
import {RequireAuth} from "./components/RequireAuth";
import {RequireGuest} from "./components/RequireGuest";
import {RuleViewer} from "./screens/rule-viewer/RuleViewer";
import {RulePage} from "./screens/homepage/marketplace/rules/RulePage";
import {RulePage as RulesRulePage} from "./screens/homepage/rules/RulePage";
import {RulesList} from "./screens/homepage/marketplace/rules/RulesList";
import {Rules} from './screens/homepage/Rules';
import {Nodes} from "./screens/homepage/Nodes";
import {Triggers} from "./screens/homepage/Triggers";
import {Marketplace} from "./screens/homepage/Marketplace";
import {NodeViewer} from "./screens/node-editor/NodeViewer";
import {NodePage} from "./screens/homepage/nodes/NodePage";
import {NodePage as MarketNodePage} from "./screens/homepage/marketplace/nodes/NodePage";
import {NodesList} from "./screens/homepage/marketplace/nodes/NodesList";
import {Forbidden} from "./screens/auth/Forbidden";
import './index.scss';

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <Routes>
                    <Route path="/forbidden" element={<Forbidden />}/>
                    <Route path="/sso" element={<RequireGuest><Sso/></RequireGuest>}/>
                    <Route path="/auth" element={<RequireGuest><Auth/></RequireGuest>}/>
                    <Route path="/" element={<RequireAuth><App/></RequireAuth>}>
                        <Route index element={<Navigate to={'/marketplace/rules'}/>}/>
                        <Route path="marketplace/rules/*">
                            <Route element={<Homepage section="marketplace"/>}>
                                <Route element={<Marketplace/>}>
                                    <Route index element={<RulesList/>}/>
                                    <Route path=":ruleUuid/:ruleVersion/*" element={<RulePage/>}/>
                                </Route>
                            </Route>
                            <Route path="view/:ruleUuid/:ruleVersion" element={<RuleViewer/>}/>
                        </Route>
                        <Route path="marketplace/nodes">
                            <Route element={<Homepage section="marketplace"/>}>
                                <Route element={<Marketplace/>}>
                                    <Route index element={<NodesList/>}/>
                                    <Route path=":nodeUuid/:nodeVersion/*" element={<MarketNodePage/>}/>
                                </Route>
                            </Route>
                            <Route path="view/:nodeUuid/:nodeVersion" element={<NodeViewer/>}/>
                        </Route>

                        {/* Rules routes */}
                        <Route path="org/:organization/rules">
                            <Route element={<Homepage section="rules"/>}>
                                <Route index element={<Navigate to={'drafts/recent'} />} />
                                <Route path="public/:ruleUuid/:ruleVersion/*" element={<RulesRulePage/>}/>
                                <Route path=":section/:tag" element={<Rules/>}/>
                            </Route>
                            <Route path="view/:ruleUuid/:ruleVersion" element={<RuleViewer/>}/>
                            <Route path="release/:ruleUuid/:ruleVersion" element={<RuleViewer/>}/>
                            <Route path="edit/:ruleUuid/:ruleVersion" element={<RuleEditor/>}/>
                        </Route>

                        {/* Node routes */}
                        <Route path="org/:organization/nodes/*">
                            <Route element={<Homepage section="nodes"/>}>
                                <Route index element={<Navigate to={'drafts/recent'} />} />
                                <Route path="public/:nodeUuid/:nodeVersion" element={<NodePage/>}/>
                                <Route path=":section/:tag" element={<Nodes/>}/>
                            </Route>
                            <Route path="view/:nodeUuid/:nodeVersion" element={<NodeViewer/>}/>
                            <Route path="release/:nodeUuid/:nodeVersion" element={<NodeViewer/>}/>
                            <Route path="edit/:nodeUuid/:nodeVersion" element={<NodeEditor/>}/>
                        </Route>

                        {/* Trigger routes */}
                        <Route path="org/:organization/triggers">
                            <Route element={<Homepage section="triggers"/>}>
                                <Route index element={<Triggers/>}/>
                            </Route>
                            <Route path=":triggerUuid" element={<TriggerEditor/>}/>
                        </Route>
                        <Route path="triggers">
                            <Route element={<Homepage section="triggers"/>}>
                                <Route index element={<Triggers/>}/>
                            </Route>
                            <Route path=":triggerUuid" element={<TriggerEditor/>}/>
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
